<div class="flex items-center" mat-dialog-title>
    <h2 class="text-base md:text-2xl m-0 flex-auto">{{blank.descr}}</h2>
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-divider class="text-border"></mat-divider>
<mat-dialog-content>
    <div class="flex flex-col justify-between p-4 gap-4">
        <div class="flex flex-col sm:flex-row justify-center gap-4">
            <img class="w-44 h-44" [src]="blank.frontImageThumbnail" alt="No Image Found"
                (error)="commonService.imageErrorHandle($event)" />
            <img class="w-44 h-44" [src]="blank.backImageThumbnail" alt="No Image Found"
                (error)="commonService.imageErrorHandle($event)" />
        </div>
        <div class="grid grid-cols-2 md:grid-cols-3 text-primary gap-2 text-center">
            <div>
                <label class="text-slate-300 text-sm">Category</label>
                <div>{{blank?.clcCat}}</div>
            </div>
            <div>
                <label class="text-slate-300 text-sm">Department</label>
                <div>{{blank?.dept}}</div>
            </div>
            <div>
                <label class="text-slate-300 text-sm">Color</label>
                <div>{{blank?.color}} <small>( {{blank?.colorGroup}} )</small></div>
            </div>
            <div>
                <label class="text-slate-300 text-sm">Luminance</label>
                <div>{{blank?.luminance}}</div>
            </div>
            <div>
                <label class="text-slate-300 text-sm">PMS</label>
                <div>{{blank?.pms}}</div>
            </div>
            <div>
                <label class="text-slate-300 text-sm">Model Rank</label>
                <div>{{blank?.modelRank}}</div>
            </div>
            <div>
                <label class="text-slate-300 text-sm">Model</label>
                <div>{{blank?.model}}</div>
            </div>
            <div class="col-span-2 font-semibold text-xl text-right pr-6">
                ${{blank?.salePrice}}
            </div>
        </div>
    </div>
</mat-dialog-content>
