<div mat-dialog-title class="mb-0 p-2 flex flex-row flex-wrap items-start justify-between">
  <h4 class="text-sm md:text-lg">View Disclosure </h4>
  <div>
    <button mat-stroked-button color="primary" (click)="generatePDF()" [buttonLoader]="exportLoader"
      [disabled]="exportLoader">
      <mat-icon>download</mat-icon> Download
    </button>
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content class="">
  <div @fadeInUp class="card border-0 px-6 shadow-none" id="exportPdf" #exportPdf [blockLoader]="loader">
  <div class="grid  grid-cols-1 sm:grid-cols-2" >
    <div class="mt-12">
    <div class="flex items-center gap-3">
      <img class="w-16 border  border-gray-200 rounded-full" mat-card-sm-image
      alt="Image not found" [src]="getCachelessUrl(playerProfileImage)"
      (error)="commonService.imageErrorHandle($event)" crossOrigin="anonymous">
      <h3 class="lg:text-lg text-sm m-0">
      <p class="text-secondary m-0">To</p>
      {{payout?.user?.firstName}} {{payout?.user?.lastName}} <br>
      <p class="text-secondary">StudentID : -</p>
      </h3>
    </div>

    <div class="text-secondary mt-1">
      <p class="m-0">Sports : <span *ngFor="let row of payout?.user?.players">{{row?.sport}}</span></p>
      <p class="m-0 mt-1 flex">
      <mat-icon class="mr-2 icon-sm" svgIcon="mat:phone"></mat-icon>
      <span> {{payout.user?.email || '-'}}</span>
      </p>
      <p class="m-0 mt-1 flex">
      <mat-icon class="mr-2 icon-sm" svgIcon="mat:mail"></mat-icon>
      <span> {{payout.user?.phone || '-'}}</span>
      </p>
    </div>
    </div>
    <div class="mt-12">
    <div class="flex items-center gap-3">
      <img class="w-16 rounded-full" mat-card-sm-image
      alt="Image not found" src="/assets/img/icon.png"
      (error)="commonService.imageErrorHandle($event)">
      <h3 class="lg:text-lg text-sm m-0">
      <p class="text-secondary m-0">From</p>
      Athletic Solutions <br>
      <p class="text-secondary"><a href="https://athsolutions.net" target="blank">athsolutions.net</a></p>
      </h3>
    </div>

    <div class="mt-6">
      <div class="text-secondary">
      <p class="m-0">Contact: Paul Feiden (VP - Business Development)</p>
      <p class="m-0">
        <mat-icon class="mr-2 icon-sm" svgIcon="mat:phone"></mat-icon>
        <span>(720) 281-9372</span>
      </p>
      </div>
    </div>
    </div>
  </div>
  <div class="overflow-auto hidden lg:block mt-16">
    <table class="payout-table table w-full  ">
    <thead>
      <tr>
      <td class="body-2 text-secondary">DESCRIPTION</td>
      <td class="body-2 text-secondary">DATE</td>
      <td class="body-2 text-secondary">VALUE</td>
      <td class="body-2 text-secondary">PAYMENT TYPE</td>
      <td class="body-2 text-secondary text-right">STATUS</td>
      </tr>
    </thead>
    <tbody>
      <tr>
      <td class="py-6 border-b">Sales from personally branded merchandise</td>
      <td class="py-6 border-b">{{payout.createdAt | date : 'MMM d, y, hh:mm a' || '-'}}</td>
      <td class="py-6 border-b">$ {{payout?.amount || '-'}}</td>
      <td class="py-6 border-b">Cash/Cash Equivalent</td>
      <td class="py-6 border-b font-medium text-right">{{payout?.itemStatus || '-'}}</td>
      </tr>
    </tbody>
    </table>
  </div>
  <div class="lg:hidden block my-6">
    <ul class="card">
    <li class="flex flex-col md:flex-row justify-between w-full p-2 md:p-3">
      <h5 class="text-gray-500 text-sm">Description</h5>
      <p class="text-gray-800 font-semibold text-sm">Sales from personally branded merchandise</p>
    </li>
    <mat-divider></mat-divider>
    <li class="flex flex-col md:flex-row justify-between w-full p-2 md:p-3">
      <h5 class="text-gray-500 text-sm">Date</h5>
      <p class="text-gray-800 font-semibold text-sm">{{payout.createdAt | date : 'MMM d, y, hh:mm a' || '-'}}
      </p>

    </li>
    <mat-divider></mat-divider>
    <li class="flex flex-col md:flex-row justify-between w-full p-2 md:p-3">
      <h5 class="text-gray-500 text-sm">Value</h5>
      <p class="text-gray-800 font-semibold text-sm">$ {{payout?.amount || '-'}}</p>

    </li>
    <mat-divider></mat-divider>
    <li class="flex flex-col md:flex-row justify-between w-full p-2 md:p-3">
      <h5 class="text-gray-500 text-sm">Payment Type</h5>
      <p class="text-gray-800 font-semibold text-sm">Cash/Cash Equivalent</p>
    </li>
    <mat-divider></mat-divider>
    <li class="flex flex-col md:flex-row justify-between w-full p-2 md:p-3">
      <h5 class="text-gray-500 text-sm">Status</h5>
      <p class="text-gray-800 font-semibold text-sm">{{payout?.itemStatus || '-'}}</p>
    </li>
    </ul>
  </div>
  </div>
</mat-dialog-content>
