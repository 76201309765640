import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { UserService } from '../../services/api/user.service';
import { NavItem } from '../../models/navigation';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy {
  @Input() opened__:any;
  @Input() open = false;
  @Output() itemSelected: EventEmitter<any> = new EventEmitter();
  private connServSub!: Subscription;
  private unsubscribe = new Subject<void>();
  public selectedNav: any;
  private isUserAdmin = false;
  public logo!: string;
  private userSub!: Subscription;
  public navItems!: NavItem[];

  constructor(
    private router: Router,
    private userService: UserService
  ) {
    const isConnected = navigator.onLine;
    this.logo = ""
    this.userSub = this.userService.user.subscribe(user => {
      this.logo = user.logo!;
      if (user.groups) {
      } else {
        this.navItems = [];
      }

    })
  }

  ngOnInit() {

  }



  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    if (this.connServSub) {
      this.connServSub.unsubscribe();
    }
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }

  closeSideMenuOnClick() {
    setTimeout(() => {
      this.itemSelected.emit();
    });
  }

}
