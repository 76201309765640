import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { MaterialModule } from './modules/material/material.module';
import { LoginComponent } from './pages/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from './core/core.module';
import { MainModule } from './pages/main/main.module';
import { UnderConstructionComponent } from './pages/under-construction/under-construction.component';
import { UserVerificationComponent } from './pages/user-verification/user-verification.component';
import { VexModule } from '../@vex/vex.module';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { StoreModule } from '@ngrx/store';
import { reducers } from './ngrx-store/reducers';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { PasswordResetRequestComponent } from './pages/password-reset-request/password-reset-request.component';
import { AdminBlankViewComponent } from './pages/common-admin-org/admin-blanks/admin-blank-view/admin-blank-view.component';
import { UserSignupComponent } from './pages/user-signup/user-signup.component';
import { UserSignupFormComponent } from './pages/user-signup-form/user-signup-form.component';
import { UserOptOutComponent } from './pages/user-opt-out/user-opt-out.component';
import { PlayerSignupComponent } from './pages/player-signup/player-signup.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UnderConstructionComponent,
    UserVerificationComponent,
    PasswordResetComponent,
    PasswordResetRequestComponent,
    AdminBlankViewComponent,
    UserSignupComponent,
    UserSignupFormComponent,
    UserOptOutComponent,
    PlayerSignupComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    VexModule,
    CoreModule,
    MainModule,
    StoreModule.forRoot(reducers)
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
