import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BestSelling, RecentSales, SalesByDay, SalesOverview, SchoolHome } from '../../models/home';
import { School } from '../../models/school';
import { HttpHelperService } from '../http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class SchoolHomeService {

  schoolHome$ : SchoolHome=null;
  recentSales$ : RecentSales[]=null;
  salesByDay$: SalesByDay[]=null;
  salesOverview$: SalesOverview=null;
  schoolBestSelling$:BestSelling[]=null
  constructor(private httpHelper: HttpHelperService) { }

  resetState(){
    this.schoolHome$ = this.recentSales$ = this.salesByDay$ = this.salesOverview$ = this.schoolBestSelling$=null;
  }

  getRecentSales(School: School):Observable<RecentSales[]> {
    return this.httpHelper.request<RecentSales[]>(`school-home/${School.id}/recent_sales`, { method: 'GET' });
  }

  getSchoolHome(School: School):Observable<SchoolHome> {
    return this.httpHelper.request<SchoolHome>(`school-home/${School.id}`, { method: 'GET' });
  }

  getSalesByDay(school: School, start: Date, end: Date):Observable<SalesByDay[]> {
    let salesByDayParams = new HttpParams({
      fromObject: {
        'start_date': start.toISOString().split('T')[0],
        'end_date': end.toISOString().split('T')[0]
      }
    })
    return this.httpHelper.request<SalesByDay[]>(`school-home/${school.id}/sales_by_day`, { method: 'GET', search: salesByDayParams });
  }

  totalMonthSales(School: School):Observable<SalesOverview> {
    var today = new Date();
    var thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    let totalMonthSalesParams = new HttpParams({
      fromObject: {
        'start_date': thirtyDaysAgo.toISOString().substring(0, 10),
        'end_date': today.toISOString().substring(0, 10)
      }
    })
    return this.httpHelper.request<SalesOverview>(`school-home/${School.id}/total_month_sales`, { method: 'GET' ,search:totalMonthSalesParams});
  }

  monthlySales(School: School) {
    var today = new Date();
    var thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    let monthlySalesParams = new HttpParams({
      fromObject: {
        'start_date': thirtyDaysAgo.toISOString().substring(0, 10),
        'end_date': today.toISOString().substring(0, 10)
      }
    })
    return this.httpHelper.request<void>(`school-home/${School.id}/monthly_sales`, { method: 'GET' ,search:monthlySalesParams});
  }

  schoolBestSelling(School:School):Observable<BestSelling[]>{
    return this.httpHelper.request<BestSelling[]>(`school-home/${School.id}/best_selling`, { method: 'GET'});

  }

}

