import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BestSelling, SalesByDay } from '../../models/home';
import { HttpHelperService } from '../http-helper.service';
import { HttpParams } from '@angular/common/http';
import { JournalEntrySale, SportSalesReport, TotalSalesReport } from '../../models/sales';

export type OrgBrandReportItem = {
  id: number;
  sales: number;
  count: number
  brandName: string;
}

export type OrgTotalSalesReport = {
  license: number;
  brandLicense: number;
  sales: number;
}


@Injectable({
  providedIn: 'root'
})
export class OrgHomeService {
  private endpoint: string = 'organization';

  orgBestSelling$:BestSelling[]=null
  constructor(private httpHelper: HttpHelperService) { }
  
  resetState(){
    this.orgBestSelling$=null;
  }
  
  orgBestSelling(orgId:number):Observable<BestSelling[]>{
    return this.httpHelper.request<BestSelling[]>(`organization/${orgId}/best_selling`, { method: 'GET'});

  }

  brandSales(orgId: number, start: Date, end: Date): Observable<OrgBrandReportItem[]> {
    let params = new HttpParams({
      fromObject: {
        'start_date': start.toISOString().split('T')[0],
        'end_date': end.toISOString().split('T')[0]
      }
    });
    return this.httpHelper.request<OrgBrandReportItem[]>(`${this.endpoint}/${orgId}/brand-sales`, { method: 'GET', search: params });
  }

  totalSalesReport(orgId: number, start: Date, end: Date): Observable<OrgTotalSalesReport> {
    let params = new HttpParams({
      fromObject: {
        'start_date': start.toISOString().split('T')[0],
        'end_date': end.toISOString().split('T')[0]
      }
    });
    return this.httpHelper.request<OrgTotalSalesReport>(`${this.endpoint}/${orgId}/total-sales`, { method: 'GET', search: params });
  }

  journalEntries(orgId: number, start: Date, end: Date): Observable<JournalEntrySale[]> {
    let params = new HttpParams({
      fromObject: {
        'start_date': start.toISOString().split('T')[0],
        'end_date': end.toISOString().split('T')[0]
      }
    });
    return this.httpHelper.request<JournalEntrySale[]>(`${this.endpoint}/${orgId}/journal-entries/`, { method: 'GET', search: params });
  }

  getSalesByDay(ordId: number, start: Date, end: Date):Observable<SalesByDay[]> {
      let salesByDayParams = new HttpParams({
        fromObject: {
          'start_date': start.toISOString().split('T')[0],
          'end_date': end.toISOString().split('T')[0]
        }
      })
      return this.httpHelper.request<SalesByDay[]>(`${this.endpoint}/${ordId}/sales-by-day`, { method: 'GET', search: salesByDayParams });
    }
}
