import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { map, startWith, take } from 'rxjs';
import { School } from 'src/app/core/models/school';
import { AuthService } from '../../core/services/api/auth.service';

@Component({
  selector: 'app-user-signup',
  templateUrl: './user-signup.component.html',
  styleUrls: ['./user-signup.component.scss']
})
export class UserSignupComponent implements OnInit {

  public schoolSearchControl = new FormControl('');
  public schools: School[] = [];
  public signupForm!: FormGroup;
  public requestAccessForm!: FormGroup;
  public isPageLoading: boolean = false;
  public afterSubmit: boolean = false;
  public success: boolean;
  public message: string;
  public isLoading = false;
  public loadingSchools = false;
  private schoolIdParam: number;

  public schoolFilter = this.schoolSearchControl.valueChanges.pipe(startWith(''), map(value => this._filter(value || '').slice(0, 20)),);

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.signupForm = this.formBuilder.group({
      schoolId: [null, Validators.required],
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    });
    this.schoolIdParam = +this.route.snapshot.queryParamMap.get('school');
    this.loadingSchools = true;
    this.authService.getActiveSchools().pipe(take(1)).subscribe(schools => {
      this.schools = schools;
      if (this.schoolIdParam && schools.find(s => s.id === this.schoolIdParam)) {
        this.schoolSearchControl.setValue(schools.find(s => s.id === this.schoolIdParam).fullName);
        this.signupForm.patchValue({ schoolId: this.schoolIdParam });
      }
      this.loadingSchools = false;
    });
  }

  private _filter(value: string): School[] {
    let schools = this.schools.filter(data => data.fullName.toLowerCase().includes(value.toLowerCase()));
    if (schools.length === 0) {
      schools.push({ fullName: "No Results" });
    }
    return schools;
  }

  onSubmit(): void {
    if (this.signupForm.valid) {
      const schoolId = this.signupForm.value.schoolId;
      const email = this.signupForm.value.email;
      this.isLoading = true;
      this.authService.userRequestValidaiton(schoolId, email).pipe(take(1)).subscribe(response => {
        this.success = response.success;
        this.message = response.message;
        this.isLoading = false;
        this.afterSubmit = true;
      })
    }
  }

  schoolSelected(event: MatAutocompleteSelectedEvent): void {
    this.signupForm.patchValue({ schoolId: this.schools.find(s => s.fullName === event.option.value).id });
  }

  reset(): void {
    this.afterSubmit = false;
    this.signupForm.patchValue({
      email: ""
    });
  }

  gotoLogin(): void {
    this.router.navigate(['/login']);
  }



}
