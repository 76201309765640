import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelperService } from '../http-helper.service';
import { ReportItem, ReportingEntity, RoyaltyReport } from '../../models/reporting';
import { School } from '../../models/school';

@Injectable({
  providedIn: 'root'
})
export class ReportingEntityService {
  private endpoint: string = 'reporting-entities';
  constructor(private httpHelper: HttpHelperService) { }

  getAll(): Observable<ReportingEntity[]> {
    return this.httpHelper.request<ReportingEntity[]>(this.endpoint);
  }

  get(id: number): Observable<ReportingEntity> {
    return this.httpHelper.request<ReportingEntity>(`${this.endpoint}/${id}`);
  }

  filter(item: ReportingEntity): Observable<ReportingEntity> {
    return this.httpHelper.request<ReportingEntity>(`${this.endpoint}`, { params: item, method: 'GET' });
  }

  create(item: ReportingEntity): Observable<ReportingEntity> {
    return this.httpHelper.request<ReportingEntity>(`${this.endpoint}`, { body: item, method: 'POST' });
  }

  edit(item: ReportingEntity): Observable<ReportingEntity> {
    return this.httpHelper.request<ReportingEntity>(`${this.endpoint}/${item.id}`, { body: item, method: 'PUT' });
  }

  delete(id: number): Observable<null> {
    return this.httpHelper.request<null>(`${this.endpoint}/${id}`, { method: 'DELETE' });
  }

  pullReport(id: number, excludedBrands: number[], startDate: Date, endDate: Date): Observable<ReportItem[]> {
    const body = {
      excludedBrands,
      startDate: startDate.toISOString().split("T")[0],
      endDate: endDate.toISOString().split("T")[0]
    }
    return this.httpHelper.request<ReportItem[]>(`${this.endpoint}/${id}/pull_report`, { body, method: 'POST' });
  }

  saveReport(id: number, brands: number[], startDate: Date, endDate: Date): Observable<RoyaltyReport> {
    const body = {
      brands,
      startDate: startDate.toISOString().split("T")[0],
      endDate: endDate.toISOString().split("T")[0]
    }
    return this.httpHelper.request<RoyaltyReport>(`${this.endpoint}/${id}/save_report`, { body, method: 'POST' });
  }

  addBrand(id: number, brandId: number): Observable<School> {
    return this.httpHelper.request<School>(`${this.endpoint}/${id}/add_brand`, { body: {brandId}, method: 'POST' });
  }

  removeBrand(id: number, brandId: number): Observable<School> {
    return this.httpHelper.request<School>(`${this.endpoint}/${id}/remove_brand`, { body: {brandId}, method: 'POST' });
  }

}