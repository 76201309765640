import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  totalLoader: boolean = false;
  catLoader: boolean = false;
  sportLoader: boolean = false;

  constructor() { }
  downloadCsvFile(data, csvHeaders, filename, newCsvHeaders) {
    let csvData = this.ConvertToCSV(data, csvHeaders, newCsvHeaders);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, csvHeaders_, newCsvHeaders_) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    for (let index in newCsvHeaders_) {
      row += newCsvHeaders_[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in csvHeaders_) {
        let head = csvHeaders_[index];
        line += ',' + this.strRep(array[i][head]);
      }
      str += line + '\r\n';
    }
    return str;
  }

  strRep(data) {
    if (typeof data == "string") {
      let newData = data.replace(/,/g, " ");
      return newData;
    }
    else if (typeof data == "undefined" || data == null) {
      return "-";
    }
    else if (typeof data == "number") {
      return data.toString();
    }
    else {
      return data;
    }
  }

  generatePDF(htmlElement, filename): void {
    if (filename === 'totalSalesReport') this.totalLoader = true
    else if (filename === 'catSalesReport') this.catLoader = true
    else this.sportLoader = true;
    html2canvas(htmlElement, { scale: 3, useCORS: true, }).then((canvas) => {
      const imageGeneratedFromTemplate = canvas.toDataURL('image/png');
      const fileWidth = 200;
      const generatedImageHeight = (canvas.height * fileWidth) / canvas.width;
      let PDF = new jsPDF('p', 'mm', 'a4',);
      PDF.addImage(imageGeneratedFromTemplate, 'PNG', 5, 5, fileWidth, generatedImageHeight,);
      PDF.save(filename + '.pdf');
      this.totalLoader = false
      this.catLoader = false
      this.sportLoader = false
    });
  }

}
