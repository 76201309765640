import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SchoolDocument } from '../../models/school';
import { HttpHelperService } from '../http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class SchoolDocumentsService {

  private endpoint: string = 'school-documents';

  constructor(private httpHelper: HttpHelperService) { }

  getAll(): Observable<SchoolDocument[]> {
    return this.httpHelper.request<SchoolDocument[]>(this.endpoint);
  }

  get(id: number): Observable<SchoolDocument> {
    return this.httpHelper.request<SchoolDocument>(`${this.endpoint}/${id}`);
  }

  filter(item: SchoolDocument): Observable<SchoolDocument> {
    return this.httpHelper.request<SchoolDocument>(`${this.endpoint}`, {params: item, method: 'GET'});
  }

  create(item: SchoolDocument): Observable<SchoolDocument> {
    return this.httpHelper.request<SchoolDocument>(`${this.endpoint}`, {body: item, method: 'POST'});
  }

  edit(item: SchoolDocument): Observable<SchoolDocument> {
    return this.httpHelper.request<SchoolDocument>(`${this.endpoint}/${item.id}`, {body: item, method: 'PUT'});
  }
}
