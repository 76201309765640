<div class="dropdown">
  <div class="dropdown-header flex items-center justify-between">
    <div class="flex items-center">
      <div
        class="rounded-full h-9 w-9 flex items-center justify-center text-primary bg-primary/10 overflow-hidden"
      >
        <img
          alt="User Avatar"
          class="vex-sidenav-user__image flex-none object-cover object-top w-full h-full"
          [src]="logo$ | async"
          (error)="commonService.imageErrorHandle($event)"
        />
      </div>
      <div class="ml-3">
        <div class="dropdown-heading">{{ userFullName$ | async }}</div>
        <div class="">{{ orgName$ | async }}</div>
      </div>
    </div>

  </div>

  <div class="dropdown-content">
    <a
      (click)="close()"
      *ngFor="let item of menuItems; trackBy: trackById"
      [routerLink]="item.route"
      class="notification flex items-center"
      matRipple
    >
      <mat-icon
        [svgIcon]="item.icon"
        [ngClass]="item.colorClass"
        class="notification-icon flex-none"
      ></mat-icon>
      <div class="flex-auto">
        <div class="notification-label">{{ item.label }}</div>
        <div class="notification-description">{{ item.description }}</div>
      </div>
      <mat-icon class="notification-chevron flex-none">chevron_right</mat-icon>
    </a>
  </div>

  <div class="dropdown-footer flex items-center justify-between">
    <a (click)="logout()" color="primary" mat-button>Logout</a>
    <div class="totalDue" [class.spinnerForBlock]="this.loader" *ngIf="!hidePayouts">
      <ng-container *ngIf="!loader">
        <div class="totalDueHeading">Current Available To Payout</div>
        <div class="totalDueData">
          {{ this.totalDueData?.totalDue || "0.00" | currency}}
        </div>
      </ng-container>
    </div>
  </div>
</div>

<mat-menu #statusMenu="matMenu" xPosition="before" yPosition="below">
  <button
    (click)="setStatus(status)"
    *ngFor="let status of statuses; trackBy: trackById"
    mat-menu-item
  >
    <mat-icon [ngClass]="status.colorClass" [svgIcon]="status.icon"></mat-icon>
    <span>{{ status.label }}</span>
  </button>
</mat-menu>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon>business</mat-icon>
    <span>Change Address</span>
  </button>

  <button mat-menu-item>
    <mat-icon>verified_user</mat-icon>
    <span>Change Username</span>
  </button>

  <button mat-menu-item>
    <mat-icon>lock</mat-icon>
    <span>Change Password</span>
  </button>

  <button mat-menu-item>
    <mat-icon>notifications_off</mat-icon>
    <span>Disable Notifications</span>
  </button>
</mat-menu>
