import { Component, OnInit, inject } from '@angular/core';
import { Address, STATES } from '../../models/address';
import { FormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss']
})
export class AddressModalComponent {
  private dialogRef = inject(MatDialogRef<AddressModalComponent>);
  fb = inject(FormBuilder);
  address: Address = {};
  states = STATES;
  form = this.fb.group({
    email: [null, Validators.required],
    phone: [null, Validators.required],
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    company: [null],
    address1: [null, Validators.required],
    city: [null, Validators.required],
    zip: [null, Validators.required],
    province: [null],
    country: ["US", Validators.required],
    address2: [null],
    name: [null],
    countryCode: ["US", Validators.required],
    provinceCode: [null, Validators.required],
  })

  getErrors(): void {
    Object.keys(this.form.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  submit(): void {
    this.dialogRef.close(this.form.value);
  }

}
