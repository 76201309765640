<div class="card user-payout-history-main overflow-hidden" [blockLoader]="payoutsLoader">
  <div class="payoutsNoData flex flex-col items-center justify-center h-full text-[#c1b0b0]" *ngIf="payoutsDataSource?.data?.length===0">
    <span>No Payouts History</span>
    <img src="/assets/img/illustrations/finance.svg" width="16%">
  </div>
  <div class="table-td" *ngIf="!(payoutsDataSource?.data?.length===0)">
    <table mat-table [dataSource]="payoutsDataSource" matSort (matSortChange)="announceSortChange($event)">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by id">
          Id
        </th>
        <td mat-cell *matCellDef="let element">{{ element?.id||'-'}}</td>
      </ng-container>
      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="User">
          Name
        </th>
        <td mat-cell *matCellDef="let element">{{ element?.user?.lastName }}, {{ element?.user?.firstName }}</td>
      </ng-container>
      <ng-container matColumnDef="submittedAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by submittedAt">
          Submitted At
        </th>
        <td mat-cell *matCellDef="let element">
          {{ (element?.submittedAt | date: 'MMM d, yyyy') || '-' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="payoutType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by payoutType">
          Type
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.payoutType || '-' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="receiver">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Receiver">
          Email
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.receiver || '-' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="player">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Receiver">
          Athlete
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.user.firstName}} {{ element?.user.lastName}}
        </td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by amount">
          Amount
        </th>
        <td mat-cell *matCellDef="let element">{{ element?.amount || 0 | currency }}</td>
      </ng-container>

      <ng-container matColumnDef="itemStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by itemStatus">
          Status
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="flex items-center gap-1">
            <div [ngClass]="getStatusStyles(element)" class="rounded px-2 py-1 font-medium text-xs flex-none">
              {{ element?.itemStatus || '-' }}
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell>Actions</th>
        <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
          <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{ payout: row }"
            [matMenuTriggerFor]="actionsMenu" mat-icon-button type="button">
            <mat-icon>more_horiz</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="payoutsDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: payoutsDisplayedColumns"></tr>
    </table>
    <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" class="sticky left-0"></mat-paginator>
  </div>
</div>
<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-payout="payout" matMenuContent>
    <!-- <button (click)="showDisclosure(payout)" mat-menu-item>
      <mat-icon>download</mat-icon>
      <span>View/Export Disclosure</span>
    </button> -->
    <button [routerLink]="['/user/payouts', payout.id]" mat-menu-item>
      <mat-icon>download</mat-icon>
      <span>Details</span>
    </button>
  </ng-template>
</mat-menu>