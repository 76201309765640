<div class="w-full h-full flex flex-col" [class.componentLoader]="this.loader">
  <ng-container *ngIf="!this.loader">
    <div class="bg-primary/10 border-b w-full h-64 absolute top-0 left-0 right-0"></div>

    <mat-drawer-container class="card overflow-hidden flex-auto">
      <mat-drawer [mode]="mobileQuery.matches ? 'over' : 'side'" (openedChange)="drawerChange($event)"
        [opened]="drawerOpen$ | async" class="drawer" position="start">
        <div class="h-full flex flex-col">
          <div class="p-4 border-b bg-app-bar flex-none flex items-center gap-4 h-20 headlinem-0 p-4 text-primary">
            <mat-icon class="mr-5 ml-1">history_edu</mat-icon>
            <div class="block font-semibold text-lg">Documents</div>
          </div>
  
          <vex-scrollbar *ngIf="documents$" [@stagger]="(documents$ | async).length" class="flex-auto">
            <app-player-docs-list *ngFor="let document of (documents$ | async)" [document]="document" @fadeInUp>
            </app-player-docs-list>
          </vex-scrollbar>
        </div>
      </mat-drawer>
  
      <mat-drawer-content class="chat-pattern relative">
        <app-player-docs-view class="h-full"></app-player-docs-view>
      </mat-drawer-content>
    </mat-drawer-container>
  </ng-container> 
</div>