import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from '../services/api/user.service';
import { AuthService } from '../services/api/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard  {
  constructor(
    public userService: UserService,
    private authService: AuthService,
    public router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.userService.isAdmin() && !this.userService.isReadOnly()) {
      if (this.userService.isSchool()) {
        this.router.navigate(['/school/home']);
      } else if (this.userService.isPlayer()) {
        this.router.navigate(['/player/home']);
      }else if (this.userService.isOrg()) {
        this.router.navigate(['/org/home']);
      } else {
        if (this.router.url !== '/') {
          localStorage.setItem('lastUrl', this.router.url);
        }
        this.authService.logout();
      }
      return false;
    } else {
      return true;
    }
  }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.userService.isAdmin() && !this.userService.isReadOnly()) {
      if (this.userService.isSchool()) {
        this.router.navigate(['/school/home']);
      } else if (this.userService.isPlayer()) {
        this.router.navigate(['/player/home']);
      }else if (this.userService.isOrg()) {
        this.router.navigate(['/org/home']);
      } else {
        if (this.router.url !== '/') {
          localStorage.setItem('lastUrl', this.router.url);
        }
        this.authService.logout();
      }
      return false;
    } else {
      return true;
    }
  }
}
