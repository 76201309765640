<div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8 bg-slate-100">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <img class="mx-auto h-10 w-auto" src="assets/img/icon.png" alt="Athletic Solutions">
    <h2 class="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-primary">Sign in to your account</h2>
  </div>

  <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
    <div class="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12" [componentIsLoading]="this.isPageLoading">
      <div *ngIf="badCredentials" class="bg-red-200 p-3 rounded border border-red-400 flex flex-row mb-3">
        <mat-icon class="mr-2">cancel</mat-icon>
        <div>Unable to login with Username and Password</div>
      </div>
      <form class="space-y-6" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div>
          <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address</label>
          <div class="mt-2 mr-4">
            <input id="email"
              formControlName="username"
              autocomplete="off"
              name="email"
              type="text"
              required
              class="appearance-none block px-2 w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
          </div>
        </div>

        <div>
          <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Password</label>
          <div class="mt-2 mr-4">
            <input
              id="password"
              name="password"
              type="password"
              formControlName="password"
              autocomplete="current-password"
              required
              class="appearance-none block px-2 w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
          </div>
        </div>

        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary" formControlName="rememberMe">
            <label for="remember-me" class="ml-3 block text-sm leading-6 text-gray-900">Remember me</label>
          </div>

          <div class="text-sm leading-6">
            <a href="/password-reset-request" class="font-semibold text-primary hover:text-primary-500">Forgot password?</a>
          </div>
        </div>

        <div>
          <button type="submit" class="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">Sign in</button>
        </div>
      </form>
    </div>

    <p class="mt-10 text-center text-sm text-gray-500">
      Not signed up yet?
      <a href="/player-signup" class="font-semibold leading-6 text-primary">Signup Here!</a>
    </p>
  </div>
</div>