import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs';
import { BadgeType } from 'src/app/core/components/badge/badge.component';
import { UserPayoutsService } from 'src/app/core/services/api/user-payouts.service';

@Component({
  selector: 'app-payout-detail',
  templateUrl: './payout-detail.component.html',
  styleUrls: ['./payout-detail.component.scss']
})
export class PayoutDetailComponent {
  route = inject(ActivatedRoute);
  payoutService = inject(UserPayoutsService);

  payout$ = this.route.params.pipe(
    switchMap(({id}) => this.payoutService.get(id))
  )


  constructor() {}

  getStatusColor(status: string): BadgeType {
      switch(status) {
        case 'Success':
          return 'green';
        case 'Pending':
          return 'blue';
        case 'next payout':
          return 'blue';
        case 'Denied':
          return 'red';
        case 'FAILED':
          return 'red';
        default:
          return 'gray';
      }
    }
}
