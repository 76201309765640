import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, EventEmitter, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { catchError, filter, Observable, of, Subject, Subscription } from 'rxjs';
import { Notification } from 'src/app/core/models/notifications';
import { NotificationsService } from 'src/app/core/services/api/notifications.service';
import { NotifierService } from 'src/app/core/services/notifier.services';
import { PopoverService } from '../../../components/popover/popover.service';

@Component({
  selector: 'vex-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ToolbarNotificationsComponent implements OnInit, AfterViewInit {
  notifications: Notification[] = [];
  loader: boolean = false;
  @ViewChild('originRef', { static: true, read: ElementRef }) originRef: ElementRef;
  @ViewChild('menu') menu!: MatMenu;

  ngAfterViewInit() {
    // Inject our custom logic of menu close
    (this.menu as any).closed = this.configureMenuClose(this.menu.close);
  }

  private configureMenuClose(old: MatMenu['close']): MatMenu['close'] {
    const upd = new EventEmitter();
    feed(upd.pipe(
      filter(event => {
        if (event === 'click') {
          // Ignore clicks inside the menu
          return false;
        }
        return true;
      }),
    ), old);
    return upd;
  }

  todayIndex : number;
  yesterdayIndex : number;
  olderIndex : number;
  dropdownOpen: boolean;
  unseenNotificationCount : number = 0;
  constructor(private popover: PopoverService,
    private cd: ChangeDetectorRef,
    private notificationsService: NotificationsService,
    private notifier : NotifierService) { }

  ngOnInit() {
  }
  showNotifications(){
    if(this.notifications.length<1){
      this.getNotifications();
    }
  }
  getNotifications() {
    const start = new Date(new Date().setHours(0, 0, 0, 0));
    const end = new Date(new Date().setHours(23, 59, 59, 999));
    const yesterdayStart = new Date(new Date().setHours(0, 0, 0, 0));
    yesterdayStart.setDate(yesterdayStart.getDate() - 1);
    const yesterdayEnd = new Date(new Date().setHours(23, 59, 59, 999));
    yesterdayEnd.setDate(yesterdayEnd.getDate() - 1);
    const olderEnd = new Date(new Date().setHours(23, 59, 59, 999));
    olderEnd.setDate(olderEnd.getDate() - 2);
    this.loader = true;
    let subscribed = this.notificationsService.getAll().pipe(catchError(error=>{ this.loader=false; return of(null)})).subscribe(response => {
      if(response.length>0){
        this.notifications = response;
        this.unseenNotificationCount =0;
        for (let index = 0; index < response.length; index++) {
          const createdAt = new Date(response[index].createdAt);
          if(!response[index].seenAt) this.unseenNotificationCount++;
          if(this.todayIndex == undefined){
            if( createdAt>= start && createdAt <= end){
              this.todayIndex = index;
              continue;
            }
          }

          if(this.yesterdayIndex == undefined){
            if(createdAt >= yesterdayStart && createdAt <= yesterdayEnd){
              this.yesterdayIndex = index;
              continue;
            }
          }

          if(this.olderIndex == undefined){
            if(createdAt <= olderEnd){
              this.olderIndex=index;
              break;
            }
          }
        }
      }
      this.loader=false;
      subscribed.unsubscribe();
    });
  }

  updateNotifications(idData : number[], type: string) {
    if(!idData || idData.length<1) return this.notifier.alert({message :'No Notification to Update !!', type : 'warning'})
    let idList = {id: idData};
    this.loader = true;
    this.notificationsService.updateSeenNotification(idList).subscribe(response => {
      switch (type) {
        case 'MARKALL':
          this.notifications.map(data=>data.seenAt=new Date());
          this.unseenNotificationCount=0;
          break;
        case 'MARK' :
          this.notifications[this.notifications.findIndex(data => data.id == idData[0])].seenAt=new Date();
          this.unseenNotificationCount--;
          break;
        default:
          break;
      }
      this.loader = false;
      this.cd.markForCheck();
    });
  }

  markAsRead(id: number) {
    this.updateNotifications([id], 'MARK');
  }

  markAllAsRead() {
    let ids = this.notifications.map(a => a.id);
    this.updateNotifications(ids, 'MARKALL');
  }
}

function feed<T>(from: Observable<T>, to: Subject<T>): Subscription {
  return from.subscribe(
    data => to.next(data),
    err => to.error(err),
    () => to.complete(),
  );
}
