import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelperService } from '../http-helper.service';
import { Handle } from '../../models/handle';

@Injectable({
  providedIn: 'root'
})
export class HandleService {
  private endpoint: string = 'handles';

  constructor(private httpHelper: HttpHelperService) { }

  getAll(): Observable<Handle[]> {
    return this.httpHelper.request<Handle[]>(this.endpoint);
  }

  filter(item: Handle): Observable<Handle[]> {
    return this.httpHelper.request<Handle[]>(this.endpoint, {search: item});
  }

  get(id: number): Observable<Handle> {
    return this.httpHelper.request<Handle>(`${this.endpoint}/${id}`);
  }

  create(item: Handle): Observable<Handle> {
    return this.httpHelper.request<Handle>(`${this.endpoint}`, { body: item, method: 'POST' });
  }

  edit(item: Handle): Observable<Handle> {
    return this.httpHelper.request<Handle>(`${this.endpoint}/${item.handle}`, { body: item, method: 'PUT' });
  }

  partialEdit(item: Handle): Observable<Handle> {
    return this.httpHelper.request<Handle>(`${this.endpoint}/${item.handle}`, { body: item, method: 'PATCH' });
  }

  delete(handle: string): Observable<void> {
    return this.httpHelper.request<void>(`${this.endpoint}/${handle}`, { method: 'DELETE' });
  }

  available(handle: string): Observable<boolean> {
    return this.httpHelper.request<boolean>(`${this.endpoint}/available`, { body: {handle}, method: 'POST' });
  }


}
