<div mat-dialog-title class="flex flex-row flex-wrap items-start justify-between">
  <h2>{{data.title}}</h2>
  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-divider class="text-border"></mat-divider>

<mat-dialog-content class="flex flex-col gap-2 p-4 items-center">
  <div class="h-52 w-52 flex-shrink-0 border rounded bg-gray-100 mx-auto">
    <img [src]="data.image" (error)="commonService.imageErrorHandle($event)" alt="Upload Image"
      class="h-full w-full object-contain object-center p-1 rounded-md">
  </div>
  <p class="text-center">{{data.description}}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="grow"></div>


  <button mat-button mat-dialog-close type="button">Close</button>
</mat-dialog-actions>
