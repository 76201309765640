import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/api/auth.service';
import { UserService } from 'src/app/core/services/api/user.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-password-reset-request',
  templateUrl: './password-reset-request.component.html',
  styleUrls: ['./password-reset-request.component.scss']
})
export class PasswordResetRequestComponent implements OnInit {

  public usernameFormGroup: UntypedFormGroup;

  public isPageLoading: boolean = false;
  public requestSubmitted: boolean = false;



  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
  ) {
    this.usernameFormGroup = this.fb.group({
      username: [null, Validators.required]
    });
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    if (this.usernameFormGroup.valid) {
      this.isPageLoading = true;
      this.authService.requestPasswordReset(this.usernameFormGroup.value.username).pipe(take(1)).subscribe(data => {
        this.requestSubmitted = true;
        this.isPageLoading = false;
      })
    }

  }

}
