import { Component, OnInit, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { SelectedProductsService } from 'src/app/pages/school/school-products/selected-products.service';
import { ModelStateService } from '../../services/state/model-state.service';
import { tap } from 'rxjs';
import { Model } from '../../models/blank';
import { BlankSelectionService } from '../../services/state/blank-selection.service';

@UntilDestroy()
@Component({
  selector: 'common-product-model-menu',
  templateUrl: './product-model-menu.component.html',
  styleUrls: ['./product-model-menu.component.scss'],
  animations: [fadeInRight400ms, stagger40ms]
})
export class ProductModelMenuComponent implements OnInit {
  modelStateService = inject(ModelStateService);
  blankSelectionService = inject(BlankSelectionService);

  selected: boolean = true;
  notSelected: boolean = false;
  all: string = 'all';
  filterBy = 'department';

  public items$ = this.modelStateService.filteredItems$.pipe(
    tap(items => {
      if (items.length > 0 && items[0].items.length > 0) {
        // this.selectedProductService.setModel(items[0].items[0].id)
      }
    })
  )

  activeCategory: any['id'] = 'all';

  constructor(
  ) { }

  ngOnInit() {
  }

  filterByUpdate(event: any): void {
    this.modelStateService.filterBy$.next(event.value);
  }

  setFilter(model: Model) {
    this.activeCategory = model;
    this.blankSelectionService.setModel(model);
  }

  isActive(category: any['id']) {
    return this.activeCategory === category;
  }

}
