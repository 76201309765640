import { createFeatureSelector, createReducer, on, Action, createSelector } from '@ngrx/store';
import { PlayerSalesState, setPlayerSalesState } from '../actions/player-sales.actions';


export const initialState: PlayerSalesState = {
    categorySalesReport: null,
}

export const playerSalesReducer = createReducer(
    initialState,
    on(setPlayerSalesState, (state, { payload }) => {
        return payload;
    })
);

export const PlayerSalesReducer = (state: any, action: Action) => {
    return playerSalesReducer(state, action);
}

export const PlayerSales = createFeatureSelector<PlayerSalesState>('playerSales');

export const getPlayerSalesState = createSelector(
    PlayerSales,
    (state: PlayerSalesState) => state,
)
