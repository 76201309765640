import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SchoolLogo } from '../../models/school';

@Component({
  selector: 'app-logo-selector',
  templateUrl: './logo-selector.component.html',
  styleUrls: ['./logo-selector.component.scss']
})
export class LogoSelectorComponent {
  @Input({required: true}) logoId: number | string;
  @Input({required: true}) logos: SchoolLogo[];
  @Output() logoIdChange = new EventEmitter<number | string>();
}
