import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ReportingEntityService } from '../api/reporting-entity.service';
import { ReportingEntity } from '../../models/reporting';

@Injectable({
  providedIn: 'root'
})
export class ReportingEntityStateService {
  reportingEntityState = inject(ReportingEntityService);

  private readonly _entitySource = new BehaviorSubject<ReportingEntity[]>(null);
  public readonly entities$ = this._entitySource.asObservable();

  public init(): void {
    if (!this._entitySource.getValue()) {
      this.reportingEntityState.getAll().subscribe(entities => {
        this._entitySource.next(entities);
      })
    }
  }

  getEntities(): ReportingEntity[] {
    return this._entitySource.getValue();
  }

}