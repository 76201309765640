import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { take } from 'rxjs';
import { CommonService } from '../../services/api/common.service';
import { NotifierService } from '../../services/notifier.services';

@Component({
    selector: 'app-upload-file',
    styleUrls: ['./upload-file.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UploadFileComponent),
            multi: true
        }
    ],
    template: `
    <button *ngIf="!disabled"  type="button" mat-raised-button color="primary" [disabled]="buttonText === 'Loading'" (click)="fileInput.click()">
    <mat-icon class="ltr:-ml-2 rtl:-mr-2 ltr:mr-2 rtl:ml-2"
    >cloud_upload</mat-icon>
    <span>{{buttonText}}</span>
    </button>
    <input hidden type="file" id="fileInput" #fileInput (change)="getFilePath('fileInput')">
  `,
})
export class UploadFileComponent implements OnInit {
    selectedFileUrl: string;
    @Input() field: string;
    @Input() model: string;
    @Input() updatedEndpoint:string;
    @Input() modelId: any;
    @Input() disabled: boolean = false;
    @Input() allowedExtensions: any = /(jpg|jpeg|png|gif)$/i;
    @Output() output : EventEmitter<any> = new EventEmitter();
    buttonText = "Upload";
    constructor(
        private el: ElementRef,
        private commonService: CommonService,
        private notifier: NotifierService
    ) { }
    ngOnInit(): void { }
    getFilePath(element) {

        let inputEl: HTMLInputElement = this.el.nativeElement.querySelector('#' + element);
        let formData = new FormData();
        let formField = (this.field.split(/(?=[A-Z])/).join('_')).toLowerCase();

        // var allowedExtensions = /(jpg|jpeg|png|gif|pdf|docx|xlsx)$/i;
        // if (!allowedExtensions.exec(inputEl.files.item(0).type)) {
        //     this.el.nativeElement.querySelector('#' + element).value = "";
        //     return this.notifier.alert({ message: 'Select image extensions only', type: 'error' });
        // }

        formData.append('field', formField);
        formData.append('file', inputEl.files.item(0));
        this.buttonText = "Loading";
        this.commonService.uploadFile(this.model, formData, this.modelId,this.updatedEndpoint).pipe(take(1)).subscribe(res => {
            if (res.error) return this.notifier.alert({ message: res.error.message, type: 'error' });
            if (res.data[this.field]) {
                this.propagateChange(res.data[this.field]);
                this.output.emit(res.data[this.field]);
            }
            this.buttonText = "Upload";
            this.commonService.closeDialog.next(true)
            return this.notifier.alert({ message: res.message, type: 'success' });
        })
    }
    writeValue(value: any) {
        if (value !== undefined) {
            this.selectedFileUrl = value;
        }
    }
    propagateChange = (_: any) => { };

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() { }
}

