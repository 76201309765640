import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CategorySalesReport, PlayerSalesReport, SportSalesReport, TotalSalesReport } from '../../models/sales';
import { School } from '../../models/school';
import { HttpHelperService } from '../http-helper.service';
@Injectable({
  providedIn: 'root'
})
export class SchoolSalesService {
  totalSales$ : TotalSalesReport=null;
  categorySales$ : CategorySalesReport[]=null;
  sportSales$ : SportSalesReport[]=null;
  playerSales$ : PlayerSalesReport[]=null;

  constructor(private httpHelper: HttpHelperService) { }

  resetState(){
    this.totalSales$ = this.categorySales$= this.sportSales$ = null;
  }

  totalSalesReport(school: School, start: Date, end: Date): Observable<TotalSalesReport> {
    let params = new HttpParams({
      fromObject: {
        'start_date': start.toISOString().split('T')[0],
        'end_date': end.toISOString().split('T')[0]
      }
    });
    return this.httpHelper.request<TotalSalesReport>(`schools/${school.id}/total_sales_report`, { method: 'GET', search: params });
  }

  categorySalesReport(school: School, start: Date, end: Date): Observable<CategorySalesReport[]> {
    let params = new HttpParams({
      fromObject: {
        'start_date': start.toISOString().split('T')[0],
        'end_date': end.toISOString().split('T')[0]
      }
    });
    return this.httpHelper.request<CategorySalesReport[]>(`schools/${school.id}/category_sales_report`, { method: 'GET', search: params });
  }

  sportSalesReport(school: School, start: Date, end: Date): Observable<SportSalesReport[]> {
    let params = new HttpParams({
      fromObject: {
        'start_date': start.toISOString().split('T')[0],
        'end_date': end.toISOString().split('T')[0]
      }
    });
    return this.httpHelper.request<SportSalesReport[]>(`schools/${school.id}/sport_sales_report`, { method: 'GET' , search: params});
  }

  playerSalesReport(school: School, start: Date, end: Date): Observable<PlayerSalesReport[]> {
    let params = new HttpParams({
      fromObject: {
        'start_date': start.toISOString().split('T')[0],
        'end_date': end.toISOString().split('T')[0]
      }
    });
    return this.httpHelper.request<PlayerSalesReport[]>(`schools/${school.id}/player_sales_report`, { method: 'GET' , search: params});
  }
}
