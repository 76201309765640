<div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8 bg-slate-100">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <img class="mx-auto h-10 w-auto" src="assets/img/icon.png" alt="Athletic Solutions">
    <h2 class="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-primary">Password Reset Request</h2>
  </div>

  <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
    <div class="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12" [componentIsLoading]="this.isPageLoading" >
      <form class="space-y-6" [formGroup]="usernameFormGroup" *ngIf="!requestSubmitted else requestSent">
        <div>
          <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email/Username</label>
          <div class="mt-2">
            <input id="email"
              formControlName="username"
              autocomplete="off"
              name="email"
              type="text"
              required
              class="block px-2 w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
          </div>
        </div>
        <div>
          <button
            (click)="onSubmit()"
            type="submit"
            [disabled]="!usernameFormGroup.valid"
            class="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">Send Reset Request</button>
        </div>
      </form>
    </div>
  </div>
</div>
<ng-template #requestSent>
  <div class="text-lg">Password reset instructions sent!</div>
</ng-template>



<!-- <link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Nunito&display=swap" rel="stylesheet">

<div [componentIsLoading]="this.isPageLoading" class="login flex flex-col items-center justify-center h-screen">
  <div class="w-full flex items-center justify-center">
    <mat-card class="login-card flex items-stretch">
      <div class="left items-stretch flex-grow">
        <h2>Password Reset</h2>
        <div class="content" *ngIf="!requestSumbitted">
          <div class="text-lg">To reset your password, please provide your Athletic Solutions Username/Email.</div>
          <form [formGroup]="usernameFormGroup" class="flex flex-col">
            <div class="form-group">
              <mat-form-field appearance="outline" color="accent" class="pb-0">
                <mat-label>E-mail/Username</mat-label>
                <input type="text" formControlName="username" class="form-control" autocomplete="off" matInput />
                <mat-icon matSuffix>account_circle</mat-icon>
              </mat-form-field>
            </div>
            <div class="form-group text-center  md:text-right">
              <button class="btn btn-primary ml-auto" color="primary" mat-flat-button [disabled]="!usernameFormGroup.valid" (click)="onSubmit()" >Send Reset Request</button>
            </div>
          </form>
        </div>
        <div class="content" *ngIf="requestSumbitted">
          <div class="text-lg">Password Reset Instructions have been send to the requested user's Email!</div>
        </div>
      </div>
    </mat-card>
  </div>
</div> -->
