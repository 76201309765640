<form (ngSubmit)="submit()" [formGroup]="form">
  <div class="flex items-center" mat-dialog-title>

    <h2 class="headline m-0 flex-auto">Submit Additional Docment Signers</h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider class="text-border"></mat-divider>
  <ng-container formArrayName="signers">
    <ng-container *ngFor="let signer of signers.controls; let i = index">
      <div [formGroupName]="i">
        <div class="text-center text-xl mt-1">Submit {{signer.value.role.signerRole}} Information</div>
        <mat-dialog-content class="flex flex-col">
          <mat-form-field class="flex-auto">
            <mat-label appRemoveAriaOwns>Email</mat-label>
            <input formControlName="email" matInput [email]="true">
            <mat-icon class="mr-3" matPrefix>email</mat-icon>
          </mat-form-field>
          <mat-form-field class="flex-auto">
            <mat-label appRemoveAriaOwns>First Name</mat-label>
            <input formControlName="firstName" matInput>
          </mat-form-field>
          <mat-form-field class="flex-auto">
            <mat-label appRemoveAriaOwns>Last Name</mat-label>
            <input formControlName="lastName" matInput>
          </mat-form-field>
          <mat-form-field class="flex-auto">
            <mat-label appRemoveAriaOwns>Street Address</mat-label>
            <input formControlName="address" matInput>
          </mat-form-field>
        </mat-dialog-content>
      </div>

    </ng-container>
  </ng-container>




  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Cancel</button>
    <button color="primary" mat-flat-button type="submit" [disabled]="!form.valid">Create Documnet</button>
  </mat-dialog-actions>
</form>
