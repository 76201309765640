import { Component, ElementRef, Input, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { ApexOptions } from '../../chart/chart.component';
import { defaultChartOptions } from '../../../utils/default-chart-options';
import { createDateArray } from '../../../utils/create-date-array';
import { SalesByDay } from 'src/app/core/models/home';
import { ExportService } from 'src/app/core/services/api/export.service';

@Component({
  selector: 'vex-widget-large-chart',
  templateUrl: './widget-large-chart.component.html',
  styleUrls: ['./widget-large-chart.component.scss']
})
export class WidgetLargeChartComponent implements OnInit {
  weeklySalesCsvheaders: string[] = ['day', 'count', 'sales'];
  weeklySalesNewCsvheaders: string[] = ['DAY', 'COUNT', 'SALES'];
  @ViewChild('weeklySalesChart') weeklySalesChart!: ElementRef;
  @Input() adminAnalyticsChartXAxis;
  @Input() adminAnalyticsChartType;
  @Input() labels: string[];
  @Input() legend;
  @Input() dataLabels;
  @Input() colors;
  @Input() title: string;
  @Input() series: ApexNonAxisChartSeries | ApexAxisChartSeries | number[];
  @Input() data: SalesByDay[];


  @Input() options: ApexOptions = defaultChartOptions({
    grid: {
      show: true,
      strokeDashArray: 3,
      padding: {
        left: 16
      }
    },
    stroke: {
      show: false,    
    },
    chart: {
      type: 'area',
      height: 384,
      sparkline: {
        enabled: false
      },
      zoom: {
        enabled: false
      }
    },
    plotOptions: {
      pie:{
        donut: {
          labels: {
            show: true,
          }
        }
      }
    },
    series: [],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0.9,
        opacityFrom: 0.7,
        opacityTo: 0.5,
        stops: [0, 90, 100]
      }
    },
    colors: ['#115a7b', '#d59636', '#2196f3','#ffd740'],
    labels: [],
    dataLabels: { // add this part to remove %
      enabled: true,
      formatter(value: any, opts: any): any {
        return opts.w.config.series[opts.seriesIndex];
      },
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        colors: [function({ value}) {
          if (value > 0) {
              return '#000'
          }
        }]
        },
      dropShadow: {
        enabled: false,
        color: '#000',
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        show: true
      },
    },
    yaxis: {
      labels: {
        show: true
      }
    },
    legend: {
      show: false,
      itemMargin: {
        horizontal: 4,
        vertical: 4
      }
    }
  });

  constructor(private exportService: ExportService) {
    this.options.series = this.series;
    this.options.labels = this.labels;

  }

  ngOnInit() {
    if (this.adminAnalyticsChartXAxis) {
      this.options.xaxis.type = this.adminAnalyticsChartXAxis;
    }
    if (this.labels) {
      this.options.labels = this.labels;
    }
    if (this.legend) {
      this.options.legend = this.legend;
    }
    if (this.adminAnalyticsChartType) {
      this.options.chart.type = this.adminAnalyticsChartType;
    }
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   if (this.labels) {
  //     this.options.labels = this.labels;
  //   }
  // }

  weeklySalesPDF() {
    this.exportService.generatePDF(this.weeklySalesChart.nativeElement, 'weeklySales')
  }


  weeklySalesCSV() {
    this.data.forEach(element => {
      element.day = element.day.substring(0, 10)
    });
    this.exportService.downloadCsvFile(this.data, this.weeklySalesCsvheaders, 'weeklySales.csv', this.weeklySalesNewCsvheaders)

  }

}
