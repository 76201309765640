import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, Observable, startWith, Subscription } from 'rxjs';
import { ConfigService } from 'src/@vex/config/config.service';
import { LayoutService } from 'src/@vex/services/layout.service';
import { checkRouterChildsData } from 'src/@vex/utils/check-router-childs-data';
import { SideNavComponent } from '../../core/components/side-nav/side-nav.component';
import { UserService } from '../../core/services/api/user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SidebarComponent } from 'src/@vex/components/sidebar/sidebar.component';

@UntilDestroy()
@Component({
  selector: 'portal-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isFooterVisible$ = this.configService.config$.pipe(map(config => config.footer.visible));
  isDesktop$ = this.layoutService.isDesktop$;

  opened_: boolean = true
  public showSideNav = true;
  public globalTrendOpen = false;
  public media!: Observable<boolean>;
  public mediaSubscription!: Subscription;
  public mediaShowSideNav = true;
  public sideNavMode: MatDrawerMode = 'side';
  // contentMargin:any=240;

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled))
  );

  @ViewChild('sidenav')
  sideNav!: SideNavComponent;

  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;


  private mediaMap = new Map([
    ['xs', false],
    ['sm', false],
    ['md', false],
    ['lg', true],
    ['xl', true]
  ]);

  constructor(
    private userService: UserService,
    public breakpointObserver: BreakpointObserver,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.HandsetPortrait])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.opened_ = false;
        } else {
          this.opened_ = true;
        }
      });
    if (this.router.url === '/' && this.userService.currentUser) {
      this.userService.navigateHome();
    }
    
  }




}
