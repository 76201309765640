<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Nunito&display=swap" rel="stylesheet">
<div class="flex h-full max-h-full flex-col justify-center sm:py-12 sm:px-6 lg:px-8 bg-slate-100 overflow-y-auto">
  <div class="sm:mx-auto sm:w-full sm:max-w-md mb-3">
    <img class="mx-auto h-10 w-auto" src="assets/img/icon.png" alt="Athletic Solutions">
    <h2 class="mt-2 text-center text-2xl font-bold leading-9 tracking-tight text-primary">Athlete Signup</h2>
  </div>
  <div class="mt-2 sm:mx-auto sm:w-full sm:max-w-[480px]">
    <div class="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12" [componentIsLoading]="this.isPageLoading" *ngIf="!afterSubmit">
      <form class="space-y-6" [formGroup]="signupForm" (ngSubmit)="onSubmit()">
        <div>
          <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Find your school</label>
          <div class="card rounded-md px-4 flex-auto flex items-center border border-gray-300 searchProducts"
            [blockLoader]="loadingSchools">
            <mat-icon class="icon-sm text-secondary">search</mat-icon>
            <input #inpRef [formControl]="schoolSearchControl" [matAutocomplete]="auto"
              class="appearance-none px-4 py-2 border-0 outline-none w-full bg-transparent" placeholder="Find your school" type="search">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="schoolSelected($event)">
              <mat-option [class.noResults]="school?.fullName === 'No Results'" [disabled]="school?.fullName === 'No Results'"
                *ngFor="let school of schoolFilter | async" [value]="school.fullName">
                {{school.fullName}}
              </mat-option>
            </mat-autocomplete>
          </div>
        </div>

        <div>
          <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address</label>
          <div class="mr-4">
            <input id="email" formControlName="email" autocomplete="off" name="email" type="text" required
              class="appearance-none block px-2 w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
          </div>
        </div>
        <div class="flex flex-row gap-2">
          <div class="flex-1"></div>
          <button class="btn btn-primary ml-auto" color="primary" mat-raised-button [disabled]="!signupForm.valid"
            [buttonLoader]="isLoading">Send Verification</button>
        </div>
      </form>
    </div>
    <div class="m-4" *ngIf="afterSubmit">
      <div class="rounded-md border-l-4 p-4"
        [ngClass]="{'border-yellow-400 bg-yellow-50': !success, 'border-green-400 bg-green-50': success}">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" *ngIf="success">
              <path fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clip-rule="evenodd" />
            </svg>
            <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
              *ngIf="!success">
              <path fill-rule="evenodd"
                d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <h3 class="text-sm font-medium" [ngClass]="{'text-yellow-800': !success, 'text-green-800': success}">{{ success
              ?
              'Success' : 'Failure' }}</h3>
            <div class="mt-2 text-sm" [ngClass]="{'text-yellow-700': !success, 'text-green-700': success}">
              <p>{{ message }}</p>
            </div>
            <div class="mt-4">
              <div class="-mx-2 -my-1.5 flex">
                <button type="button" [ngClass]="{
                                      'bg-green-50 text-green-800 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50': success,
                                      'bg-yellow-50 text-yellow-800 hover:bg-yellow-100 focus:ring-yellow-600 focus:ring-offset-yellow-50': !success,
                                    }"
                  class="rounded-md px-2 py-1.5 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2"
                  (click)="reset()">Back</button>
                <button type="button" *ngIf="success"
                  class="bg-green-50 text-green-800 focus:ring-green-600 focus:ring-offset-green-50 ml-3 rounded-md px-2 py-1.5 text-sm font-medium hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  (click)="gotoLogin()">Login</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
