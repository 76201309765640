import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LicenseService } from '../api/license.service';
import { License } from '../../models/license';

@Injectable({
  providedIn: 'root'
})
export class LicenseStateService {
  licenseService = inject(LicenseService);

  private readonly _licensesSource = new BehaviorSubject<License[]>(null);
  public readonly licenses$ = this._licensesSource.asObservable();

  public init(schoolId: number): void {
    if (!this._licensesSource.getValue()) {
      if (schoolId) {
        this.licenseService.getAll({ school: schoolId }).subscribe(licenses => {
          this._licensesSource.next(licenses);
        })
      } else {
        this.licenseService.getAll().subscribe(licenses => {
          this._licensesSource.next(licenses);
        })
      }

    }
  }

  getLicenses(): License[] {
    return this._licensesSource.getValue();
  }

}


