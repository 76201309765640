<mat-card >
  <mat-card-title class="text-base">
    <span *ngIf="userService.isAdmin()" class="absolute top-[10px] dark-white-bg right-[10px] text-gray-900 dark:text-white rounded px-2 py-1 font-semibold text-xs flex-none bg-emerald-300">
      School: {{logo?.school}} Logo: {{logo?.id}} Full: {{logo?.school}}_{{logo?.id}}
    </span>
    <div class="flex flex-row">
      <div class="flex items-center">
        {{logo.name}} <mat-icon *ngIf="logo.main" class="ml-1 text-sm text-green-400">verified</mat-icon>
      </div>
      <mat-icon *ngIf="!editing" color="primary" (click)="editing = true"
        class="cursor-pointer ml-3">edit</mat-icon>
      <mat-icon *ngIf="editing" color="warn" (click)="editing = false"
        class="cursor-pointer ml-3">close</mat-icon>
      <div class="flex-1"></div>
      <mat-icon class="flex-0" color="warn" (click)="delete()"
        class="cursor-pointer">delete</mat-icon>
    </div>
    <div class="overflow-hidden" [@collapse]="!editing" @fadeInUp>
      <div class="text-center rounded border-gray-200 border py-1 flex flex-col gap-2 p-2">
        <div class="flex flex-row gap-2">
          <mat-form-field  class="!py-0 max-h-fit">
            <mat-label appRemoveAriaOwns>Logo Name</mat-label>
            <input matInput name="name" required [(ngModel)]="logo.name">
          </mat-form-field>
          <div class="flex flex-col">
            <mat-checkbox class="mr-2" [(ngModel)]="logo.main" color="primary">Main Logo?</mat-checkbox>
            <mat-checkbox class="mr-2" [(ngModel)]="logo.embroideryNeeded" color="primary">Embroidery Needed?</mat-checkbox>
          </div>
        </div>

        <button mat-raised-button color="primary" class="save-button" (click)="saveLogo(logo)"
          [class.spinner]="loader">Save Changes</button>
      </div>
    </div>
  </mat-card-title>
  <mat-card-content class="flex flex-col sm:flex-wrap gap-3 sm:flex-row justify-between items-center">
    <div class="logoWraper rounded border-gray-200 border flex flex-col flex-1">
      <img class="w-48 h-48" alt="Upload Image" [src]="logo?.originalUpload"
        (error)="commonService.imageErrorHandle($event)" *ngIf="logo?.originalUpload" />
      <app-upload-file class="form-control"
        [disabled]="logo.originalUpload != undefined"
        [modelId]="logo?.id"
        model="school-logos"
        field="originalUpload"
        [(ngModel)]="logo.originalUpload"
        (output)="updateLogo(logo)"
        [ngModelOptions]="{standalone: true}">
      </app-upload-file>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button color="primary" (click)="openColorwayModal(logo)" class="w-full">
      Show All Product Colorways
    </button>
  </mat-card-actions>
  <mat-stepper class="bg-slate-200 rounded-md p-2" #stepper labelPosition="bottom" [selectedIndex]="this.index">
    <mat-step [editable]="false" [completed]="index > 0" >
      <ng-template matStepLabel>
        <div class="text-sm">Upload</div>
      </ng-template>
      <div class="text-sm text-center">Please Upload your logo, we will handle the rest!</div>
    </mat-step>
    <mat-step [editable]="false" [completed]="index > 1" >
      <ng-template matStepLabel>
        <div class="text-sm">Processing</div>
      </ng-template>
      <div class="text-sm text-center">Our design team is processing your logo. We will create all needed versions to display on products of any color!</div>
    </mat-step>
    <mat-step [editable]="false" [completed]="index > 1" state="done">
      <ng-template matStepLabel >
        <div class="text-sm">Ready</div>
      </ng-template>
    </mat-step>
    <ng-template matStepperIcon="done">
      <mat-icon>done</mat-icon>
    </ng-template>
  </mat-stepper>
</mat-card>
