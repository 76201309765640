import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { ConfirmDialogComponent } from 'src/app/core/components/confirm-dialog/confirm-dialog.component';
import { SchoolTemplateDesign } from 'src/app/core/models/templates';
import { User } from 'src/app/core/models/user';
import { ConfirmDialog } from 'src/app/core/models/utils';
import { CommonService } from 'src/app/core/services/api/common.service';
import { SchoolTemplateDesignsService } from 'src/app/core/services/api/school-template-designs.service';
import { NotifierService } from 'src/app/core/services/notifier.services';

@Component({
  selector: 'app-school-template-design-view-update',
  templateUrl: './school-template-design-view-update.component.html',
  styleUrls: ['./school-template-design-view-update.component.scss'],
  animations: [
    fadeInUp400ms
  ]
})
export class SchoolTemplateDesignViewUpdateComponent implements OnInit {
  form: UntypedFormGroup;
  loader: boolean;
  user: User;
  enable: boolean;
  designStatus: string;
  @Input() initialData: SchoolTemplateDesign;
  @Output() output: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: UntypedFormBuilder,
    private notifier: NotifierService,
    public commonService: CommonService,
    private schoolTemplateDesignsService: SchoolTemplateDesignsService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      player: [this.initialData.player || ''],
      schoolTemplate: [this.initialData.id || ''],
      schoolSport: [''],
      name: ['', Validators.required]
    });
  }

  updateSchoolTemplate(data) {
    this.loader = true;
    this.schoolTemplateDesignsService.edit(data).subscribe(result => {
      this.loader = false;
      if (!result) return this.notifier.alert({ type: 'warning', message: 'Something went wrong' });
      this.initialData.approved = result.approved;
      this.initialData.approvedAt = result.approvedAt;
      this.initialData.rejectedAt = result.rejectedAt;
      this.initialData.rejectedReason = result.rejectedReason;
      this.output.emit(this.initialData);
      this.notifier.alert({ type: 'success', message: 'Updated Successfully' });
    }, err => {
      this.loader = false;
    })
  }

  rejectDesign(value: string) {
    if (!value || value == '') return this.notifier.alert({ type: 'warning', message: 'Write some content !!' })
    let data = {
      id: this.initialData.id,
      rejectedReason: value,
      approved: false
    }
    this.updateSchoolTemplate(data);
  }

  getGroupStyles(): string[] {
    if (!this.initialData.approved && !this.initialData.rejectedAt) {
      this.designStatus = 'Under Review';
      return ["text-amber", "bg-amber-light"]
    } else if (!this.initialData.approved && this.initialData.rejectedAt) {
      this.designStatus = 'Rejected';
      return ["text-red", "bg-red-light"]
    } else if (this.initialData.approved) {
      this.designStatus = 'Approved';
      return ["text-green", "bg-green-light"]
    } else {
      this.designStatus = 'Un Approved';
      return ["text-cyan", "bg-cyan-light"]
    }
  }

  approvalChange() {
    let value = this.initialData.approved ? 'Un Aprrove' : 'Approve';
    let data: ConfirmDialog = { content: value };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: data });
    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      let data = {
        id: this.initialData.id,
        approved: !this.initialData.approved
      };
      this.updateSchoolTemplate(data);
    });
  }
  closed() {
    this.close.emit(true);
  }
}
