<div class="" mat-dialog-title>
  <div class="flex items-center">
    <h2 class="text-base md:text-2xl m-0 flex-auto">Audit Logs</h2>
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="flex items-center gap-2 justify-between w-full">
    <mat-form-field >
      <mat-label>Item</mat-label>
      <mat-select [formControl]="auditFilter" class="form-control" >
        <mat-option [value]="-1">All</mat-option>
        <mat-option [value]="0">Only Order</mat-option>
        <mat-option *ngFor="let item of filterOptions" [value]="item.id">{{item.id}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field >
      <mat-label>Log Type</mat-label>
      <mat-select [formControl]="logTypeFilter" class="form-control" >
        <mat-option *ngFor="let item of LOG_TYPES" [value]="item">{{item}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<mat-divider class="text-border"></mat-divider>

<mat-dialog-content class="gap-2 min-w-[500px] !py-0 min-h-full flex-1">
  <div *ngIf="filteredLogs$ | async as filteredLogs">
    <ul role="list" class="space-y-6 min-h-64" *ngIf="filteredLogs.length > 0 else emptyLogs">
      <ng-container *ngFor="let log of filteredLogs; let i = index;">
        <li class="relative flex gap-x-4" *ngIf="log.action === 'STATUS_CHANGE'">
          <div class="absolute -bottom-6 left-0 top-0 flex w-6 justify-center" [ngClass]="{'-bottom-6': !isLastItem(i, filteredLogs.length)}">
            <div class="w-px bg-gray-200"></div>
          </div>
          <div class="relative flex h-6 w-6 flex-none items-center justify-center bg-white" [matTooltip]="log.itemStatus">
            <div class="h-1.5 w-1.5 rounded-full" [ngClass]="{
              'bg-fuchsia-400 ring-fuchsia-600': ['CREATED', 'STAGED'].includes(log.itemStatus),
              'bg-red-400 ring-red-600': ['REJECTED', 'BACK_ORDERED', 'ERROR', 'CANCELLED'].includes(log.itemStatus),
              'bg-yellow-400 ring-yellow-600': ['PROCESSED', 'PRINTING', 'STASHED'].includes(log.itemStatus),
              'bg-green-400 ring-green-600': log.itemStatus === 'SHIPPED',
              'bg-cyan-400 ring-cyan-600': log.itemStatus === 'READY_TO_SHIP',
            }"></div>
          </div>
          <div class="w-full">
            <div class="inline-flex w-full">
              <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">#{{ log.orderPartItem ? log.orderPartItem : 'Order'}}</p>
              <time datetime="2023-01-23T10:32" class="flex-none py-0.5 text-xs leading-5 text-gray-500">{{log.createdAt | date: 'short' }}</time>
            </div>
            <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500"><span class="font-medium text-gray-900">{{log.message}}</span></p>
          </div>
        </li>
        <li class="relative flex gap-x-4" *ngIf="log.action === 'COMMENT'">
          <div class="absolute left-0 top-0 flex w-6 justify-center" [ngClass]="{'-bottom-6': !isLastItem(i, filteredLogs.length)}">
            <div class="w-px bg-gray-200"></div>
          </div>
          <img src="assets/img/icon.png" alt="" class="h-6 w-6 object-contain flex-none rounded-full bg-gray-50">
          <div class="flex-auto">
            <div class="flex justify-between gap-x-4">
              <div class="py-0.5 text-xs leading-5 text-gray-500"><span class="font-medium text-gray-900">{{log.username}}</span></div>
              <time datetime="2023-01-23T10:32" class="flex-none py-0.5 text-xs leading-5 text-gray-500">{{log.createdAt | date: 'short' }}</time>
            </div>
            <p class="text-sm leading-6 text-gray-500">{{log.message}}</p>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
  
</mat-dialog-content>



<mat-dialog-actions class="flex-col" >
  <div class="flex-row-reverse w-full">
    <button mat-button mat-dialog-close type="button">Cancel</button>
  </div>
</mat-dialog-actions>

<ng-template #emptyLogs>
  <div class="flex items-center justify-center h-64">
    <p class="text-gray-500">No logs found</p>
  </div>  
</ng-template>