<div class="flex flex-col h-full">
  <div class="flex-0 flex items-center" mat-dialog-title>
    <h2 class="text-base md:text-2xl m-0 flex-auto">Design Selection</h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-divider class="text-border"></mat-divider>
  <mat-dialog-content class="flex-1 card flex items-start shrink overflow-y-auto">
    <ul role="list" class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
      <li class="relative" *ngFor="let design of designs" (click)="this.dialogRef.close(design)">
        <div
          class="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
          <img
            [src]="design.originalUpload"
            alt="" class="pointer-events-none object-cover group-hover:opacity-75">
          <button type="button" class="absolute inset-0 focus:outline-none">
            <span class="sr-only">View details for IMG_4985.HEIC</span>
          </button>
        </div>
        <p class="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{{design.name}}</p>
        <p class="pointer-events-none block text-sm font-medium text-gray-500">3.9 MB</p>
      </li>

      <!-- More files... -->
    </ul>
  </mat-dialog-content>
  <!-- <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Cancel</button>
    <button color="primary" mat-flat-button>Submit</button>
  </mat-dialog-actions> -->
</div>
