import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/api/auth.service';
import { UserService } from '../services/api/user.service';
import { NotifierService } from '../services/notifier.services';

@Injectable({
  providedIn: 'root',
})
export class OrgGuard  {
  constructor(
    private notifier: NotifierService,
    private authService: AuthService,
    public userService: UserService,
    public router: Router,
  ) {}

  canActivate(): boolean {
    if (!this.userService.isOrg()) {
      if (this.userService.isPlayer()) {
        this.router.navigate(['/player/home']);
      } else if (this.userService.isAdmin()) {
        this.router.navigate(['/admin/home']);
      } else if (this.userService.isSchool()) {
        this.router.navigate(['/school/home']);
      }   else {
        if (this.router.url !== '/') {
          localStorage.setItem('lastUrl', this.router.url);
        }
        this.authService.logout();
        this.notifier.alert({
          message: 'Permission Denied Login Again !',
          type: 'error',
          duration: 3000,
        });
      }
      return false;
    } else {
      return true;
    }
  }
  canActivateChild(): boolean {
    if (!this.userService.isOrg()) {
      if (this.userService.isPlayer()) {
        this.router.navigate(['/player/home']);
      } else if (this.userService.isAdmin()) {
        this.router.navigate(['/admin/home']);
      } else if (this.userService.isSchool()) {
        this.router.navigate(['/school/home']);
      }  else {
        if (this.router.url !== '/') {
          localStorage.setItem('lastUrl', this.router.url);
        }
        this.authService.logout();
        this.notifier.alert({
          message: 'Permission Denied Login Again !',
          type: 'error',
          duration: 3000,
        });
      }
      return false;
    } else {
      return true;
    }
  }
}
