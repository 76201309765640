import { Action, createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { PlayerMySportsState, setPlayerMySports } from "../actions/player-mysports.actions";

export const initialState : PlayerMySportsState = {
    sportsList : []
}

export const playerMySportsReducers = createReducer(
    initialState,
    on(setPlayerMySports, (state, { payload }) => {
        return payload;
    })
);

export const playerMySportsReducer = (state: any, action: Action) => {
    return playerMySportsReducers(state, action);
}

export const getPlayerMySportsState = createFeatureSelector<PlayerMySportsState>('playerMySportsState');
export const getPlayerMySports = createSelector(getPlayerMySportsState, (state: PlayerMySportsState) => state);