<a (click)="clicked(document)"
   [class.bg-hover]="(selectedDocument$ | async) && (selectedDocument$ | async)?.pandaTemplate.id == document?.pandaTemplate.id"
   class="flex px-4 py-3 transition ease-in-out duration-200 h-28 hover:bg-primary/10"
   routerLinkActive="active">
  <div class="flex-none flex flex-col ltr:pr-4 rtl:pl-4 w-7">
    <div [class.hidden]="!document?.required && !document?.pandaDocument?.signedAt"
         class="flex-none block mx-auto p-1 rounded-full hover:bg-hover relative"
         matRipple>
      <mat-icon [class.text-green]="document?.pandaDocument?.signedAt"
                [class.text-orange]="!document?.pandaDocument?.signedAt"
                class="icon-sm">{{!document?.pandaDocument?.signedAt ? 'history_edu' : 'task'}}</mat-icon>
    </div>
  </div>

  <div class="wrap w-full h-full">
    <div class="flex flex-col items-start">
      <p [ngClass]="{ 'font-bold': !document?.pandaDocument?.createdAt }"
         class="flex-auto text-s leading-normal text-primary">{{ document?.pandaTemplate.name }}</p>
      <div [ngClass]="{ 'font-bold': !document?.pandaDocument?.createdAt }">
         <p class="flex-none text-xs leading-normal text-secondary" *ngIf="document?.pandaDocument?.createdAt">Created:{{ document?.pandaDocument?.createdAt | date:'shortDate' }}</p>
         <p class="flex-none text-xs leading-normal text-secondary font-bold" *ngIf="!document?.pandaDocument?.createdAt">Needs To be Created</p>
      </div>
      <div [ngClass]="{ 'font-bold': !document?.pandaDocument?.signedAt }">
        <p *ngIf="document?.pandaDocument?.signer"
       class="flex-auto text-s leading-normal text-primary">Signer: {{ document?.pandaDocument.signer.firstName }} {{ document?.pandaDocument.signer.lastName }}</p>
       <p *ngIf="!document?.pandaDocument?.signer"
       class="flex-auto text-s leading-normal text-primary">Signer: No Signer Assigned</p>
      </div>
      <div [ngClass]="{ 'font-bold': !document?.pandaDocument?.signedAt }">
        <p class="flex-none text-xs leading-normal text-secondary" *ngIf="document?.pandaDocument?.signedAt">Signed:{{ document?.pandaDocument?.signedAt | date:'shortDate' }}</p>
        <p class="flex-none text-xs leading-normal text-secondary" *ngIf="!document?.pandaDocument?.signedAt">Needs To be Signed</p>
      </div>
    </div>
  </div>
</a>
