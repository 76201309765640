import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PandaDocument } from 'src/app/core/models/document';
import { SchoolDocument } from 'src/app/core/models/school';
import { PandaDocumentsService } from '../../../services/api/panda-documents.service';
import { DocumentsPageService } from '../documents-page.service';
import { PlayerDocument } from '../../../models/school';
import { trackById } from 'src/@vex/utils/track-by';

@Component({
  selector: 'app-document-list-entry',
  templateUrl: './document-list-entry.component.html',
  styleUrls: ['./document-list-entry.component.scss']
})
export class DocumentListEntryComponent implements OnInit {

  @Input() document: SchoolDocument | PlayerDocument;
  @Input() selected: boolean;

  public selectedDocument$ = this.documentPageService.getSelectedDocument();

  trackById = trackById;


  hovered: boolean;

  constructor(
    private cd: ChangeDetectorRef,
    private documentPageService: DocumentsPageService

  ) { }

  ngOnInit(): void {
  }

  isCheckboxVisible() {
    return this.selected || this.hovered;
  }

  onMouseEnter() {
    this.hovered = true;
  }

  onMouseLeave() {
    this.hovered = false;
  }

  onCheckboxChange(event: MatCheckboxChange): void {
  }

  clicked(document: SchoolDocument | PlayerDocument): void {
    this.documentPageService.setSelected(document);

  }

}
