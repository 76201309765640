<div class="flex min-h-full flex-col justify-center py-8 sm:px-6 lg:px-8 bg-slate-100 overflow-y-auto" [blockLoader]="isPageLoading">
  <div class="sm:mx-auto sm:w-full sm:max-w-md" *ngIf="school">
    <img class="mx-auto h-10 w-auto" [src]="school?.mainLogo" alt="Athletic Solutions">
    <h2 class="mt-3 text-center text-xl font-bold leading-9 tracking-tight text-primary" *ngIf="!optOutComplete">{{ school?.fullName }} Store Sign-Up</h2>
    <ng-container *ngIf="optOutComplete">
      <h2 class="mt-3 text-center text-2xl font-bold leading-9 tracking-tight text-primary" *ngIf="optOutComplete">Opt out complete!</h2>
    </ng-container>

  </div>

  <div class="mt-2 sm:mx-auto sm:w-full sm:max-w-[900px] lg:max-w-[1200px]">
    <div class="bg-white px-6 mx-auto py-12 shadow sm:rounded-lg sm:px-12 sm:max-w-[600px]" *ngIf="!optOutComplete">
      <div *ngIf="errorInOptOut" class="bg-red-200 p-3 rounded border border-red-400 flex flex-row mb-3">
        <mat-icon class="mr-2">cancel</mat-icon>
        <div>Unable to login with Username and Password</div>
      </div>
      <div class="flex flex-row items-center px-2 md:px-4 py-4 ">
        <div class="card rounded-full px-4 flex-auto flex items-center border border-gray-300 searchProducts">
          <mat-icon class="icon-sm text-secondary">search</mat-icon>
          <input #inpRef [formControl]="control" [matAutocomplete]="auto"
            class="px-4 py-2 border-0 outline-none w-full bg-transparent" placeholder="Find your name!" type="search">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="updateUser($event)" [displayWith]="displayedPlayerText">
            <mat-option *ngFor="let player of filteredPlayers$ | async" [value]="player">
              <div class="flex sm:flex-row flex-col">
                <span class="truncate">{{player.lastName}}, {{player.firstName}}</span>
              </div>
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>
      <button
        (click)="playerOptOut()"
        [disabled]="!selectedPlayer"
        type="submit"
        [ngClass]="{'!bg-gray-300': !selectedPlayer}"
        class="
          flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white
          shadow-sm hover:bg-primary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">Opt Out!
        </button>
    </div>
  </div>
</div>
