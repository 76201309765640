<div class="card outer-div-template-main" *ngIf="initialData" @fadeInUp>
    <div class="bg-app-bar whole-change-bg-template bg-white-td px-6 h-16 border-b sticky left-0 flex items-center">
        <h2 class="title black my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l flex-none">
            {{initialData?.name}}
        </h2>
        <span class="flex-1"></span>
        <button mat-icon-button type="button" color="warn" (click)="closed()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="flex flex-col sm:flex-row m-2">
        <div class="basis-3/4 flex flex-wrap justify-around px-gutter py-4 items-start" @fadeInUp>
            <div class="flex flex-col items-center font-bold text-gray rounded border border-gray-200 p-1">
                <label>Dark</label>
                <img class="w-28 h-28" alt="Image Not Found" [src]="initialData?.darkColorway"
                    (error)="commonService.imageErrorHandle($event)">
            </div>
            <div class="flex flex-col items-center font-bold text-gray rounded border border-gray-200 p-1">
                <label>light</label>
                <img class="w-28 h-28" mat-card-sm-image alt="Image Not Found" [src]="initialData?.lightColorway"
                    (error)="commonService.imageErrorHandle($event)">
            </div>
            <div class="flex flex-col items-center font-bold text-gray rounded border border-gray-200 p-1">
                <label>White</label>
                <img class="w-28 h-28" mat-card-sm-image alt="Image Not Found" [src]="initialData?.whiteColorway"
                    (error)="commonService.imageErrorHandle($event)">
            </div>
            <div class="flex flex-col items-center font-bold text-gray rounded border border-gray-200 p-1"
                *ngIf="initialData?.primaryColorway">
                <label>Primary</label>
                <img class="w-28 h-28" mat-card-sm-image alt="Image Not Found" [src]="initialData?.primaryColorway"
                    (error)="commonService.imageErrorHandle($event)">
            </div>
            <div class="flex flex-col items-center font-bold text-gray rounded border border-gray-200 p-1"
                *ngIf="initialData?.secondaryColorway">
                <label>Secondary</label>
                <img class="w-28 h-28" mat-card-sm-image alt="Image Not Found" [src]="initialData?.secondaryColorway"
                    (error)="commonService.imageErrorHandle($event)">
            </div>
        </div>
        <div class="m-3 p-2 border rounded-md border-gray-200 basis-1/4" [blockLoader]="loader" @fadeInUp>
            <span [ngClass]="getGroupStyles()" class="rounded px-2 py-1 font-medium text-xs mb-4 inline-block">
                {{designStatus}}
            </span>
            <div *ngIf="initialData?.approvedAt" class="text-xs">
                <strong>
                    {{initialData?.approvedAt | date:'MMM dd yyyy,HH:MM a' }}
                </strong>
                <br />
                Approved Date
            </div>
            <div class="flex justify-between mt-4">
                <div (click)="enable=!enable;" color="warn"  mat-stroked-button
                    class="mat-focus-indicator mat-stroked-button mat-button-base mat-warn">
                    <span class="mat-button-wrapper">Reject Design</span>
                    <span class="mat-ripple mat-button-ripple"></span>
                    <span class="mat-button-focus-overlay"></span>
                </div>
                <div class="mat-focus-indicator mat-stroked-button mat-button-base text-white"
                    [ngClass]="initialData?.approved?'bg-pink':'bg-green'" (click)="approvalChange()">
                    <ng-container *ngIf="!initialData?.approved">Approve</ng-container>
                    <ng-container *ngIf="initialData?.approved">Unapprove</ng-container>
                </div>
            </div>
            <div *ngIf="enable" @fadeInUp>
                <mat-form-field appearance="outline" class="w-full mt-2 pb-0">
                    <mat-label appRemoveAriaOwns>Remark</mat-label>
                    <textarea matInput #rejectInput [value]="initialData?.rejectedReason"></textarea>
                </mat-form-field>
                <button color="warn" mat-raised-button=""
                    class="mat-focus-indicator mat-raised-button mat-button-base mat-warn -mt-5"><span
                        (click)="rejectDesign(rejectInput.value)" class="mat-button-wrapper">Reject</span><span
                        class="mat-ripple mat-button-ripple"></span><span
                        class="mat-button-focus-overlay"></span></button>
            </div>
        </div>
    </div>
</div>