import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelperService } from '../http-helper.service';
import { PandaTemplate } from '../../models/document';
import { Code } from '../../models/code';


@Injectable({
  providedIn: 'root'
})
export class PandaTemplateService {
  defaultLicenseTypes$: Code[] = null;
  pandaTemplates$: PandaTemplate[] = null;

  private endpoint: string = 'panda-templates';

  constructor(private httpHelper: HttpHelperService) { }

  resetState() {
    this.defaultLicenseTypes$ = null;
    this.pandaTemplates$ = null;
  }

  getAll(): Observable<PandaTemplate[]> {
    return this.httpHelper.request<PandaTemplate[]>(this.endpoint);
  }

  get(id: number): Observable<PandaTemplate> {
    return this.httpHelper.request<PandaTemplate>(`${this.endpoint}/${id}`);
  }

  filter(item: PandaTemplate): Observable<PandaTemplate> {
    return this.httpHelper.request<PandaTemplate>(`${this.endpoint}`, { params: item, method: 'GET' });
  }

  create(item: PandaTemplate): Observable<PandaTemplate> {
    return this.httpHelper.request<PandaTemplate>(`${this.endpoint}`, { body: item, method: 'POST' });
  }

  edit(item: PandaTemplate): Observable<PandaTemplate> {
    return this.httpHelper.request<PandaTemplate>(`${this.endpoint}/${item.id}`, { body: item, method: 'PUT' });
  }
}
