<div class="flex items-center" mat-dialog-title>
    <h2 class="text-base md:text-lg m-0 flex-auto">Design - {{initialData?.name}}</h2>
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <mat-divider></mat-divider>
    <div class="flex flex-col sm:flex-row mt-2">
        <div class="grid grid-cols-2 sm:grid-cols-3 gap-4 items-start custom-design" @fadeInUp>
            <div class="custom-box flex flex-col items-center justify-center font-bold text-gray rounded border border-gray-200 relative w-16 h-16">
                <label>Dark</label>
                <img width="60" alt="Image Not Found" [src]="initialData?.darkColorway"
                    (error)="commonService.imageErrorHandle($event)">
                <app-upload-file class="form-control" [modelId]="initialData?.id" model="school-template-designs"
                    field="darkColorway" (output)="setImage('darkColorway',$event)">
                </app-upload-file>
            </div>
            <div class="custom-box flex flex-col items-center justify-center font-bold text-gray rounded border border-gray-200 p-1">
                <label>light</label>
                <img width="60" alt="Image Not Found" [src]="initialData?.lightColorway"
                    (error)="commonService.imageErrorHandle($event)">
                <app-upload-file class="form-control" [modelId]="initialData?.id" model="school-template-designs"
                    field="lightColorway" (output)="setImage('lightColorway',$event)">
                </app-upload-file>
            </div>
            <div class="custom-box flex flex-col items-center justify-center font-bold text-gray rounded border border-gray-200 p-1">
                <label>White</label>
                <img width="60" alt="Image Not Found" [src]="initialData?.whiteColorway"
                    (error)="commonService.imageErrorHandle($event)">
                <app-upload-file class="form-control" [modelId]="initialData?.id" model="school-template-designs"
                    field="whiteColorway" (output)="setImage('whiteColorway',$event)">
                </app-upload-file>
            </div>
            <div class="custom-box flex flex-col items-center justify-center font-bold text-gray rounded border border-gray-200 p-1">
                <label>Primary</label>
                <img width="60" alt="Image Not Found" [src]="initialData?.primaryColorway"
                    (error)="commonService.imageErrorHandle($event)">
                <app-upload-file class="form-control" [modelId]="initialData?.id" model="school-template-designs"
                    field="primaryColorway" (output)="setImage('primaryColorway',$event)">
                </app-upload-file>
            </div>
            <div class="custom-box flex flex-col items-center justify-center font-bold text-gray rounded border border-gray-200 p-1">
                <label>Secondary</label>
                <img width="60" alt="Image Not Found" [src]="initialData?.secondaryColorway"
                    (error)="commonService.imageErrorHandle($event)">
                <app-upload-file class="form-control" [modelId]="initialData?.id" model="school-template-designs"
                    field="secondaryColorway" (output)="setImage('secondaryColorway',$event)">
                </app-upload-file>
            </div>
        </div>

    </div>
    <div class="flex flex-col justify-between gap-2" [blockLoader]="loader" @fadeInUp>
        <span class="chips" [ngClass]="designBackgroundChange()">{{designStatus}}</span>
        <div class="text-lg text-primary font-bold">{{initialData?.name}}</div>
        <div class="text-xs">{{initialData?.approvedAt | date:'MMM dd yyyy, HH:MM a' }}</div>
        <div class="flex items-center gap-3">

            <button mat-button #approvalEvent class="bg-primary text-white" color="primary" (click)="approve()"
                *ngIf="initialData?.approved == false">Approve</button>
            <button mat-button #approvalEvent class="bg-red-300" color="primary" (click)="approve()"
                *ngIf="initialData?.approved == true">Remove Approval</button>

            <a color="primary" class="bg-primary cursor-pointer text-white font-bold hover:bg-gray"
                (click)="enable=!enable;" mat-button>Reject</a>
        </div>
        <div *ngIf="enable" @fadeInUp class="mb-2">
            <mat-divider></mat-divider>
            <mat-form-field appearance="outline" class="w-full mt-2 pb-0 form-field">
                <mat-label appRemoveAriaOwns>Remark</mat-label>
                <textarea matInput #rejectInput [value]="initialData?.rejectedReason"></textarea>
            </mat-form-field>
            <br>
            <button color="warn" class="text-base py-1" mat-raised-button
                (click)="rejectDesign(rejectInput.value)">Reject</button>
        </div>
    </div>

</mat-dialog-content>
