import { createAction, props } from '@ngrx/store';
import { RecentSales, SalesByDay, SalesOverview, SchoolHome } from 'src/app/core/models/home';

export interface SchoolHomeState {
    homes: SchoolHome;
    salesOverview:SalesOverview;
    schoolRecentSales:RecentSales[];
    schoolSalesByDay:SalesByDay[];
}


export const setSchoolHomeState = createAction(
    '[School Home] Set School Home Success',
    props<{ payload: SchoolHomeState }>()
);
