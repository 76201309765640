<div *ngIf="referenceDocument; else noDocument" class="h-full">
  <div *ngIf="referenceDocument?.pandaDocument && referenceDocument?.pandaDocument?.sessionId && sessionLoaded"
    class="h-full w-full">
    <iframe class="h-full w-full" [src]="pandaUrl(referenceDocument.pandaDocument.sessionId)"></iframe>
  </div>
  <div *ngIf="referenceDocument?.pandaDocument && !referenceDocument.pandaDocument?.sessionId"
    class="grid h-48 place-items-center">
    <div class="grid h-full place-items-center">
      <div class="flex flex-col">
        <h1 *ngIf="!documentLoader">Load Document</h1>
        <div class="refreshButton">
          <h2 *ngIf="documentLoader">Loading...</h2>
          <button color="primary" [buttonLoader]="documentLoader" mat-flat-button
            (click)="refreshSession(referenceDocument)">
            <mat-icon *ngIf="!documentLoader">refresh</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noDocument>
  <div class="grid h-full place-items-center">
    <h1>No Document Found</h1>
  </div>
</ng-template>