import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PlayerDocument, SchoolDocument } from '../../models/school';
import { PlayerDocumentsService } from '../../services/api/player-documents.service';
import { SchoolDocumentsService } from '../../services/api/school-documents.service';
import { NotifierService } from '../../services/notifier.services';

@Injectable({
  providedIn: 'root'
})
export class DocumentsPageService {

  private documents$ = new BehaviorSubject<SchoolDocument[] | PlayerDocument[]>([]);
  private selectedDocument$ = new BehaviorSubject<SchoolDocument | PlayerDocument>(null);
  private userType: "PLAYER" | "SCHOOL";

  drawerOpen = new BehaviorSubject<boolean>(false);
  drawerOpen$ = this.drawerOpen.asObservable();

  constructor(
    private schoolDocumentService: SchoolDocumentsService,
    private playerDocumentService: PlayerDocumentsService,
    private notifier: NotifierService
  ) { }

  public setDocuments(docs: SchoolDocument[] | PlayerDocument[]): void {

    this.documents$.next(docs);
    if (docs && docs.length > 0) {
      this.selectedDocument$.next(docs[0]);
    }
  }

  public setSelected(document: SchoolDocument | PlayerDocument): void {
    return this.selectedDocument$.next(document);
  }

  public getDocuments(): Observable<SchoolDocument[] | PlayerDocument[]> {
    return this.documents$;
  }

  public getSelectedDocument(): Observable<SchoolDocument | PlayerDocument> {
    return this.selectedDocument$;
  }

  public updateDocument(document: SchoolDocument | PlayerDocument): void {
    let docs = this.documents$.value;
    const idx = docs.findIndex(d => d.pandaTemplate.id === document.pandaTemplate.id);
    if (idx > -1) {
      docs[idx] = document;
      this.documents$.next(docs);
    }
  }

  public setUserType(userType: "PLAYER" | "SCHOOL"): void {
    this.userType = userType;
  }

  public createDocument(document: SchoolDocument | PlayerDocument): void {
    if (this.userType === "SCHOOL") {
      this.schoolDocumentService.create(document).subscribe(savedDoc => {
        this.setSelected(savedDoc);
        this.updateDocument(savedDoc);
        this.notifier.alert({ message: 'Document has been created, please check your email!', type: 'success' });
      })
    } else if (this.userType === "PLAYER") {
      this.playerDocumentService.create(document.pandaTemplate.id, document?.pandaDocument?.additionalSigners).subscribe(savedDoc => {
        this.setSelected(savedDoc);
        this.updateDocument(savedDoc);
        this.notifier.alert({ message: 'Document has been created, please check your email!', type: 'success' });
      })
    }
  }
}
