import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdditionalSigner, PandaTemplate } from 'src/app/core/models/document';
import { DocumentAdditionalSigner } from '../../../models/document';

@Component({
  selector: 'app-document-signers-modal',
  templateUrl: './document-signers-modal.component.html',
  styleUrls: ['./document-signers-modal.component.scss']
})
export class DocumentSignersModalComponent implements OnInit {

  public form: UntypedFormGroup = this.fb.group({
    signers: this.fb.array([])
  });


  constructor(
    @Inject(MAT_DIALOG_DATA) public template: PandaTemplate,
    private dialogRef: MatDialogRef<DocumentSignersModalComponent>,
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.template.additionalSigners.forEach(signer => {
      this.addSigner(signer);
    })
  }

  get signers() {
    return this.form.controls["signers"] as FormArray;
  }

  addSigner(signer: AdditionalSigner) {
    const signerForm = this.fb.group({
      role: [signer, Validators.required],
      email: ["", Validators.email],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      address: ["", Validators.required]
    });

    this.signers.push(signerForm);
  }

  submit(): void {
    const data: DocumentAdditionalSigner[] = [];
    this.signers.value.forEach(signer => {

      data.push({role: signer.role, email: signer.email, firstName: signer.firstName, lastName: signer.lastName, address: signer.address})
    });
    this.dialogRef.close(data);
  }
}
