import { Component, Inject, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from '../../services/api/common.service';

export type ImagePreview = {
  title: string;
  description: string;
  image: string;
}

@Component({
  selector: 'app-image-preview-modal',
  templateUrl: './image-preview-modal.component.html',
  styleUrls: ['./image-preview-modal.component.scss']
})
export class ImagePreviewModalComponent {
  commonService = inject(CommonService);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ImagePreview
  ) {}

}
