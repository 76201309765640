<div class="mx-auto">
  <div class=" m-2 text-xs">
    <div class="grid grid-cols-2 justify-evenly m-1">
      <div >
        <div class="flex flex-row">
          <mat-card class="flex-0 m-2" *ngFor="let side of sides">
            <mat-card-header>
              <mat-card-title>{{side.side}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <img [src]="getPrintFileUrl('w', side.versionCode)" class="flex-0 object-fit"
                (error)="commonService.imageErrorHandle($event)" crossOrigin="anonymous">
            </mat-card-content>
          </mat-card>
        </div>
        <p class="text-2xl text-black font-semibold mb-2">Design {{design.id}} White Garment Colorway</p>
        <p class="text-2xl text-black font-semibold">COLOR CALL OUTS</p>
        <ul>
          <li class="my-1 flex flex-row gap-2 text-xl text-black items-center justify-start">
            <div [ngStyle]="{'background-color': school.primaryHex}" class="w-10 h-10 rounded-full"></div>
            <div>School Color 1: PMS {{school.primaryInk}}</div>
          </li>
          <li class="my-1 flex flex-row gap-2 text-xl text-black items-center justify-start">
            <div [ngStyle]="{'background-color': school.secondaryHex}" class="w-10 h-10 rounded-full"></div>
            <div>School Color 2: PMS {{school.secondaryInk}}</div>
          </li>
        </ul>
      </div>
      
      <div *ngIf="category">
        <div class="m-1 p-3 rounded shadow-lg" >
          <img [src]="getCachelessUrl(category.productImage)" crossOrigin="anonymous" />
        </div>
        <div class="m-1 p-3">
          <p class="text-xl text-black font-semibold">{{category.licenseCode}} {{category.description}}</p>
          <p class="text-xl text-black font-semibold">APPLICATION: Printed</p>
          <p class="text-xl text-black font-semibold" *ngFor="let side of sides">LOCATION: {{side.side}} {{SIZE_MAP[side.side]}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col justify-between m-2" *ngFor="let side of sides">
    <h2>Colorways</h2>
    <div class="flex flex-row justify-between m-2">
      <div class="grow">
        <div class="grow m-1 p-3 rounded shadow-md" [ngStyle]="{'background-color': school.primaryHex}">
          <img [src]="getPrintFileUrl('p', side.versionCode)" crossOrigin="anonymous">
        </div>
        <p class="text-center text-black">PRIMARY Colorway</p>
      </div>
      <div class="grow">
        <div class="grow m-1 p-3 rounded shadow-md" [ngStyle]="{'background-color': school.secondaryHex}">
          <img [src]="getPrintFileUrl('s', side.versionCode)" crossOrigin="anonymous">
        </div>
        <p class="text-center text-black">SECONDARY Colorway</p>
      </div>
      <div class="grow">
        <div class="m-1 p-3 rounded shadow-md bg-gray-300">
          <img [src]="getPrintFileUrl('l', side.versionCode)" crossOrigin="anonymous">
        </div>
        <p class="text-center text-black">LIGHT Colorway</p>
      </div>
      <div class="grow">
        <div class="grow m-1 p-3 rounded shadow-md bg-black" >
          <img [src]="getPrintFileUrl('d', side.versionCode)" crossOrigin="anonymous">
        </div>
        <p class="text-center text-black">DARK Colorway</p>
      </div>
      
    </div>
  </div>
</div>