import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Notification, NotificationPaginate } from '../../models/notifications';
import { HttpHelperService } from '../http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private endpoint: string = 'notifications';

  constructor(private httpHelper: HttpHelperService) { }
    
  getAll(filter?:any): Observable<Notification[]> {
    return this.httpHelper.request<Notification[]>(this.endpoint,{search:filter});
  }

  getAllByFilter(filter?:any): Observable<NotificationPaginate> {
    return this.httpHelper.request<NotificationPaginate>(this.endpoint,{search:filter});
  }

  get(id: number): Observable<Notification> {
    return this.httpHelper.request<Notification>(`${this.endpoint}/${id}`);
  }

  filter(item): Observable<Notification> {
    const params = new HttpParams().set('color', item);
    return this.httpHelper.request<Notification>(`${this.endpoint}`, { search: params, method: 'GET' });
  }

  create(item: Notification): Observable<Notification> {
    return this.httpHelper.request<Notification>(`${this.endpoint}`, { body: item, method: 'POST' });
  }

  edit(item: Notification): Observable<Notification> {
    return this.httpHelper.request<Notification>(`${this.endpoint}/${item.id}`, { body: item, method: 'PUT' });
  }
  updateSeenNotification(list : any) : Observable<Notification> {
    return this.httpHelper.request<Notification>(`${this.endpoint}`+'/seen/', { body: list, method: 'POST' });
  } 
}
