import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Settings } from 'luxon';
import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { ConfigService } from 'src/@vex/config/config.service';
import { VexConfigName } from 'src/@vex/config/config-name.model';
import { ColorSchemeName } from 'src/@vex/config/colorSchemeName';
import { ColorVariable, colorVariables } from 'src/@vex/components/config-panel/color-variables';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonService } from './core/services/api/common.service';
import { Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public env: any = environment;
  userData: any;
  constructor(
    private router: Router,
    private configService: ConfigService,
    public commonService: CommonService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private route: ActivatedRoute,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
  ) {
    this.loadSocialV();
    this.loadPayPal();
    Settings.defaultLocale = this.localeId;
    this.commonService.isLoaderActive = true;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
        }
      }
    );

    this.configService.updateConfig({
      style: {
        colors: {
          primary: {
            default: '24, 90, 124',
            contrast: '255, 255, 255'
          }
        }
      },
      sidenav: {
        title: 'Athletic Solutions',
        imageUrl: 'assets/img/icon.png',
        showCollapsePin: true,
        search: {
          visible: false
        }
      },
      footer: {
        visible: false
      }
    });
    this.route.queryParamMap.subscribe(queryParamMap => {
      if (queryParamMap.has('layout')) {
        this.configService.setConfig(queryParamMap.get('layout') as VexConfigName);
      }

      if (queryParamMap.has('style')) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get('style') as ColorSchemeName
          }
        });
      }

      if (queryParamMap.has('primaryColor')) {
        const color: ColorVariable = colorVariables[queryParamMap.get('primaryColor')];

        if (color) {
          this.configService.updateConfig({
            style: {
              colors: {
                primary: color
              }
            }
          });
        }
      }

      if (queryParamMap.has('rtl')) {
        this.configService.updateConfig({
          direction: coerceBooleanProperty(queryParamMap.get('rtl')) ? 'rtl' : 'ltr'
        });
      }
    });
  }

  ngOnInit() {

    this.commonService.isLoaderActive = true;
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(() => {
        this.commonService.isLoaderActive = false
      });
    map((e) => e instanceof NavigationEnd);
  }

  loadSocialV(): void {
    let node = document.createElement('script'); // creates the script tag
    node.src = this.env.socialModule; // sets the source (insert url in between quotes)
    node.type = 'module'; // set the script type
    // append to head of document
    document.getElementsByTagName('head')[0].appendChild(node);

    let nodeNoMod = document.createElement('script'); // creates the script tag
    nodeNoMod.src = this.env.socialNoModule; // sets the source (insert url in between quotes)
    nodeNoMod.noModule = true; // set the script type
    // append to head of document
    document.getElementsByTagName('head')[0].appendChild(nodeNoMod);
  }

  loadPayPal(): void {
    let paypal = document.createElement('script'); // creates the script tag
    paypal.src = this.env.paypalAAC; // sets the source (insert url in between quotes)
    // append to head of document
    document.getElementsByTagName('head')[0].appendChild(paypal);
  }
}
