import { Component, OnInit, Output, EventEmitter, inject } from '@angular/core';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { Blank, Model } from 'src/app/core/models/blank';
import { CommonService } from 'src/app/core/services/api/common.service';
import { ModelStateService } from '../../services/state/model-state.service';
import { BlankSelectionService } from '../../services/state/blank-selection.service';


@Component({
  selector: 'common-products-view',
  templateUrl: './products-view.component.html',
  styleUrls: ['./products-view.component.scss'],
  animations: [stagger40ms]
})
export class ProductsViewComponent implements OnInit {
  @Output() productSelected = new EventEmitter<Blank>();
  modelStateService = inject(ModelStateService);
  blankSelectionService = inject(BlankSelectionService);

  loader: boolean = false
  model: Model = {};
  model$ = this.blankSelectionService.selectedModel$;
  blanks$ = this.blankSelectionService.selectedBlanks$;
  loading$ = this.blankSelectionService.blanksLoading$;
  blankLoader: number = null;

  constructor(
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
  }


  selectedProductFun(blank: Blank): void {
    this.productSelected.emit(blank);
  }

  deleteProduct(blank: Blank): void {

  }

}
