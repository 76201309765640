
<div class="overflow-auto">
  <div class="bg-app-bar whole-change-bg bg-white-td px-6 h-16 border-b sticky left-0 flex items-center"
    [buttonLoader]="loader">
    <h2
      class="title black tableTitle my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
      <span>Transactions</span>
    </h2>    
    <span class="flex-1"></span>
    <button mat-icon-button type="button" [matMenuTriggerFor]="menu">
      <mat-icon class="text-secondary">cloud_download</mat-icon>
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
      <button mat-menu-item (click)="exportCsv()" [disabled]="this.dataSource?.data?.length===0">Export
        CSV</button>
    </mat-menu>
  </div>

  <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>

    <ng-container matColumnDef="image">
      <th *matHeaderCellDef mat-header-cell></th>
      <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>
        <img [src]="commonService.encodeImagePath(row['image'], 36, 36)" (error)="commonService.imageErrorHandle($event)"
          class="avatar h-8 w-8 align-middle">
      </td>
    </ng-container>

    <ng-container *ngFor="let column of columns">
      <ng-container *ngIf="column.type === 'badge'" [matColumnDef]="column.property">
        <th *matHeaderCellDef mat-header-cell mat-sort-header> {{ column.label }}</th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
          <app-badge [text]="row[column.property]" [type]="getStatusColor(row[column.property])" ></app-badge>
        </td>
      </ng-container>

      <ng-container *ngIf="column.type === 'currency'" [matColumnDef]="column.property">
        <th *matHeaderCellDef mat-header-cell mat-sort-header> {{ column.label }}</th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] | currency }}</td>
      </ng-container>

      <ng-container *ngIf="column.type === 'date'" [matColumnDef]="column.property">
        <th *matHeaderCellDef mat-header-cell mat-sort-header> {{ column.label }}</th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
          {{ row[column.property]? (row[column.property] | date:'MMM d, yyyy') : '-' }}</td>
      </ng-container>

      <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
        <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] ?? '-'}}</td>
      </ng-container>
    </ng-container>

    

    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef mat-header-cell></th>
      <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
        <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{ code: row }"
          [matMenuTriggerFor]="actionsMenu" mat-icon-button type="button">
          <mat-icon>more_horiz</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: visibleColumns;" @fadeInUp
      class="hover:bg-hover trans-ease-out cursor-pointer" mat-row></tr>
  </table>

  <mat-paginator #paginatorRef [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" (page)="pageChanged($event)"
    class="sticky left-0"></mat-paginator>
</div>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-code="code" matMenuContent>
    <!-- <button mat-menu-item (click)="updateCode(code)">
      <mat-icon>delete</mat-icon>
      <span>Delete</span>
    </button> -->
  </ng-template> 
</mat-menu>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
</mat-menu>
