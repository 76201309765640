import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdminSchoolsPaginatedData, AdminUserPaginatedData } from '../../models/user';
import { HttpHelperService } from '../http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class AdminPaginationService {
  adminSchools$ : AdminSchoolsPaginatedData=null;
  adminUsers$  : AdminUserPaginatedData=null;
  constructor(private httpHelper: HttpHelperService) { }

  resetState(){
    this.adminSchools$= this.adminUsers$ =null;
  }

  adminUsersPagination(currentPage, pageSize,search): Observable<AdminUserPaginatedData> {
    let adminUsersPaginationParams = new HttpParams({
      fromObject: {
        'page': currentPage + 1,
        'page_size': pageSize,
        search : search
      }
    })
    return this.httpHelper.request<AdminUserPaginatedData>(`users`, { method: 'GET', search: adminUsersPaginationParams });
  }

  adminSchoolsPagination(currentPage, pageSize, search): Observable<AdminSchoolsPaginatedData> {
    let adminSchoolsPaginationParams = new HttpParams({
      fromObject: {
        page: currentPage + 1,
        page_size: pageSize,
        search: search
      }
    })
    return this.httpHelper.request<AdminSchoolsPaginatedData>(`schools`, { method: 'GET', search: adminSchoolsPaginationParams });
  }
}
