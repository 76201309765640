import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, of, startWith, take } from 'rxjs';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { stagger80ms } from 'src/@vex/animations/stagger.animation';
import { Player, PlayerSignup, School, SchoolSport, playerYears } from 'src/app/core/models/school';
import { User } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/services/api/auth.service';
import { SchoolService } from 'src/app/core/services/api/school.service';
import { UserService } from 'src/app/core/services/api/user.service';
import { ValuesMatchValidator } from 'src/app/core/validators/values-match.validator';

@Component({
  selector: 'app-player-signup',
  templateUrl: './player-signup.component.html',
  styleUrls: ['./player-signup.component.scss'],
  animations: [
    stagger80ms,
    fadeInUp400ms,
    scaleIn400ms,
    fadeInRight400ms
  ]
})
export class PlayerSignupComponent {
  public schoolSearchControl = new FormControl('');
  public sportSearchControl = new FormControl('');
  public schools: School[] = [];
  public signupForm!: FormGroup;
  public requestAccessForm!: FormGroup;
  public isPageLoading: boolean = false;
  public afterSubmit: boolean = false;
  public success: boolean;
  public message: string;
  public isLoading = false;
  public loadingSchools = false;
  private schoolIdParam: number;
  public selectedSchool: School;
  public selectedTeam: SchoolSport;
  public passwordInputType = 'password';
  public visible = false;
  public badVerificationResponse = false;
  public errorMessages = null;
  public playerYears = playerYears;
  public referralCode: string;

  public schoolFilter = this.schoolSearchControl.valueChanges.pipe(startWith(''), map(value => this._filter(value || '').slice(0, 20)),);
  public sportFilter = this.sportSearchControl.valueChanges.pipe(startWith(''), map(value => this._filterSports(value || '').slice(0, 20)),);

  teamInfoFormGroup: FormGroup = this.fb.group({
    pYear: [null],
    pPosition: [null],
    pNumber: [null, Validators.maxLength(3)],
    hometown: [null],
    highSchool: [null],
  });
  socialFormGroup: FormGroup = this.fb.group({
    twitterHandle: [null],
    instagramHandle: [null],
    tiktokHandle: [null],
  });
  basicInfoFormGroup: FormGroup = this.fb.group({
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    email: [null, [Validators.email, Validators.required]],
    isInternational: [false],
    referralCode: [null],
  });
  
  passwordFormGroup: FormGroup = this.fb.group(
    {
      password: [null, {
        validators: [
          Validators.required,
          Validators.minLength(8)
        ],
        updateOn: 'blur'
      }
      ],
      passwordConfirm: [null, Validators.required]
    }, {
      validators: ValuesMatchValidator("password", "passwordConfirm")
    }
  );

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private schoolService: SchoolService,
    private userService: UserService
  ) { 
    const token = localStorage.getItem('token');
    if (token) {
      this.userService.navigateHome();
    }
  }

  ngOnInit(): void {
    
    this.sportSearchControl.disable();
    this.signupForm = this.fb.group({
      schoolId: [null, Validators.required],
      sportId: [null, Validators.required]
      // email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    });
    this.schoolIdParam = +this.route.snapshot.queryParamMap.get('school');
    this.referralCode = this.route.snapshot.queryParamMap.get('code');
    if (this.referralCode) {
      this.basicInfoFormGroup.patchValue({ referralCode: this.referralCode });
    }
    this.loadingSchools = true;
    this.authService.getSignupSchools().pipe(take(1)).subscribe(schools => {
      this.schools = schools;
      if (this.schoolIdParam && schools.find(s => s.id === this.schoolIdParam)) {
        this.selectedSchool= this.schools.find(s => s.id === this.schoolIdParam);
        this.schoolSearchControl.setValue(schools.find(s => s.id === this.schoolIdParam).fullName);
        this.selectedTeam = null;
        this.sportSearchControl.enable();
        this.signupForm.patchValue({ 
          schoolId: this.schoolIdParam,
          sportId: null
        });
      }
      this.loadingSchools = false;
    });
  }

  private _filter(value: string): School[] {
    if (!value) {
      return this.schools;
    }
    let schools = this.schools.filter(data => data.fullName.toLowerCase().includes(value.toLowerCase()));
    if (schools.length === 0) {
      schools.push({ fullName: "No Results" });
    }
    return schools;
  }

  private _filterSports(value: string): SchoolSport[] {
    if (!this.selectedSchool) {
      return [];
    }
    let sports = this.selectedSchool.teams.filter(data => data.sport.name.toLowerCase().includes(value.toLowerCase()));
    if (sports.length === 0) {
      sports.push({ nickname: "No Results" });
    }
    return sports.sort((a, b) => a.sport.name.localeCompare(b.sport.name));
  }

  onSubmit(): void {
    if (this.signupForm.valid) {
      const schoolId = this.signupForm.value.schoolId;
      const email = this.signupForm.value.email;
      this.isLoading = true;
      this.authService.userRequestValidaiton(schoolId, email).pipe(take(1)).subscribe(response => {
        this.success = response.success;
        this.message = response.message;
        this.isLoading = false;
        this.afterSubmit = true;
      })
    }
  }

  schoolSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectedSchool = this.schools.find(s => s.fullName === event.option.value);
    this.selectedTeam = null;
    this.sportSearchControl.enable();
    this.signupForm.patchValue({ 
      schoolId: this.schools.find(s => s.fullName === event.option.value).id,
      sportId: null
    });
  }

  sportSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectedTeam = event.option.value;
    this.signupForm.patchValue({ sportId: event.option.value.id });
  }

  reset(): void {
    this.afterSubmit = false;
    this.signupForm.patchValue({
      email: ""
    });
  }

  gotoLogin(): void {
    this.router.navigate(['/login']);
  }

  sportDisplay(team: SchoolSport): string {
    if (!team) {
      return null;
    } 
    return `${team.sport.name}${(team.gender === 'U' ? '' : '-' + team.gender )}`;
  }

  toggleVisibility() {
    if (this.visible) {
      this.passwordInputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.passwordInputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }

  createAccount(): void {
    if (
      this.teamInfoFormGroup.valid && 
      this.passwordFormGroup.valid && 
      this.signupForm.valid && 
      this.basicInfoFormGroup.valid && 
      this.socialFormGroup.valid
    ) {
      const user: User = {
        ...this.basicInfoFormGroup.value,
        ...this.socialFormGroup.value,
        password: this.passwordFormGroup.value.password,
      }
      const player: PlayerSignup = {
        schoolId: this.signupForm.value.schoolId,
        sportId: this.signupForm.value.sportId,
        ...this.basicInfoFormGroup.value,
        ...this.teamInfoFormGroup.value
      }
      this.isLoading = true;
      this.authService.playerSignup(player, user, this.selectedSchool.id).pipe(
        catchError(() => of(null))
      ).subscribe(token => {
        if (!token?.error) {
          localStorage.setItem('token', token.token);
          this.userService.getUser().pipe(take(1)).subscribe(user => {
            this.userService.navigateHome();
            this.isLoading = false;
          })
        } else {
          this.errorMessages = token.message;
          this.badVerificationResponse = true;
          this.isLoading = false;
        }
      });
    }
  }
}
