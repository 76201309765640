import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../core/core.module';
import { MainComponent } from './main.component';
import { RouterModule, Routes } from '@angular/router';
import { UnderConstructionComponent } from '../under-construction/under-construction.component';
import { SidenavModule } from 'src/@vex/layout/sidenav/sidenav.module';
import { LayoutModule } from 'src/@vex/layout/layout.module';
import { ToolbarModule } from 'src/@vex/layout/toolbar/toolbar.module';
import { FooterModule } from 'src/@vex/layout/footer/footer.module';
import { ConfigPanelModule } from 'src/@vex/components/config-panel/config-panel.module';
import { SidebarModule } from 'src/@vex/components/sidebar/sidebar.module';
import { QuickpanelModule } from 'src/@vex/layout/quickpanel/quickpanel.module';
import { CodeService } from 'src/app/core/services/api/code.service';
import { SchoolGuard } from 'src/app/core/guards/school.guard';
import { PageNotFoundComponent } from 'src/app/core/components/page-not-found/page-not-found.component';
import { USER_ROUTES } from '../user/user.module';
import { AdminGuard } from 'src/app/core/guards/admin.guard';
import { PlayerGuard } from 'src/app/core/guards/player.guard';
import { OrgGuard } from 'src/app/core/guards/org.guard';
import { HelpCenterModule } from 'src/@vex/components/help-center/help-center.module';
import { HELP_CENTER_ROUTES } from '../../../@vex/components/help-center/help-center-routing.module'
import { UserGuard } from 'src/app/core/guards/user.guard';
import { ManufacturerGuard } from 'src/app/core/guards/manufacturer.guard';

export const MAIN_ROUTES: Routes = [

  {
    data: { label: 'Products' },
    path: 'player/products',
    component: UnderConstructionComponent,
  },

  {
    path: 'school',
    loadChildren: () => import('../school/school.module').then(m => m.SchoolModule),
    canActivate: [SchoolGuard],
    canActivateChild: [SchoolGuard],
  },
  {
    path: 'player',
    loadChildren: () => import('../player/player.module').then(m => m.PlayerModule),
    canActivate: [PlayerGuard],
    canActivateChild: [PlayerGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('../admin/admin.module').then(m => m.AdminModule),
    canActivate: [AdminGuard],
    canActivateChild: [AdminGuard],
  },
  {
    path: 'org',
    loadChildren: () => import('../organization/organization.module').then(m => m.OrganizationModule),
    canActivate: [OrgGuard],
    canActivateChild: [OrgGuard],
  },
  {
    path: 'workstations',
    loadChildren: () => import('../workstations/workstations.module').then(m => m.WorkstationsModule),
    canActivate: [AdminGuard],
    canActivateChild: [AdminGuard],
  },
  {
    path: 'manufacturer',
    loadChildren: () => import('../manufacturer/manufacturer.module').then(m => m.ManufacturerModule),
    canActivate: [ManufacturerGuard],
    canActivateChild: [ManufacturerGuard],
  },
  {
    path: 'user',
    children: USER_ROUTES,
    canActivate: [UserGuard],
    canActivateChild: [UserGuard],
  },
  {
    path: 'help-center',
    children: HELP_CENTER_ROUTES
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  }

];


@NgModule({
  declarations: [
    MainComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    LayoutModule,
    SidenavModule,
    ToolbarModule,
    FooterModule,
    ConfigPanelModule,
    SidebarModule,
    QuickpanelModule,
    HelpCenterModule
  ],
  providers: [
    CodeService
  ],
})
export class MainModule { }
