import { Component, OnInit, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { catchError, of } from 'rxjs';
import { PaypalAuth, PaypalData } from 'src/app/core/models/user';
import { environment } from 'src/environments/environment';
import { UserService } from '../../../../core/services/api/user.service';
import { UsersService } from '../../../../core/services/api/users.service';

declare var paypal: any;

@Component({
  selector: 'app-paypal-payouts-aac',
  templateUrl: './paypal-payouts-aac.component.html',
  styleUrls: ['./paypal-payouts-aac.component.scss']
})
export class PaypalPayoutsAacComponent implements OnInit, AfterViewInit {

  @Output() payoutConfirmered = new EventEmitter<PaypalData>();
  @Input() paypalEmail: string;

  paypalClientId = environment.paypalClientId;
  addScript: boolean = false;
  scriptTagElement: HTMLScriptElement;
  loading = false;
  constructor(
    private usersService: UsersService
  ) { }

  ngAfterViewInit(): void {
    paypal.PayoutsAAC.render({
      // Use sandbox for testing
      env: (environment.production) ? 'production' : 'sandbox',
      clientId: {
        production: environment.paypalClientId,
        sandbox: environment.paypalClientId
      },
      merchantId: environment.paypalMerchantId,
      pageType: "login",
      onLogin: this.getPaypalData.bind(this)
    }, '#paypal-aac');
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this.scriptTagElement) {
      document.body.removeChild(this.scriptTagElement);
    }
  }

  getPaypalData(response: any): void {
    if (response.err) {
      console.log(response.err)
    } else {
      const paypalAuth: PaypalAuth = {
        code: response.body.code,
        clientData: response.body.clientData
      }
      this.loading = true;
      this.usersService.paypalAuth(paypalAuth).pipe(
        catchError(() => {
          this.loading = false;
          return of(null)
        })
      ).subscribe(paypalData => {
        if (paypalData) {
          this.payoutConfirmered.emit(paypalData);
          this.paypalEmail = paypalData.email;
        }
        this.loading = false;
      })
    }
  }

  resetAccount(): void {
    this.paypalEmail = null;
    this.payoutConfirmered.emit({email: null})
  }

}
