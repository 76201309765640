import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, inject } from '@angular/core';
import { PlayerDocument, SchoolDocument } from '../../models/school';
import { PandaDocumentsService } from '../../services/api/panda-documents.service';
import { take } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PandaDocument } from '../../models/document';

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentViewerComponent {
  @Input() referenceDocument: SchoolDocument | PlayerDocument;

  pandaDocumentsService = inject(PandaDocumentsService);
  cd = inject(ChangeDetectorRef);
  sanitizer = inject(DomSanitizer);


  documentLoader = false;
  sessionLoaded = false;

  document: PandaDocument;

  public pandaUrl(sessionId: string): SafeResourceUrl {
    const url = `https://app.pandadoc.com/s/${sessionId}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  

  refreshSession(document: SchoolDocument | PlayerDocument): void {
    this.documentLoader=true;
    if (document?.pandaDocument.id) {
      this.pandaDocumentsService.refreshSession(document?.pandaDocument.id).pipe(
        take(1)
      ).subscribe(doc => {
        this.documentLoader=false;
        if (doc.sessionId) {
          this.sessionLoaded = true;
          document.pandaDocument.sessionId = doc.sessionId;
          // this.documentsPagesService.updateDocument(document);
          // this.documentsPagesService.setSelected(document);
          this.cd.markForCheck();
        }
      })
    }
  }

}
