import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-button',
  template: `  
  <div class="spinner">
  </div>
`
})
export class LoadingButtonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
