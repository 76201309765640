import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LicenseSlice } from '../../models/license';
import { HttpHelperService } from '../http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class LicenseSliceService {
  licenseSliceList$ : LicenseSlice[]=null;
  private endpoint: string = 'license-slice';

  constructor(private httpHelper: HttpHelperService) { }
  resetState(){
    this.licenseSliceList$ = null;
  }
  
  getAll(filter?:any): Observable<LicenseSlice[]> {
    return this.httpHelper.request<LicenseSlice[]>(this.endpoint,{method : 'GET',search : filter});
  }

  get(id: number): Observable<LicenseSlice> {
    return this.httpHelper.request<LicenseSlice>(`${this.endpoint}/${id}`);
  }

  create(item: LicenseSlice): Observable<LicenseSlice> {
    return this.httpHelper.request<LicenseSlice>(`${this.endpoint}`, {body: item, method: 'POST'});
  }

  edit(item: LicenseSlice): Observable<LicenseSlice> {
    return this.httpHelper.request<LicenseSlice>(`${this.endpoint}/${item.id}`, {body: item, method: 'PUT'});
  }
  delete(item : LicenseSlice) : Observable<LicenseSlice>{
    return this.httpHelper.request<LicenseSlice>(`${this.endpoint}/${item.id}`, { method: 'DELETE'});
  }
}
