<div class="w-full h-full overflow-hidden flex flex-col">
  <div class="bg-app-bar p-4 border-b flex-none flex items-center gap-4 h-20">
    <button (click)="openDrawer()" class="block md:hidden" color="primary" mat-icon-button type="button">
      <mat-icon>menu</mat-icon>
    </button>

    <div class="relative">
    </div>

    <div class="flex-auto">
    </div>

  </div>
  <div *ngIf="document$ | async as currentDocument; else noDocument" class="h-full">
    <div *ngIf="currentDocument?.pandaDocument && currentDocument?.pandaDocument?.sessionId && sessionLoaded"
      class="h-full w-full">
      <iframe class="h-full w-full" [src]="pandaUrl(currentDocument.pandaDocument.sessionId)"></iframe>
    </div>
    <div *ngIf="!currentDocument?.pandaDocument && currentDocument?.pandaTemplate"
      class="grid h-screen place-items-center">
      <div class="grid h-full place-items-center">
        <div class="flex flex-col">
          <h1>Please create contract to view/sign document</h1>
          <button color="primary" mat-flat-button (click)="createDocument(currentDocument)">Create Contract</button>
        </div>
      </div>
    </div>
    <div *ngIf="currentDocument?.pandaDocument && !currentDocument.pandaDocument?.sessionId"
      class="grid h-screen place-items-center">
      <div class="grid h-full place-items-center">
        <div class="flex flex-col">
          <h1 *ngIf="!documentLoader">Load Document</h1>
          <div class="refreshButton">
            <h2 *ngIf="documentLoader">Loading...</h2>
            <button color="primary" [buttonLoader]="documentLoader" mat-flat-button
              (click)="refreshSession(currentDocument)">
              <mat-icon *ngIf="!documentLoader">refresh</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<mat-menu #optionsMenu="matMenu" xPosition="after" yPosition="below">
  <button mat-menu-item (click)="sendDocument(currentDocument)"
    *ngIf="document$ | async as currentDocument; else noDocument">
    <mat-icon>mail</mat-icon>
    <span>Send To Email</span>
  </button>
</mat-menu>

<ng-template #noDocument>
  <div class="grid h-full place-items-center">
    <h1>Please Select Document</h1>
  </div>
</ng-template>