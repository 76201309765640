import { Injectable } from '@angular/core';
import { Model } from '../../models/blank';
import { BehaviorSubject, forkJoin, map, combineLatest, filter, Observable } from 'rxjs';
import { ModelService } from '../api/model.service';
import { FilterList, FilterListTree } from '../../models/utils';
import { SchoolService } from '../api/school.service';

@Injectable({
  providedIn: 'root'
})
export class ModelStateService {

  private readonly _modelsSource = new BehaviorSubject<Model[]>(null);
  public readonly models$ = this._modelsSource.asObservable();

  filterBy$ = new BehaviorSubject<'department' | 'manufacturer'>('department');

  public readonly filteredItems$ = combineLatest({
    models: this.models$,
    filterBy: this.filterBy$
  }).pipe(
    filter(data => {
      return data.models !== null
    }),
    map(data => {
      switch (data.filterBy) {
        case 'department': {
          return this.departmentMap(data.models);
        }
        case 'manufacturer': {
          return this.manufacturerMap(data.models);
        }
        default: {
          return this.departmentMap(data.models);

        }
      }
    })
  )



  constructor(
    private modelService: ModelService,
    private schoolsService: SchoolService
  ) { }

  public init(): void {
    if (!this._modelsSource.getValue()) {
      this.modelService.getAll().subscribe(models => {
        this._modelsSource.next(models);
      })
    }
  }

  public schoolInit(schoolId: number): void {
    if (!this._modelsSource.getValue()) {
      this.schoolsService.models(schoolId).subscribe(models => {
        this._modelsSource.next(models);
      })
    }
  }

  getModel(model: string): Observable<Model> {
    return this.models$.pipe(
      filter(Boolean),
      map(models => models.find(m => m.model === model))
    );
  }

  getModels(): Model[] {
    return this._modelsSource.getValue();
  }

  setModels(models: Model[]): void {
    this._modelsSource.next(models);
  }

  updateModel(model: Model): void {
    const models = this._modelsSource.getValue();
    const idx = models.findIndex(m => m.model === model.model);
    if (idx > -1) {
      models[idx] = model;
    } else {
      models.push(model);
    }
    this._modelsSource.next(models);
  }

  private departmentMap(models: Model[]): FilterListTree<Model>[] {
    const deptMap: Map<string, Model[]> = new Map<string, Model[]>();
    const items: FilterListTree<Model>[] = [];
    models = models.sort((a, b) => a.description.localeCompare(b.description));
    models.forEach(m => {
      if (!deptMap.has(m.department)) {
        deptMap.set(m.department, []);
      }
      deptMap.get(m.department).push(m);
    })

    deptMap.forEach((v, k) => {
      items.push({
        type: 'subheading',
        label: k,
        items: v.map(m => {
          return {
            type: 'link',
            id: m.model,
            object: m,
            label: m.description,
            classes: {
              icon: 'text-primary'
            }
          }
        })
      })
    })
    return items;
  }

  private manufacturerMap(models: Model[]): FilterListTree<Model>[] {
    const manufacturerMap: Map<string, Model[]> = new Map<string, Model[]>();
    const items: FilterListTree<Model>[] = [];
    models = models.sort((a, b) => a.description.localeCompare(b.description));
    models.forEach(m => {
      if (!manufacturerMap.has(m.manufacturer?.name)) {
        manufacturerMap.set(m.manufacturer?.name, []);
      }
      manufacturerMap.get(m.manufacturer?.name).push(m);
    })
    manufacturerMap.forEach((v, k) => {
      items.push({
        type: 'subheading',
        label: k,
        items: v.map(m => {
          return {
            type: 'link',
            id: m.model,
            object: m,
            label: m.description,
            classes: {
              icon: 'text-primary'
            }
          }
        })
      })
    })
    return items;
  }


}
