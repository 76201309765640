<div class="relative h-full max-h-full isolate bg-white px-6 py-12 sm:py-16 lg:px-8 overflow-y-auto">
  <svg class="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]" aria-hidden="true">
    <defs>
      <pattern id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527" width="200" height="200" x="50%" y="-64" patternUnits="userSpaceOnUse">
        <path d="M100 200V.5M.5 .5H200" fill="none" />
      </pattern>
    </defs>
    <svg x="50%" y="-64" class="overflow-visible fill-gray-50">
      <path d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M299.5 800h201v201h-201Z" stroke-width="0" />
    </svg>
    <rect width="100%" height="100%" stroke-width="0" fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
  </svg>
  <div class="mx-auto max-w-xl lg:max-w-4xl">
    <div class="flex flex-col sm:flex-row-reverse justify-between gap-y-4">
      <img class="w-40 h-auto object-contain" src="assets/img/logo.png" alt="Athletic Solutions">
      <div>
        <h2 class="text-xl sm:text-3xl font-bold tracking-tight text-primary">Join your team's store now!</h2>
        <p class="mt-2 sm:text-lg leading-8 text-gray-600">Already signed up? <a class="text-lg sm:text-xl font-bold text-primary hover:text-primary/50" [routerLink]="['/login']">Login</a></p>
        <p class="mt-2 sm:text-lg leading-8 text-gray-600">Don't see your school? <a class="text-lg sm:text-xl font-bold text-primary hover:text-primary/50" href="https://athsolutions.shop/contact-us">Contact Us</a></p>
        <p class="mt-2 sm:text-lg leading-8 text-gray-600" *ngIf="referralCode">
          Referral Code:
          <span class="text-lg sm:text-xl font-bold text-primary hover:text-primary/50 ml-3" >{{referralCode}}</span>
        </p>
      </div>
    </div>
    
    <div class="mt-4 flex flex-col gap-16 sm:gap-y-20 lg:flex-row" [blockLoader]="isLoading">
      <div class="lg:flex-auto">
        <form [formGroup]="signupForm" (ngSubmit)="onSubmit()" *ngIf="!signupForm.valid else complete">
          <div class="grid grid-cols-1 gap-x-8 gap-y-2 sm:grid-cols-2">
            <div>
              <label for="email" class="block text-sm font-semibold leading-6 text-gray-900">Find your school</label>
              <div class="card rounded-md px-4 flex-auto flex items-center border border-gray-300 mt-2.5"
                [blockLoader]="loadingSchools">
                <mat-icon class="icon-sm text-secondary">search</mat-icon>
                <input #inpRef [formControl]="schoolSearchControl" [matAutocomplete]="auto"
                  class="appearance-none px-4 py-2 border-0 outline-none w-full bg-transparent" placeholder="Find your school" type="search">
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="schoolSelected($event)">
                  <mat-option [class.noResults]="school?.fullName === 'No Results'" [disabled]="school?.fullName === 'No Results'"
                    *ngFor="let school of schoolFilter | async" [value]="school.fullName">
                    {{school.fullName}}
                  </mat-option>
                </mat-autocomplete>
              </div>
            </div>
            <div>
              <label for="email" class="block text-sm font-semibold leading-6 text-gray-900">Find your sport</label>
              <div class="card rounded-md px-4 flex-auto flex items-center border border-gray-300 mt-2.5"
                [blockLoader]="loadingSchools">
                <mat-icon class="icon-sm text-secondary">search</mat-icon>
                <input #inpRef [formControl]="sportSearchControl" [matAutocomplete]="autoSport"
                  class="appearance-none px-4 py-2 border-0 outline-none w-full bg-transparent" placeholder="Find your team" type="search">
                <mat-autocomplete #autoSport="matAutocomplete" (optionSelected)="sportSelected($event)" [displayWith]="sportDisplay">
                  <mat-option [class.noResults]="sport?.nickname === 'No Results'" [disabled]="sport?.nickname === 'No Results'"
                    *ngFor="let sport of sportFilter | async" [value]="sport">{{sport.nickname}}
                  </mat-option>
                </mat-autocomplete>
              </div>
            </div>
          </div>
        </form>
        
        
        <mat-stepper [linear]="true" #stepper class="" *ngIf="signupForm.valid" @fadeInUp>
          <ng-template matStepperIcon="edit">
            <mat-icon>done_all</mat-icon>
          </ng-template>
  
          <ng-template matStepperIcon="done">
            <mat-icon>done_all</mat-icon>
          </ng-template>
          <mat-step [stepControl]="basicInfoFormGroup">
            <form [formGroup]="basicInfoFormGroup" class="grid grid-cols-1 gap-x-8 gap-y-2 sm:gap-y-6 sm:grid-cols-2 px-3">
              <div class="">
                <label for="first-name" class="block text-sm font-semibold leading-6 text-gray-900">First name*</label>
                <div class="mt-2.5 mr-8">
                  <input formControlName="firstName" type="text" name="first-name" id="first-name" autocomplete="first-name" 
                    class="appearance-none block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                </div>
              </div>
              <div class="">
                <label for="last-name" class="block text-sm font-semibold leading-6 text-gray-900">Last name*</label>
                <div class="mt-2.5 mr-8">
                  <input formControlName="lastName" type="text" name="last-name" id="last-name" autocomplete="family-name" 
                  class="appearance-none block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                </div>
              </div>
              <div class="sm:col-span-2">
                <label for="email" class="block text-sm font-semibold leading-6 text-gray-900">E-mail*</label>
                <div class="mt-2.5 mr-8">
                  <input formControlName="email" type="email" name="email" id="email" autocomplete="email" 
                    class="appearance-none block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                </div>
              </div>
              <div class="" *ngIf="!referralCode">
                <label for="referralCode" class="block text-sm font-semibold leading-6 text-gray-900">Referral Code</label>
                <div class="mt-2.5 mr-8">
                  <input formControlName="referralCode" name="referralCode" id="referralCode" 
                    class="appearance-none block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                </div>
              </div>
              <div class="">
                <label for="isInternational" class="block text-sm font-semibold leading-6 text-gray-900">International Student</label>
                <div class="mt-4 mr-8">
                  <div class="relative flex items-center ">
                    <input id="isInternational" formControlName="isInternational" aria-describedby="comments-description" name="isInternational" type="checkbox" class="apperance-none h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary">
                    <span id="comments-description" class="text-gray-500 ml-3"><span class="sr-only">International Student{{' '}}</span>Are you an international student on a visa?</span>
                  </div>
                </div>
              </div>
              <div class="mt-6 flex items-center justify-between gap-x-6 sm:col-span-2">
                <button type="button" (click)="stepper.reset()" [disabled]="basicInfoFormGroup.pristine" class="text-sm font-semibold leading-6 text-gray-900">Reset</button>
                <button
                  [disabled]="basicInfoFormGroup.invalid"
                  matStepperNext
                  class="rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm disabled:bg-gray-500 hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">
                  Next
                </button>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="teamInfoFormGroup">
            <form [formGroup]="teamInfoFormGroup" class="grid grid-cols-1 gap-x-8 gap-y-2 sm:gap-y-6 sm:grid-cols-2 px-3">
              <div class="sm:col-span-2">
                <label for="pYear" class="block text-sm font-semibold leading-6 text-gray-900">Year</label>
                <select formControlName="pYear" id="pYear" name="pYear" class="appearance-none mt-2.5 block w-full rounded-md border-0 py-2 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6">
                  <option *ngFor="let year of playerYears" [value]="year" [selected]="year === teamInfoFormGroup.value.pYear">{{year}}</option>
                </select>
              </div>
              <div>
                <label for="pPosition" class="block text-sm font-semibold leading-6 text-gray-900">Position</label>
                <div class="mt-2.5 mr-8">
                  <input formControlName="pPosition" type="text" name="pPosition" id="pPosition" 
                    class="appearance-none block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                </div>
              </div>
              <div>
                <label for="pNumber" class="block text-sm font-semibold leading-6 text-gray-900">Number</label>
                <div class="mt-2.5 mr-8">
                  <input formControlName="pNumber" id="pNumber" name="pNumber" type="text" maxlength="3" onlyNumbers
                    class="appearance-none block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                </div>
              </div>
              <div>
                <label for="hometown" class="block text-sm font-semibold leading-6 text-gray-900">Hometown</label>
                <div class="mt-2.5 mr-8">
                  <input formControlName="hometown" name="hometown" id="hometown" type="text" 
                    class="appearance-none block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                </div>
              </div>
              <div>
                <label for="highSchool" class="block text-sm font-semibold leading-6 text-gray-900">High School</label>
                <div class="mt-2.5 mr-8">
                  <input formControlName="highSchool" name="highSchool" id="highSchool" type="text" 
                    class="appearance-none block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                </div>
              </div>
              <div class="mt-6 flex items-center justify-between gap-x-6 sm:col-span-2">
                <button type="button" (click)="stepper.reset()" [disabled]="teamInfoFormGroup.pristine" class="text-sm font-semibold leading-6 text-gray-900">Reset</button>
                <button
                  [disabled]="teamInfoFormGroup.invalid"
                  matStepperNext
                  class="rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">
                  Next
                </button>
              </div>
              
            </form>
          </mat-step>
          <mat-step [stepControl]="socialFormGroup">
            <form [formGroup]="socialFormGroup" class="grid grid-cols-1 gap-x-8 gap-y-2 sm:gap-y-6 sm:grid-cols-2 px-3">
              <div class="col-span-full sm:col-span-2">
                <label for="instagramHandle" class="block text-sm font-semibold leading-6 text-gray-900">Instagram</label>
                <div class="mr-4 flex mt-2.5">
                  <span
                    class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm py-1.5">@</span>
                  <input id="instagramHandle" formControlName="instagramHandle" name="instagramHandle" type="text"
                    class="appearance-none block px-2 w-full rounded-none  rounded-r-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                </div>
              </div>
              <div class="col-span-full sm:col-span-2">
                <label for="twitterHandle" class="block text-sm font-semibold leading-6 text-gray-900">Twitter</label>
                <div class="mr-4 flex mt-2.5">
                  <span
                    class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm py-1.5">@</span>
                  <input id="twitterHandle"  formControlName="twitterHandle" name="twitterHandle" type="text"
                    class="appearance-none block px-2 w-full rounded-none  rounded-r-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                    
                  </div>
              </div>
              <div class="col-span-full sm:col-span-2">
                <label for="tiktokHandle" class="block text-sm font-semibold leading-6 text-gray-900">TikTok</label>
                <div class="mr-4 flex mt-2.5">
                  <span
                    class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm py-1.5">@</span>
                  <input id="tiktokHandle" formControlName="tiktokHandle" name="tiktokHandle" type="text"
                    class="appearance-none block px-2 w-full rounded-none  rounded-r-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                </div>
              </div>
              <div class="mt-6 flex items-center justify-between gap-x-6 sm:col-span-2">
                <button type="button" (click)="stepper.reset()" [disabled]="socialFormGroup.pristine" class="text-sm font-semibold leading-6 text-gray-900">Reset</button>
                <button
                  [disabled]="socialFormGroup.invalid"
                  matStepperNext
                  class="rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">
                  Next
                </button>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="passwordFormGroup">
            <form [formGroup]="passwordFormGroup" class="px-3">
              <ng-template matStepLabel><span class="hidden sm:block">Select a Password</span></ng-template>
              <div class="block sm:hidden border-b border-gray-200 py-2">
                <div class="title text-xl font-semibold">Select a Password</div>
              </div>
              <div class="mt-4 flex flex-col gap-2">
                <div>
                  <label for="password" class="block text-sm font-semibold leading-6 text-gray-900">Password*</label>
                  <div class="flex rounded-md shadow-sm mt-2.5">
                    <div class="relative flex flex-grow items-stretch focus-within:z-10">
                      <input name="password" id="password" matInput required
                        [type]="passwordInputType" formControlName="password"
                        class="appearance-none block w-full rounded-none rounded-l-md border-0 py-1.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                        placeholder="Password">
                    </div>
                    <button type="button"
                      tabindex="-1"
                      (click)="toggleVisibility()"
                      class="relative -ml-px inline-flex items-center gap-x-0.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      <mat-icon class="w-8 px-1 text-gray-400" *ngIf="passwordInputType === 'password'">visibility</mat-icon>
                      <mat-icon class="w-8 px-1 text-gray-400" *ngIf="passwordInputType === 'text'">visibility_off</mat-icon>
                    </button>
                  </div>
                  <p *ngIf="passwordFormGroup.controls.password.errors?.required" class="mt-2 text-sm text-red-600" id="email-error">This
                    Field is required</p>
                  <p *ngIf="passwordFormGroup.controls.password.errors?.minLength" class="mt-2 text-sm text-red-600" id="email-error">Password must be at least 8 characters long</p>
                </div>
                <div>
                  <label for="passwordConfirm" class="block text-sm font-semibold leading-6 text-gray-900">Confirm Password*</label>
                  <div class="flex rounded-md shadow-sm mt-2.5">
                    <div class="relative flex flex-grow items-stretch focus-within:z-10">
                      <input name="passwordConfirm" id="passwordConfirm" matInput required
                        [type]="passwordInputType" formControlName="passwordConfirm"
                        class="appearance-none block w-full rounded-none rounded-l-md border-0 py-1.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                        placeholder="Confirm Password">
                    </div>
                    <button type="button" (click)="toggleVisibility()"
                      tabindex="-1"
                      class="relative -ml-px inline-flex items-center gap-x-0.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      <mat-icon class="w-8 px-1 text-gray-400" *ngIf="passwordInputType === 'password'">visibility</mat-icon>
                      <mat-icon class="w-8 px-1 text-gray-400" *ngIf="passwordInputType === 'text'">visibility_off</mat-icon>
                    </button>
                  </div>
                  <p *ngIf="passwordFormGroup.controls.passwordConfirm.errors?.confirmedValidator" class="mt-2 text-sm text-red-600" id="email-error">Passwords do not match</p>
                </div>
              </div>

              <div class="actions flex flex-col sm:flex-row items-center justify-between gap-2 mt-6">
                <p class="mt-4 text-sm leading-6 text-gray-500">By submitting this form, I agree to the <a target="_blank" href="https://ipods.s3.us-east-2.amazonaws.com/media/legal/athletic_solutions_terms.pdf" class="font-semibold text-primary">Terms of Service</a>.</p>
                <button (click)="createAccount()"
                  type="submit" class="block rounded-md bg-primary mt-6 w-full sm:w-auto px-3.5 sm:mt-0 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary/50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">Create Account</button>
              </div>
            </form>            
          </mat-step>
        </mat-stepper>
        <div *ngIf="badVerificationResponse">
          <div class="rounded-md bg-red-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">Error</h3>
                <div class="mt-2 text-sm text-red-700">
                  <p>{{errorMessages}}</p>
                </div>
                <div class="mt-4">
                  <div class="-mx-2 -my-1.5 flex">
                    <a href="/password-reset-request" class="rounded-md bg-red-50 px-2 py-1.5 text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50">Forgot Password</a>
                    <button (click)="badVerificationResponse=false" type="button" class="ml-3 rounded-md bg-red-50 px-2 py-1.5 text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50">Dismiss</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray/20 mt-12 sm:mt-24 rounded-md">
      <div class="mx-auto max-w-7xl px-6 py-8">
        <div class="mx-auto max-w-2xl text-center">
          <h2 class="text-xl font-bold leading-10 tracking-tight text-primary">How to open your store</h2>
          <p class="mt-6 text-base leading-7 text-gray-600">Open your Athletic Solutions store in a few quick steps! Check out the FAQs that will answer any questions you might have.</p>
        </div>
        <div class="mt-8">
          <dl class="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
            <div>
              <dt class="text-base font-semibold leading-7 text-gray-900">1. Fill out form</dt>
              <dd class="mt-2 text-sm leading-7 text-gray-600">Fill out the form above to introduce yourself to us. The more information you provide the better your store will look!</dd>
            </div>
            <div>
              <dt class="text-base font-semibold leading-7 text-gray-900">2. Sign Agreement</dt>
              <dd class="mt-2 text-sm leading-7 text-gray-600">An NIL agreement will be sent to the email you provided after you submit the form. Sign that document and we will start building your store!</dd>
            </div>
            <div>
              <dt class="text-base font-semibold leading-7 text-gray-900">3. Launch Store</dt>
              <dd class="mt-2 text-sm leading-7 text-gray-600">A link to your store will be emailed 24-72 hours after your agreement is signed. In the meantime, feel free to add a some images to your profile that will be displayed in your store.</dd>
            </div>
            <div>
              <dt class="text-base font-semibold leading-7 text-gray-900">4. Payments</dt>
              <dd class="mt-2 text-sm leading-7 text-gray-600">Your royalties are added to your account as products are sold and shipped. You can receive these at any time via PayPal or Venmo.</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #complete>
  <div class="w-full px-auto">
    <img class="h-24 w-auto" [src]="selectedSchool.mainLogo" alt="Athletic Solutions">
    <h1 class="text-2xl font-semibold" [ngStyle]="{'color': selectedSchool.primaryHex}">{{selectedSchool.fullName}}</h1>
    <h1 [ngStyle]="{'color': selectedSchool.secondaryHex}">{{selectedTeam.nickname}}</h1>
  </div>
</ng-template>

<ng-template #errorMessage>
  <div class="rounded-md bg-red-50 p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
        </svg>
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-red-800">Error</h3>
        <div class="mt-2 text-sm text-red-700">
          <p>{{errorMessages}}</p>
        </div>
        <div class="mt-4">
          <div class="-mx-2 -my-1.5 flex">
            <a href="password-reset" class="rounded-md bg-red-50 px-2 py-1.5 text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50">Forgot Password</a>
            <button (click)="badVerificationResponse=false" type="button" class="ml-3 rounded-md bg-red-50 px-2 py-1.5 text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50">Dismiss</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>