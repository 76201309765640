<div mat-dialog-title>Confirm</div>

<div mat-dialog-content class="p-4 m-2">
  <p> Are you sure, you want to <span class="text-primary">{{dialogData?.content}}</span> ?</p>
  <p *ngIf="dialogData.subContent">{{dialogData?.subContent}}</p>
</div>

<div mat-dialog-actions align="end">
    <button mat-button (click)="onDismiss()">Cancel</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">Confirm</button>
</div>
