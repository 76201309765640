import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { MainComponent } from './pages/main/main.component';
import { MAIN_ROUTES } from './pages/main/main.module';
import { UserVerificationComponent } from './pages/user-verification/user-verification.component';
import { MainResolver } from './pages/main/main.resolver';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { PasswordResetRequestComponent } from './pages/password-reset-request/password-reset-request.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { UserSignupComponent } from './pages/user-signup/user-signup.component';
import { UserSignupFormComponent } from './pages/user-signup-form/user-signup-form.component';
import { UserOptOutComponent } from './pages/user-opt-out/user-opt-out.component';
import { PlayerSignupComponent } from './pages/player-signup/player-signup.component';

const routes: Routes = [
  {
    path: 'login',
    data: { label: 'Login' },
    pathMatch: 'prefix',
    component: LoginComponent
  },
  {
    path: 'user-verification',
    data: { label: 'User Verification' },
    pathMatch: 'prefix',
    component: UserVerificationComponent
  },
  {
    path: 'user-signup',
    data: { label: 'User Signup' },
    pathMatch: 'prefix',
    component: UserSignupComponent
  },
  {
    path: 'player-signup',
    data: { label: 'Player Signup' },
    pathMatch: 'prefix',
    component: PlayerSignupComponent
  },
  {
    path: 'user-signup-form',
    data: { label: 'User Signup Form' },
    pathMatch: 'prefix',
    component: UserSignupFormComponent
  },
  {
    path: 'user-opt-out',
    data: { label: 'User Opt Out' },
    pathMatch: 'prefix',
    component: UserOptOutComponent
  },
  {
    path: 'password-reset-request',
    data: { label: 'Password Reset Request' },
    pathMatch: 'prefix',
    component: PasswordResetRequestComponent
  },
  {
    path: 'password-reset',
    data: { label: 'Password Reset' },
    pathMatch: 'prefix',
    component: PasswordResetComponent
  },
  {
    path: '404-not-found',
    component: PageNotFoundComponent,
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: MAIN_ROUTES,
    resolve: {
      userLoaded: MainResolver
    }
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
