import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SchoolDesignElements, Templates } from '../../models/templates';
import { HttpHelperService } from '../http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {
  templateList$ : Templates[]=null;
  templateListBlanks$ : any[]=null;
  templateListDesign$ : any[]=null;
  private endpoint: string = 'templates';

  constructor(private httpHelper: HttpHelperService) { }

  resetState(){
    this.templateList$ = null;
  }

  getAll(filter? : Partial<Templates> & {school?: number}): Observable<Templates[]> {
    return this.httpHelper.request<Templates[]>(this.endpoint,{method: 'GET',search : filter});
  }

  get(id: number): Observable<Templates> {
    return this.httpHelper.request<Templates>(`${this.endpoint}/${id}`);
  }

  create(item: Templates): Observable<Templates> {
    return this.httpHelper.request<Templates>(`${this.endpoint}`, {body: item, method: 'POST'});
  }

  edit(item: Templates): Observable<Templates> {
    return this.httpHelper.request<Templates>(`${this.endpoint}/${item.id}`, {body: item, method: 'PUT'});
  }

  exportBuildFile(templateId: number, schoolIds: number[], playerIds: number[], fullBuild: boolean): Observable<Blob> {
    return this.httpHelper.request<Blob>(`${this.endpoint}/${templateId}/export_build_file`, {body: {schoolIds, playerIds, fullBuild}, method: 'POST', responseType: 'blob'});
  }

  triggerTemplateBuild(templateId: number, schoolIds: number[], playerIds: number[], fullBuild: boolean): Observable<number> {
    return this.httpHelper.request<number>(`${this.endpoint}/${templateId}/trigger_template_build`, {body: {schoolIds, playerIds, fullBuild}, method: 'POST'});
  }

  exportDesignMerge(templateId: number, schoolIds: number[], playerIds: number[], fullBuild: boolean): Observable<Blob> {
    return this.httpHelper.request<Blob>(`${this.endpoint}/${templateId}/export_merge_file`, {body: {schoolIds, playerIds, fullBuild}, method: 'POST', responseType: 'blob'});
  }

  exportDesignMergeFiles(templates: number[], schoolIds: number[], playerIds: number[], fullBuild: boolean): Observable<Blob> {
    return this.httpHelper.request<Blob>(`${this.endpoint}/export_merge_files`, {body: {templates, schoolIds, playerIds, fullBuild}, method: 'POST', responseType: 'blob'});
  }

  generatePrintForms(templates: number[], schoolIds: number[], playerIds: number[], fullBuild: boolean): Observable<number> {
    return this.httpHelper.request<number>(`${this.endpoint}/generate_print_forms`, {body: {templates, schoolIds, playerIds, fullBuild}, method: 'POST'});
  }

  exportSchoolStoreBuild(schoolId: number, fullBuild: boolean): Observable<Blob> {
    const search = {school_id: schoolId};
    if (fullBuild) {
      search['full_build'] = true;
    }
    return this.httpHelper.request<Blob>(`jobs/matrixify-export/`, {search, method: 'GET', responseType: 'blob'});
  }

  generatePrintFormFromSku(skus: string[]): Observable<number> {
    return this.httpHelper.request<number>(`${this.endpoint}/generate_skus_print_forms`, {body: {skus}, method: 'POST'})
  }

  getDesignElements(id: number): Observable<SchoolDesignElements[]> {
    return this.httpHelper.request<SchoolDesignElements[]>(`${this.endpoint}/${id}/design_elements`);
  }
}
