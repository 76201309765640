import { Component, Input, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-download-file',
  styleUrls: ['./download-file.component.scss'],
  template: `
  <button type="button" mat-raised-button color="primary" (click)="saveFile()">
    <mat-icon class="ltr:-ml-2 rtl:-mr-2 ltr:mr-2 rtl:ml-2"
    >get_app</mat-icon>
    <span>Download</span>
  </button>
  `,
})
export class DownloadFileComponent implements OnInit {
  @Input() sourcePath: string;

  constructor() { }

  ngOnInit(): void {
  }

  get fileName(): string {
    if (this.sourcePath) {
      return this.sourcePath.split("/").pop()
    } else {
      return "";
    }
  }

  saveFile(): void {
    saveAs(this.sourcePath, this.fileName);
  }

}
