import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { School, SchoolLogo } from 'src/app/core/models/school';
import { CommonService } from 'src/app/core/services/api/common.service';
import * as FileSaver from 'file-saver';
import { UntypedFormGroup, FormBuilder } from '@angular/forms';
import { catchError, of } from 'rxjs';
import { SchoolLogoService } from '../../services/api/school-logo.service';
import { UserService } from '../../services/api/user.service';

@Component({
  selector: 'app-all-logos-modal',
  templateUrl: './all-logos-modal.component.html',
  styleUrls: ['./all-logos-modal.component.scss']
})
export class AllLogosModalComponent implements OnInit {

  public loadingZip = false;
  public updatingBuildAt = false;
  public savingEmbSettings = false;
  public updatingEmbroidery = false;
  public schoolLogo: SchoolLogo = this.schoolInitialData.schoolLogo;
  public school: School = this.schoolInitialData.school;
  public logoForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public schoolInitialData: {schoolLogo: SchoolLogo, updateEvent: EventEmitter<SchoolLogo>, school: School},
    public commonService : CommonService,
    private schoolLogoService: SchoolLogoService,
    public userService: UserService,
  ) {

  }

  ngOnInit(): void {
  }

  downloadAll(): void {
    this.loadingZip = true;
    this.schoolLogoService.downloadAll(this.schoolInitialData.schoolLogo.id).subscribe(response => {
      let blob = new Blob([response]);
      FileSaver.saveAs(blob, `${this.schoolInitialData.schoolLogo.name}.zip`)
      this.loadingZip = false;
    })
  }

  toggleBuildAt(): void {
    this.updatingBuildAt = true;
    const buildAt = (this.schoolLogo.buildAt) ? null : new Date();
    this.schoolLogoService.partialEdit({id: this.schoolLogo.id, buildAt: buildAt}).subscribe(logo => {
      this.updatingBuildAt = false;
      this.schoolLogo.buildAt = buildAt;
      this.schoolInitialData.updateEvent.emit(this.schoolLogo);
    })
  }

  toggleEmbroidery(): void {
    this.updatingEmbroidery = true;
    const embroideryLoaded = !this.schoolLogo.embroideryLoaded;
    this.schoolLogoService.partialEdit({id: this.schoolLogo.id, embroideryLoaded: embroideryLoaded}).subscribe(logo => {
      this.updatingEmbroidery = false;
      this.schoolLogo.embroideryLoaded = embroideryLoaded;
      this.schoolInitialData.updateEvent.emit(this.schoolLogo);
    })
  }

  toggleHasEmbroidery(): void {
    this.updatingEmbroidery = true;
    const hasEmbTemplate = !this.schoolLogo.hasEmbTemplate;
    this.schoolLogoService.partialEdit({id: this.schoolLogo.id, hasEmbTemplate: hasEmbTemplate}).subscribe(logo => {
      this.updatingEmbroidery = false;
      this.schoolLogo.hasEmbTemplate = hasEmbTemplate;
      this.schoolInitialData.updateEvent.emit(this.schoolLogo);
    })
  }

  updateLogo(field: string): void {
    this.schoolLogo[field] = `${this.schoolLogo[field]}?${Date.now()}`;
  }

  saveEmbSettings(): void {
    const logo: SchoolLogo = {
      id: this.schoolLogo.id,
      darkEmbFilename: this.schoolLogo.darkEmbFilename,
      darkThreadSequence: this.schoolLogo.darkThreadSequence,
      lightEmbFilename: this.schoolLogo.lightEmbFilename,
      lightThreadSequence: this.schoolLogo.lightThreadSequence,
      whiteEmbFilename: this.schoolLogo.whiteEmbFilename,
      whiteThreadSequence: this.schoolLogo.whiteThreadSequence,
      primaryEmbFilename: this.schoolLogo.primaryEmbFilename,
      primaryThreadSequence: this.schoolLogo.primaryThreadSequence,
      secondaryEmbFilename: this.schoolLogo.secondaryEmbFilename,
      secondaryThreadSequence: this.schoolLogo.secondaryThreadSequence,
      hasEmbTemplate: this.schoolLogo.hasEmbTemplate
    }
    this.savingEmbSettings = true;
    this.schoolLogoService.partialEdit(logo).pipe(
      catchError(err => {
        this.savingEmbSettings = false;
        return of(null)
      })
    ).subscribe(savedLogo => {
      if (savedLogo) {
        this.schoolInitialData.updateEvent.emit(savedLogo);
        this.savingEmbSettings = false;
      }
    })
  }

}
