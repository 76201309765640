import { createAction, props } from '@ngrx/store';
import { Code } from 'src/app/core/models/code';

export interface AdminCodesState {
    codes: Code[]
}

export const setAdminCodesState = createAction(
    '[Admin Codes] Set Admin Codes Success',
    props<{ payload: AdminCodesState }>()
);
