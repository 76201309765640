<vex-page-layout>
  <vex-page-layout-content class="container">
    <div class="pb-5">
      <div class="have-school">
        <div class="total-status-of-sale td-padding-first-0 td-row grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 gap-1">
          <div class="relative h-full flex flex-col justify-center full hover:shadow-lg text-primary">
            <div class="grid grid-cols-1 px-0 sm:grid-cols-1 md:grid-cols-1">
              <div [buttonLoader]="totalLoader"
                class="card p-6 relative flex flex-col td-col-card-full  justify-center full hover:shadow-lg trans-shadow user-one h-28">
                <ng-container *ngIf="!totalLoader">
                  <h3 class="text-base md:text-lg text-center md:text-left font-thin m-0 color-dark">Total Payouts
                  </h3>
                  <div class="flex items-center flex-col md:flex-row justify-between  mt-auto">
                    <h2 class="font27 font-bold color-dark"> {{this.totalData?.total || 0 | currency}}</h2>
                    <div class="rounded-full w-12 h-12 flex items-center justify-center text-primary bg-primary/10">
                      <mat-icon> attach_money</mat-icon>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div [buttonLoader]="totalDueLoader"
                class="card p-6 relative h-28 flex flex-col td-col-card-full  justify-center full hover:shadow-lg trans-shadow user-two mt-3">
                <ng-container *ngIf="!totalDueLoader">
                  <h3 class="text-base md:text-lg text-center md:text-left font-thin m-0 color-dark">Next Payout
                  </h3>
                  <div class="flex items-center flex-col md:flex-row justify-between mt-auto">
                    <a [routerLink]="['/user/transactions']" [queryParams]="{status: 'next payout'}" class="hover:underline font27 font-bold color-dark">{{this.totalDueData?.totalDue || 0 | currency}}</a>
                    <ng-container *ngIf="!totalDueLoader && userService.isPlayer()">
                      <div [matTooltipPosition]="'above'" [matTooltipShowDelay]="300" [matTooltipHideDelay]="1000"
                        matTooltip="Your available Payout must be greater than or equal to $5.00"
                        [matTooltipDisabled]="!(this.totalDueData?.totalDue === null ||this.totalDueData?.totalDue<5)">
                        <button color="primary" mat-raised-button color="primary" (click)="showPayout()"
                          [disabled]="this.totalDueData?.totalDue === null ||this.totalDueData?.totalDue<5">Get Payout</button>
                      </div>
                    </ng-container>
                    <div *ngIf="!userService.isPlayer()" class="rounded-full w-12 h-12 flex items-center justify-center text-primary bg-primary/10">
                      <mat-icon> attach_money</mat-icon>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-payout-table (sendGraphData)="getGraphData($event)" [refresh]="refreshPayoutList" [payoutsDisplayedColumns]="payoutsDisplayedColumns" class="mt-4 block"></app-payout-table>
  </vex-page-layout-content>
</vex-page-layout>
