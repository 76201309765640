import { Injectable } from '@angular/core';
import { HttpHelperService } from '../http-helper.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { User, UserVerification } from '../../models/user';
import { UserService } from './user.service';
import { CommonService } from './common.service';
import { PlayerDocument, PlayerSignup, School } from '../../models/school';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private httpHelper: HttpHelperService,
    private router: Router,
    private userService: UserService,
    private commonService : CommonService
  ) { }

  isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    if (token) {
      return true
    } else {
      return false
    }
  }

  login(username: string, password: string): Observable<any> {
    return this.httpHelper.request('login/', {body: {username, password}, method: "POST"})
  }

  getUserFromJwt(token: string): Observable<{user: User, documents: PlayerDocument[]}> {
    return this.httpHelper.request<{user: User, documents: PlayerDocument[]}>('get-user-from-jwt/', {body: {token}})
  }

  userVerification(userData: UserVerification): Observable<any> {
    return this.httpHelper.request('user-verification/', {body: {...userData}, method: 'POST'})
  }

  requestPasswordReset(username: string): Observable<any> {
    return this.httpHelper.request('request-password-reset/', {body: {username}, method: 'POST'})
  }

  passwordReset(userId: number, token: string, newPassword: string): Observable<any> {
    const body = {userId, token, newPassword};
    return this.httpHelper.request('password-reset/', {body, method: 'POST'})
  }

  getActiveSchools(): Observable<School[]> {
    return this.httpHelper.request('public/active-schools/');
  }

  getSignupSchools(): Observable<School[]> {
    return this.httpHelper.request('public/signup-schools/');
  }

  userRequestValidaiton(schoolId: number, email: string): Observable<{success: boolean, message: string}> {
    const body = {schoolId, email};
    return this.httpHelper.request<{success: boolean, message: string}>('public/check-email/', {body, method: 'POST'});
  }

  logout(): void {
    localStorage.removeItem('token');
    this.userService.portalAccessLogout();
    this.commonService.resetAllStates();
    this.router.navigate(['/login']);
  }
  getAuthToken(){
    return localStorage.getItem('token');
  }

  playerSignup(player: PlayerSignup, user: User, schoolId: number): Observable<{error: boolean, token: string, message: string}> {
    return this.httpHelper.request<{error: boolean, token: string, message: string}>(`player-signup`, { body: {player, user, schoolId}, method: "POST" });
  }
  
}
