import { Component, Input, OnInit } from '@angular/core';
import { SchoolSport } from '../../models/school';
import { Router } from '@angular/router';
import { CommonService } from '../../services/api/common.service';

@Component({
  selector: 'app-school-sport-preview',
  templateUrl: './school-sport-preview.component.html',
  styleUrls: ['./school-sport-preview.component.scss']
})
export class SchoolSportPreviewComponent implements OnInit {

  @Input('team') team!: SchoolSport;

  constructor(
    private router: Router,
    public commonService : CommonService
  ) { }

  ngOnInit(): void {
  }

  openTeamRoster(): void {
    this.router.navigate([`school/roster/${this.team.id}`])
  }

}
