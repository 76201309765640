import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DesignElementAttribute, SchoolDesignElements } from "../../models/templates";
import { School } from "../../models/school";
import { environment } from 'src/environments/environment';

export type CoalesceInkRequest = {
  school_id: number;
  template_id: string;
  design_id: string;
  cway: string;
  outputname: string;
  primary_ink: string;
  primary_thread?: string;
  secondary_ink: string;
  secondary_thread?: string;
  prim_stroke: string;
  sec_stroke: string;
  c1: string;
  c2: string;
  c3: string;
  e0_name?: string;
  e0_val?: string;
  e0_font?: string;
  e0_logo?: string;
  e0_mark?: string;
  e1_name?: string;
  e1_val?: string;
  e1_font?: string;
  e1_logo?: string;
  e1_mark?: string;
  e2_name?: string;
  e2_val?: string;
  e2_font?: string;
  e2_logo?: string;
  e2_mark?: string;
  e3_name?: string;
  e3_val?: string;
  e3_font?: string;
  e3_logo?: string;
  e3_mark?: string;
  e4_name?: string;
  e4_val?: string;
  e4_font?: string;
  e4_logo?: string;
  e4_mark?: string;
  e5_name?: string;
  e5_val?: string;
  e5_font?: string;
  e5_logo?: string;
  e5_mark?: string;
  e6_name?: string;
  e6_val?: string;
  e6_font?: string;
  e6_logo?: string;
  e6_mark?: string;
}

@Injectable({
  providedIn: 'root'
})
export class CoalesceInkService {
  constructor(private httpClient: HttpClient) {}

  snakeToCamel(str){
    return str.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  }

  private getTextValue(baseObject: string, textValue: string, school: School): string {
    if (!textValue) return '';
    if (baseObject === 'school') {
      if (textValue === 'school_mascot') {
        return `${school.abbrName} ${school.mascot}`;
      }
      return school[this.snakeToCamel(textValue)];
    } else if ( ['player', 'playerNumber'].includes(baseObject)) {
      if (textValue === 'p_number') {
        return '99';
      } else if (textValue === 'player_number_name') {
        return '99 PLAYERNAME';
      } else if (textValue === 'first_name') {
        return "FIRSTNAME"
      } else if (textValue === 'last_name') {
        return "LASTNAME"
      }
      else {
        return 'PLAYERNAME';
      }
    } else if (baseObject === 'sport') {
      return 'SPORTNAME';
    }
    return textValue;
  }

  renderDesignFromElements(elements: SchoolDesignElements[], school: School, templateId: string, designId?: number) {
    if (templateId === '25'){
      templateId = '20';
    } else if (templateId === '41') {
      templateId = '40';
    } else if (templateId === '41b') {
      templateId = '40b';
    }
    const requestData: CoalesceInkRequest = {
      school_id: school.id,
      template_id: templateId.toString(),
      design_id: designId?.toString() || "",
      cway: "p",
      outputname: "renderedDesign",
      primary_ink: school.primaryInk,
      primary_thread: school.primaryThread || '0',
      secondary_ink: school.secondaryInk,
      secondary_thread: school.primaryThread  || '0',
      prim_stroke: school.primaryInk,
      sec_stroke: school.primaryInk,
      c1: school.primaryInk,
      c2: school.secondaryInk,
      c3: school.secondaryInk,
      e0_name: '',
      e0_val: '',
      e0_font: '',
      e0_logo: '',
      e0_mark: '',
      e1_name: '',
      e1_val: '',
      e1_font: '',
      e1_logo: '',
      e1_mark: '',
      e2_name: '',
      e2_val: '',
      e2_font: '',
      e2_logo: '',
      e2_mark: '',
      e3_name: '',
      e3_val: '',
      e3_font: '',
      e3_logo: '',
      e3_mark: '',
      e4_name: '',
      e4_val: '',
      e4_font: '',
      e4_logo: '',
      e4_mark: '',
      e5_name: '',
      e5_val: '',
      e5_font: '',
      e5_logo: '',
      e5_mark: '',
      e6_name: '',
      e6_val: '',
      e6_font: '',
      e6_logo: '',
      e6_mark: ''
    }
    elements.forEach(element => {
      const index = element.paramId;
      requestData[`e${index}_name`] = element.name;
      element.attributes.forEach(attr => {
        switch(attr.name) {
          case 'font':
            requestData[`e${index}_font`] = attr.value;
            break;
          case 'logo' || 'logodesign' || 'archlogo' || 'textdesign':
            requestData[`e${index}_logo`] = (attr.value !== '0' && attr.value !== '') ? `${school.id}_${attr.value}w.png` : '';
            break;
          case 'text':
            requestData[`e${index}_val`] = this.getTextValue(element.baseObject, attr.value, school);
            break;
          default:
            break;
        }
      })
    })
    return this.renderDesign(requestData);
  }

  renderDesign(requestData: CoalesceInkRequest) {
    return this.httpClient.post(
      `${environment.coalesceBase}generate`, 
      requestData, 
      {observe: 'response', responseType: 'blob'}
    );
  }
}