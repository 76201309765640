import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger60ms } from 'src/@vex/animations/stagger.animation';
import { ConfigBannerItem } from '../../models/utils';

@Component({
  selector: 'app-config-banner',
  templateUrl: './config-banner.component.html',
  styleUrls: ['./config-banner.component.scss'],
  animations: [stagger60ms, fadeInUp400ms],
})
export class ConfigBannerComponent implements OnInit {
  @Input() configItems: ConfigBannerItem[] = [];

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  navigate(path: string) {
    this.router.navigate([path]);
  }

}
