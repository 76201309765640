import { Component, Inject, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SchoolSport } from 'src/app/core/models/school';
import { CommonService } from 'src/app/core/services/api/common.service';
import { SchoolSportService } from 'src/app/core/services/api/school-sport.service';

@Component({
  selector: 'app-school-sport-tile',
  templateUrl: './school-sport-tile.component.html',
  styleUrls: ['./school-sport-tile.component.scss']
})
export class SchoolSportTileComponent {
  commonService = inject(CommonService);

  constructor(
    @Inject(MAT_DIALOG_DATA) public schoolSport: SchoolSport
  ) {
    
  }

  setImage(field: string, value: string): void {
    this.schoolSport[field] = value;
  }



}
