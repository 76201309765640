<router-outlet></router-outlet>
<div id="vex-splash-screen" class="vex-splash-screen" *ngIf="this.commonService.isLoaderActive">
  <div class="wrapper">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="shadow"></div>
    <div class="shadow"></div>
    <div class="shadow"></div>
    <span>Athletic Solutions</span>
  </div>
</div>
