import { createAction, props } from '@ngrx/store';
import { CategorySalesReport } from 'src/app/core/models/sales';

export interface PlayerSalesState {
    categorySalesReport: CategorySalesReport[];
}

export const setPlayerSalesState = createAction(
    '[Player Sales] Set Player Sales Success',
    props<{ payload: PlayerSalesState }>()
);
