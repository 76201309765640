<div class="flex items-center" mat-dialog-title>
  <img *ngIf="schoolLogo.originalUpload" [src]="schoolLogo.originalUpload" (error)="commonService.imageErrorHandle($event)" class="avatar mr-5">

  <h2 class="flex-auto">{{schoolLogo.name}}</h2>
  <div class="flex-1"></div>

  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-divider class="text-border"></mat-divider>

<mat-dialog-content class="grid grid-cols-1 md:grid-cols-3 gap-4">
  <div class="logoWraper rounded border-gray-200 border flex flex-col">
    <label>For White Products</label>
    <img class="max-w-48 max-h-48"  alt="Upload Image" [src]="schoolLogo?.whiteColorway" (error)="commonService.imageErrorHandle($event)" *ngIf="schoolLogo?.whiteColorway"/>
    <div class="flex flex-row" *ngIf="userService.isAdmin()">
      <app-upload-file class="form-control" [modelId]="schoolLogo?.id" model="school-logos" field="whiteColorway"
        [(ngModel)]="schoolLogo.whiteColorway" [ngModelOptions]="{standalone: true}" (output)="updateLogo('whiteColorway')">
      </app-upload-file>
      <app-download-file *ngIf="schoolLogo.whiteColorway" [sourcePath]="schoolLogo.whiteColorway"></app-download-file>
    </div>
    <mat-accordion *ngIf="userService.isAdmin()">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Colorway Embroidery Settings
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field  class="pr-1">
          <mat-label appRemoveAriaOwns>Embroidery Filename</mat-label>
          <input matInput [(ngModel)]="schoolLogo.whiteEmbFilename" class="form-control">
        </mat-form-field>
        <mat-form-field >
          <mat-label appRemoveAriaOwns>Thread Sequence</mat-label>
          <input matInput [(ngModel)]="schoolLogo.whiteThreadSequence" class="form-control">
        </mat-form-field>
      </mat-expansion-panel>
    </mat-accordion>
  </div>


  <div class="logoWraper rounded border-gray-200 border bg-black flex flex-col">
    <label>For Dark Products</label>
    <img class="max-w-48 max-h-48"  alt="Upload Image"  [src]="schoolLogo?.darkColorway" (error)="commonService.imageErrorHandle($event)" *ngIf="schoolLogo?.darkColorway"/>
    <div class="flex flex-row" *ngIf="userService.isAdmin()">
      <app-upload-file class="form-control" [modelId]="schoolLogo?.id" model="school-logos" field="darkColorway"
        [(ngModel)]="schoolLogo.darkColorway" [ngModelOptions]="{standalone: true}" (output)="updateLogo('darkColorway')">
      </app-upload-file>
      <app-download-file *ngIf="schoolLogo.darkColorway" [sourcePath]="schoolLogo.darkColorway"></app-download-file>
    </div>
    <mat-accordion *ngIf="userService.isAdmin()">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Colorway Embroidery Settings
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field  class="pr-1">
          <mat-label appRemoveAriaOwns>Embroidery Filename</mat-label>
          <input matInput [(ngModel)]="schoolLogo.darkEmbFilename" class="form-control">
        </mat-form-field>
        <mat-form-field >
          <mat-label appRemoveAriaOwns>Thread Sequence</mat-label>
          <input matInput [(ngModel)]="schoolLogo.darkThreadSequence" class="form-control">
        </mat-form-field>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="logoWraper rounded border-gray-200 border bg-gray-300 flex flex-col">
    <label>For Light Products</label>
    <img class="max-w-48 max-h-48"  alt="Upload Image" [src]="schoolLogo?.lightColorway" (error)="commonService.imageErrorHandle($event)" *ngIf="schoolLogo?.lightColorway"/>
    <div class="flex flex-row" *ngIf="userService.isAdmin()">
      <app-upload-file class="form-control" [modelId]="schoolLogo?.id" model="school-logos" field="lightColorway"
        [(ngModel)]="schoolLogo.lightColorway" [ngModelOptions]="{standalone: true}" (output)="updateLogo('lightColorway')">
      </app-upload-file>
      <app-download-file *ngIf="schoolLogo.lightColorway" [sourcePath]="schoolLogo.lightColorway"></app-download-file>
    </div>
    <mat-accordion *ngIf="userService.isAdmin()">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Colorway Embroidery Settings
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field  class="pr-1">
          <mat-label appRemoveAriaOwns>Embroidery Filename</mat-label>
          <input matInput [(ngModel)]="schoolLogo.lightEmbFilename" class="form-control">
        </mat-form-field>
        <mat-form-field >
          <mat-label appRemoveAriaOwns>Thread Sequence</mat-label>
          <input matInput [(ngModel)]="schoolLogo.lightThreadSequence" class="form-control">
        </mat-form-field>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="logoWraper rounded border-gray-200 border flex flex-col" [ngStyle]="{'background-color': schoolInitialData.school.primaryHex}">
    <label>For Primary Color Products</label>
    <img class="max-w-48 max-h-48"  alt="Upload Image" [src]="schoolLogo?.primaryColorway" (error)="commonService.imageErrorHandle($event)" *ngIf="schoolLogo?.primaryColorway"/>
    <div class="flex flex-row" *ngIf="userService.isAdmin()">
      <app-upload-file class="form-control" [modelId]="schoolLogo?.id" model="school-logos" field="primaryColorway"
        [(ngModel)]="schoolLogo.primaryColorway" [ngModelOptions]="{standalone: true}" (output)="updateLogo('primaryColorway')">
      </app-upload-file>
      <app-download-file *ngIf="schoolLogo.primaryColorway" [sourcePath]="schoolLogo.primaryColorway"></app-download-file>
    </div>
    <mat-accordion *ngIf="userService.isAdmin()">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Colorway Embroidery Settings
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field  class="pr-1">
          <mat-label appRemoveAriaOwns>Embroidery Filename</mat-label>
          <input matInput [(ngModel)]="schoolLogo.primaryEmbFilename" class="form-control">
        </mat-form-field>
        <mat-form-field >
          <mat-label appRemoveAriaOwns>Thread Sequence</mat-label>
          <input matInput [(ngModel)]="schoolLogo.primaryThreadSequence" class="form-control">
        </mat-form-field>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="logoWraper rounded border-gray-200 border flex flex-col" [ngStyle]="{'background-color': schoolInitialData.school.secondaryHex}">
    <label>For Secondary Color Products</label>
    <img class="max-w-48 max-h-48"  alt="Upload Image" [src]="schoolLogo?.secondaryColorway" (error)="commonService.imageErrorHandle($event)" *ngIf="schoolLogo?.secondaryColorway"/>
    <div class="flex flex-row" *ngIf="userService.isAdmin()">
      <app-upload-file class="form-control" [modelId]="schoolLogo?.id" model="school-logos" field="secondaryColorway"
        [(ngModel)]="schoolLogo.secondaryColorway" [ngModelOptions]="{standalone: true}" (output)="updateLogo('secondaryColorway')">
      </app-upload-file>
      <app-download-file *ngIf="schoolLogo.secondaryColorway" [sourcePath]="schoolLogo.secondaryColorway"></app-download-file>
    </div>
    <mat-accordion *ngIf="userService.isAdmin()">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Colorway Embroidery Settings
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field  class="pr-1">
          <mat-label appRemoveAriaOwns>Embroidery Filename</mat-label>
          <input matInput [(ngModel)]="schoolLogo.secondaryEmbFilename" class="form-control">
        </mat-form-field>
        <mat-form-field >
          <mat-label appRemoveAriaOwns>Thread Sequence</mat-label>
          <input matInput [(ngModel)]="schoolLogo.secondaryThreadSequence" class="form-control">
        </mat-form-field>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="logoWraper rounded border-gray-200 border flex flex-col" *ngIf="userService.isAdmin()">
    <label>Final Logo Upload</label>
    <img class="max-w-48 max-h-48"  alt="Upload Image" [src]="schoolLogo?.finalLogo" (error)="commonService.imageErrorHandle($event)" *ngIf="schoolLogo?.finalLogo"/>
    <div class="flex flex-row" *ngIf="userService.isAdmin()">
      <app-upload-file class="form-control" [modelId]="schoolLogo?.id" model="school-logos" field="finalLogo"
        [(ngModel)]="schoolLogo.finalLogo" [ngModelOptions]="{standalone: true}" (output)="updateLogo('finalLogo')">
      </app-upload-file>
      <app-download-file *ngIf="schoolLogo.finalLogo" [sourcePath]="schoolLogo.finalLogo" ></app-download-file>
    </div>
  </div>
  <div class="rounded border-gray-200 border flex gap-2 items-center justify-center md:justify-between p-4" *ngIf="userService.isAdmin()">
    <div class="text-primary text-xl">PV File</div>
    <app-upload-file class="form-control" [modelId]="schoolLogo?.id" model="school-logos" field="pvFile"
      [(ngModel)]="schoolLogo.pvFile" [ngModelOptions]="{standalone: true}"></app-upload-file>
    <app-download-file *ngIf="schoolLogo?.pvFile" [sourcePath]="schoolLogo.pvFile" ></app-download-file>
  </div>
  <div class="rounded border-gray-200 border flex gap-2 items-center justify-center md:justify-between p-4" *ngIf="userService.isAdmin()">
    <div class="text-primary text-xl">Vector File</div>
    <app-upload-file class="form-control" [modelId]="schoolLogo?.id" model="school-logos" field="vectorFile"
      [(ngModel)]="schoolLogo.vectorFile" [ngModelOptions]="{standalone: true}"></app-upload-file>
    <app-download-file *ngIf="schoolLogo?.vectorFile" [sourcePath]="schoolLogo.vectorFile" ></app-download-file>
  </div>
  <div class="rounded border-gray-200 border flex gap-2 items-center justify-center md:justify-between p-4" *ngIf="userService.isAdmin()">
    <div class="text-primary text-xl">Embroidery File</div>
    <app-upload-file class="form-control" [modelId]="schoolLogo?.id" model="school-logos" field="embFile"
      [(ngModel)]="schoolLogo.embFile" [ngModelOptions]="{standalone: true}"></app-upload-file>
    <app-download-file *ngIf="schoolLogo?.embFile" [sourcePath]="schoolLogo.embFile" ></app-download-file>
  </div>
  <ng-container *ngIf="userService.isAdmin()">
    <div class="rounded border-gray-200 border flex gap-2 items-center justify-center md:justify-between p-4" *ngIf="userService.isAdmin()">
      <mat-slide-toggle [checked]="schoolLogo.buildAt !== null" (change)="toggleBuildAt()" >Build Complete</mat-slide-toggle>
    </div>
    <div class="rounded border-gray-200 border flex gap-2 items-center justify-center md:justify-between p-4" *ngIf="userService.isAdmin()">
      <mat-slide-toggle [checked]="schoolLogo.embroideryLoaded" (toggleChange)="toggleEmbroidery()">Embroidery File Loaded</mat-slide-toggle>
    </div>
    <div class="rounded border-gray-200 border flex gap-2 items-center justify-center md:justify-between p-4" *ngIf="userService.isAdmin()">
      <mat-slide-toggle [(ngModel)]="schoolLogo.hasEmbTemplate" (toggleChange)="toggleHasEmbroidery()">Has Embroidery Templates</mat-slide-toggle>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <ng-container *ngIf="userService.isAdmin()">
    <button color="primary" mat-flat-button (click)="saveEmbSettings()" [class.spinner]="savingEmbSettings">
      Save Settings
    </button>
  </ng-container>
  <div class="grow"></div>

  <button mat-button mat-dialog-close type="button">Cancel</button>
  <button color="primary" mat-flat-button type="submit" (click)="downloadAll()" [class.spinner]="loadingZip">Download All</button>
</mat-dialog-actions>
