<vex-page-layout>
  <div class="container py-gutter">
    <div class="">
      <div class="h-64 relative rounded-t ">
        <img class="w-full h-full object-cover" src="assets/img/demo/landscape.jpg">
        <div class="absolute bg-contrast-black opacity-25 top-0 right-0 bottom-0 left-0 w-full h-full z-0"></div>

        <img class="avatar h-24 w-24 absolute top-6 ltr:left-4 rtl:right-4 sm:hidden bg-white" [src]="logo$ | async">
      </div>

      <div class="z-10 relative -mt-16 px-gutter flex items-start">
        <div [buttonLoader]="profileImageIsLoading">
          <img class="profilePic avatar h-24 w-24 flex-none align-start hidden sm:block border-2 border-white  bg-white"
            (error)="commonService.imageErrorHandle($event)"
            [src]="logo$ | async" 
            (click)="uploadProfilePicture()" 
            matTooltip="upload profile picture">
        </div>


        <div class="max-w-full flex-auto sm:ltr:ml-6 sm:rtl:mr-6">
          <div class="h-16 flex items-end">
            <h1 class="headline text-contrast-white pb-2 m-0">{{user.firstName}} {{user.lastName}}</h1>
          </div>
          <mat-tab-group class="custom-mat">
            <mat-tab label="Account Info">
              <div class="card">
                <form role="form" [formGroup]="accountFormGroup">
                  <div class="px-8 py-4 flex flex-col">
                    <mat-form-field class="flex-auto" >
                      <mat-label appRemoveAriaOwns>Account Username</mat-label>
                      <input formControlName="username" matInput required />
                      <mat-hint>This will be your unique login name.</mat-hint>
                    </mat-form-field>

                    <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
                      <mat-form-field class="flex-auto" >
                        <mat-label appRemoveAriaOwns>First Name</mat-label>
                        <input formControlName="firstName" matInput required>
                      </mat-form-field>

                      <mat-form-field class="flex-auto" >
                        <mat-label appRemoveAriaOwns>Last Name</mat-label>
                        <input formControlName="lastName" matInput required>
                      </mat-form-field>
                    </div>

                    <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">
                      <mat-form-field class="flex-auto" >
                        <mat-label appRemoveAriaOwns>E-mail</mat-label>
                        <input formControlName="email" matInput required />
                        <mat-hint align="end">example@example.com</mat-hint>
                      </mat-form-field>

                      <mat-form-field class="flex-auto" >
                        <mat-label appRemoveAriaOwns>Phone Number</mat-label>
                        <input formControlName="phone" matInput appPhoneMask
                          pattern="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$">
                        <mat-hint align="end">(123) 123-1234</mat-hint>
                      </mat-form-field>
                    </div>

                  </div>
                </form>
                <div class="px-8 py-4 border-t flex items-center justify-between">
                  <button mat-raised-button color="primary" class="save-button" [disabled]="!accountFormGroup.valid"
                    (click)="saveAccount()">
                    Save Account Settings
                  </button>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Update Password">
              <div class="card">

                <form role="form" [formGroup]="passwordFormGroup">
                  <div class="px-8 py-4 flex flex-col">
                    <mat-form-field class="flex-auto" >
                      <mat-label appRemoveAriaOwns>Password</mat-label>
                      <input [type]="passwordInputType" formControlName="password" matInput required />
                      <button (click)="toggleVisibility('password')" *ngIf="passwordInputType === 'password'"
                        mat-icon-button matSuffix type="button" tabindex="-1">
                        <mat-icon>visibility</mat-icon>
                      </button>
                      <button (click)="toggleVisibility('password')" *ngIf="passwordInputType === 'text'"
                        mat-icon-button matSuffix type="button" tabindex="-1">
                        <mat-icon>visibility_off</mat-icon>
                      </button>
                      <mat-hint>Your password should be at least 8 characters long.</mat-hint>
                    </mat-form-field>
                    <mat-form-field class="flex-auto" >
                      <mat-label appRemoveAriaOwns>New Password</mat-label>
                      <input [type]="newPasswordInputType" formControlName="newPassword" matInput required />
                      <button (click)="toggleVisibility('new')" *ngIf="newPasswordInputType === 'password'"
                        mat-icon-button matSuffix type="button" tabindex="-1">
                        <mat-icon>visibility</mat-icon>
                      </button>
                      <button (click)="toggleVisibility('new')" *ngIf="newPasswordInputType === 'text'" mat-icon-button
                        matSuffix type="button" tabindex="-1">
                        <mat-icon>visibility_off</mat-icon>
                      </button>
                      <mat-hint>Your password should be at least 8 characters long.</mat-hint>
                    </mat-form-field>
                    <mat-form-field class="flex-auto" >
                      <mat-label appRemoveAriaOwns>Confirm Password</mat-label>
                      <input [type]="confirmPasswordInputType" formControlName="passwordConfirm" matInput required />
                      <button (click)="toggleVisibility('confirm')" *ngIf="confirmPasswordInputType === 'password'"
                        mat-icon-button matSuffix type="button" tabindex="-1">
                        <mat-icon>visibility</mat-icon>
                      </button>
                      <button (click)="toggleVisibility('confirm')" *ngIf="confirmPasswordInputType === 'text'"
                        mat-icon-button matSuffix type="button" tabindex="-1">
                        <mat-icon>visibility_off</mat-icon>
                      </button>
                      <mat-hint>Your password should be at least 8 characters long.</mat-hint>
                    </mat-form-field>

                  </div>
                </form>
                <div class="px-8 py-4 border-t flex items-center justify-between">
                  <button type="submit" mat-raised-button color="primary" class="save-button"
                    [disabled]="!passwordFormGroup.valid" (click)="updatePassword()">
                    Update Password
                  </button>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Payouts">
              <div class="td-item-card mat-elevation-z w-full" *ngIf="payoutsVisible">

                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-6 mb-4">
                  <div
                    class="card p-6 relative h-full flex flex-col  justify-center full hover:shadow-lg trans-shadow user-one text-primary">
                    <h3 class="text-base md:text-lg text-center md:text-left font-thin m-0 color-dark">Total Payouts
                    </h3>
                    <div class="flex items-center flex-col md:flex-row justify-between  mt-auto">
                      <h2 class="font27 font-bold color-dark"> ${{ this.totalData?.total || '0' }}</h2>
                      <div class="rounded-full w-12 h-12 flex items-center justify-center text-primary bg-primary/10">
                        <mat-icon> attach_money</mat-icon>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card p-6 relative h-full flex flex-col  justify-center full hover:shadow-lg trans-shadow user-two text-primary">
                    <h3 class="text-base md:text-lg text-center md:text-left font-thin m-0 color-dark">Current Available
                      To Payout
                    </h3>
                    <div class="flex items-center flex-col md:flex-row justify-between  mt-auto">
                      <h2 class="font27 font-bold color-dark">${{ this.totalDueData?.totalDue ||
                        '0'}}</h2>
                      <div class="rounded-full w-12 h-12 flex items-center justify-center text-primary bg-primary/10">
                        <mat-icon> attach_money</mat-icon>
                      </div>
                    </div>
                  </div>
                </div>

                <mat-card class="example-card transactionDetailsCard mat-elevation-z p-0">
                  <mat-card-content>
                    <table class="stickytop transactionDetailsTable w-full hidden">
                      <thead class="tableHead ">
                        <tr>
                          <th>Payout Type</th>
                          <th>Recipient Type</th>
                          <th>Receiver</th>
                          <th *ngIf="this.usersService?.userType != 'SCHOOL'">Actions</th>
                        </tr>
                      </thead>
                    </table>
                    <div class="overflow-scroll mt-3" [blockLoader]="Loader">
                      <table class="transactionDetailsTable w-full"
                        *ngIf="this.lastPayoutsArray?.length!=0;else noTransactionDetails">
                        <thead class="tableHead ">
                          <tr class="border-b">
                            <th>Payout Type</th>
                            <th>Recipient Type</th>
                            <th>Receiver</th>
                            <th *ngIf="this.usersService?.userType != 'SCHOOL'">Actions</th>
                          </tr>
                        </thead>
                        <tbody class="tableBody">
                          <tr *ngFor="let item of lastPayoutsArray">
                            <td class="text-center border-b">
                              <span *ngIf='item.payoutType == "Paypal" then paypal else venmo'></span>
                              <ng-template #paypal>
                                <img width="50px" src="/assets/img/paypal.png" alt="paypal" class="mx-auto">
                              </ng-template>
                              <ng-template #venmo>
                                <img src="/assets/img/venmo.png" alt="venmo" class="mx-auto"/>
                              </ng-template>
                            </td>
                            <td class="text-center  border-b"><span>{{item?.recipientType||'-'}}</span></td>
                            <td class="text-center  border-b"><span>{{item?.receiver||'-'}}</span></td>
                            <td class="text-center  border-b" *ngIf="this.usersService?.userType != 'SCHOOL'">
                              <button [disabled]="this.totalDueData?.totalDue===null||this.totalDueData?.totalDue<5"
                                (click)="goToPayout(item)" [matTooltipPosition]="'above'"
                                matTooltip="use this saved payout" mat-icon-button
                                aria-label="Example icon button with a vertical three dot icon">
                                <mat-icon>add</mat-icon>
                              </button>
                              &nbsp;
                              <button (click)="deleteLastPayout(item)" [matTooltipPosition]="'above'"
                                matTooltip="delete this saved payout" mat-icon-button
                                aria-label="Example icon button with a vertical three dot icon">
                                <mat-icon>delete</mat-icon>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <ng-template #noTransactionDetails>
                      <div class="noDataFoundDiv flex items-center justify-center h-full text-[#c1b0b0]">
                        No Data Found
                      </div>
                    </ng-template>
                  </mat-card-content>
                </mat-card>

              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</vex-page-layout>
