import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewChild, OnChanges, SimpleChanges, AfterContentInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { catchError, of } from 'rxjs';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { ConfirmDialogComponent } from 'src/app/core/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialog } from 'src/app/core/models/utils';
import { CommonService } from 'src/app/core/services/api/common.service';
import { SchoolLogoService } from 'src/app/core/services/api/school-logo.service';
import { UserService } from 'src/app/core/services/api/user.service';
import { AllLogosModalComponent } from '../all-logos-modal/all-logos-modal.component';
import { School, SchoolLogo } from '../../models/school';

@Component({
  selector: 'app-school-logo-item',
  templateUrl: './school-logo-item.component.html',
  styleUrls: ['./school-logo-item.component.scss'],
  animations: [fadeInRight400ms,fadeInUp400ms,
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('true', style({ height: '0', visibility: 'hidden' })),
      transition('false => true', animate(400 + 'ms ease-in')),
      transition('true => false', animate(400 + 'ms ease-out'))
    ])]
})
export class SchoolLogoItemComponent implements OnInit, AfterContentInit, OnChanges {

  @ViewChild(MatStepper) stepper: MatStepper;

  @Input({required: true}) logo: SchoolLogo;
  @Input() school: School;
  @Output() logoSaved = new EventEmitter<SchoolLogo>();
  @Output() logoDeleted = new EventEmitter<SchoolLogo>();

  public editing = false;
  public loader = false;
  public index: number = 0;
  public fullLoader = false;

  constructor(
    public commonService: CommonService,
    public userService: UserService,
    public schoolLogoService: SchoolLogoService,
    private dialog: MatDialog
  ) { }

  ngAfterContentInit(): void {
    this.getLogoIndex();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getLogoIndex();
  }

  saveLogo(logo: SchoolLogo): void {
    const schoolLogo: SchoolLogo = {
      id: logo.id,
      school: logo.school,
      name: logo.name,
      main: logo.main,
      embroideryNeeded: logo.embroideryNeeded
    };
    this.loader = true;
    this.schoolLogoService.edit(schoolLogo).subscribe(savedLogo => {
      this.editing = false;
      this.loader = false;
      this.logoSaved.emit(savedLogo);
    })
  }

  openColorwayModal(schoolLogo: SchoolLogo): void {
    this.dialog.open(AllLogosModalComponent, {
      data: {schoolLogo, updateEvent: this.logoSaved, school: this.school},
      width: "75vw"
    }).afterClosed().subscribe({})
  }

  getLogoIndex(): void {
    if (!this.logo.originalUpload) {
    } else if (this.logo.originalUpload && (!this.logo.buildAt || new Date(this.logo.uploadAt).getDate() > new Date(this.logo.buildAt).getDate())) {
      this.index = 1;
    } else {
      this.index = 2;
    }
  }

  updateLogo(logo: SchoolLogo): void {
    this.logoSaved.emit(logo);
    this.index = 1;
    this.stepper.selectedIndex = 1;
  }

  delete(): void {
    const content: ConfirmDialog = {content: `Delete Logo: ${this.logo.name}`};
    this.dialog.open(ConfirmDialogComponent, {
      data: content
    }).afterClosed().subscribe(response => {
      if (response) {
        this.fullLoader = true;
        this.schoolLogoService.delete(this.logo.id).pipe(
          catchError(() => {
            this.fullLoader = false;
            return of("error");
          })
        ).subscribe(deleted => {
          if (deleted !== "error") {
            this.fullLoader = false;
            this.logoDeleted.emit(this.logo);
          }
        })
      }
    })

  }

}

