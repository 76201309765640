import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { School, SchoolDocument, Sport } from '../../models/school';
import { HttpHelperService } from '../http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class SportService {

  private endpoint: string = 'sports';

  constructor(private httpHelper: HttpHelperService) { }


  getAll(): Observable<Sport[]> {
    return this.httpHelper.request<Sport[]>(this.endpoint);
  }

  get(id: number): Observable<Sport> {
    return this.httpHelper.request<Sport>(`${this.endpoint}/${id}`);
  }

  filter(item: Sport): Observable<Sport> {
    return this.httpHelper.request<Sport>(`${this.endpoint}`, { params: item, method: 'GET' });
  }

  create(item: Sport): Observable<Sport> {
    return this.httpHelper.request<Sport>(`${this.endpoint}`, { body: item, method: 'POST' });
  }

  edit(item: Sport): Observable<Sport> {
    return this.httpHelper.request<Sport>(`${this.endpoint}/${item.id}`, { body: item, method: 'PUT' });
  }

  deleteSchool(sportId: number): Observable<null> {
    return this.httpHelper.request<null>(`${this.endpoint}/${sportId}`, { method: 'DELETE' });
  }

}
