import { AfterViewInit, Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UserPayoutsService } from 'src/app/core/services/api/user-payouts.service';
import { Payouts, PayoutsPaginatedData, Total, TotalDue, TriggerPayout } from 'src/app/core/models/payouts';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { PayoutsPaymentComponent } from './payouts-payment/payouts-payment.component';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/core/services/api/user.service';
import { User } from 'src/app/core/models/user';
import { take } from 'rxjs';
import { UsersService } from 'src/app/core/services/api/users.service';
import { Router } from '@angular/router';
import { PayoutDisclosureComponent } from './payout-disclosure/payout-disclosure.component';
import { CommonService } from 'src/app/core/services/api/common.service';

@Component({
  selector: 'app-user-payouts',
  templateUrl: './user-payouts.component.html',
  styleUrls: ['./user-payouts.component.scss']
})
export class UserPayoutsComponent implements OnInit {
  totalLoader: boolean = false;
  totalDueLoader: boolean = false;
  refreshPayoutList: boolean=false;
  totalData: Total;
  totalDueData: TotalDue;
  user: User;
  navigation;
  lastPayoutData = {}
  showLastPayoutData: boolean = false
  payoutsDisplayedColumns: string[] = ['submittedAt', 'payoutType', 'receiver', 'amount', 'itemStatus', 'actions'];

  public userPayoutSeries: ApexAxisChartSeries = [];
  constructor(
    private userPayoutService: UserPayoutsService,
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    public userService: UserService,
    public usersService: UsersService,
    private router: Router,
    public commonService: CommonService
  ) {
  }

  ngOnInit(): void {
    this.userService.user.pipe(take(1)).subscribe(user => {
      this.user = user;
    });
    if (this.userService.isSchool()) {
      this.payoutsDisplayedColumns = ['submittedAt', 'payoutType', 'amount', 'itemStatus', 'actions'];
    }
    this.totalData = this.userPayoutService.total$;
    this.totalDueData = this.userPayoutService.totalDue$;
    if (!this.totalData) this.getTotal();
    if (!this.totalDueData) this.getTotalDue();
    if (this.showLastPayoutData)
      this.openDialogDuringNavigation()

  }

  getGraphData(event: ApexAxisChartSeries) {
    this.userPayoutSeries = [...event];
  }

  openDialogDuringNavigation() {
    let lastPayout = {
      amount: this.totalDueData?.totalDue,
      user: this.user,
      data: this.lastPayoutData
    }
    this.dialog.open(PayoutsPaymentComponent, {
      width: '100%',
      maxWidth: '400px',
      data: lastPayout,
      panelClass: 'custom-modalbox',
      disableClose: true
    }).afterClosed().subscribe(result => {
      if (result) {
        this.getTotal();
        this.getTotalDue();
        this.refreshPayoutList=!this.refreshPayoutList;
      }
    });
  }

  getTotal() {
    this.totalLoader = true
    this.userPayoutService.Total().subscribe((data: Total) => {
      this.totalLoader = false
      this.totalData = data;
      this.userPayoutService.total$ = data;
    }, (err) => {
      this.totalLoader = false;
    })
  }

  getTotalDue() {
    this.totalDueLoader = true
    this.userPayoutService.TotalDue().subscribe((data: TotalDue) => {
      this.totalDueLoader = false
      this.totalDueData = data;
    }, (err) => {
      this.totalDueLoader = false
    })
  }

  showPayout() {
    let previousPayout = {
      amount: this.totalDueData?.totalDue,
      user: this.user,
      data: this.lastPayoutData
    }
    let data: TriggerPayout = {
      amount: this.totalDueData?.totalDue,
      user: this.user
    };
    this.dialog.open(PayoutsPaymentComponent, {
      width: '100%',
      maxWidth: '400px',
      height: 'auto',
      data: this.showLastPayoutData ? previousPayout : data,
      panelClass: 'custom-modalbox',
      disableClose: true
    }).afterClosed().subscribe(result => {
      if (result) {
        this.getTotal();
        this.getTotalDue();
        this.refreshPayoutList=!this.refreshPayoutList;
      }
    });
  }
}
