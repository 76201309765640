<div mat-dialog-title class="flex flex-row flex-wrap items-start justify-between">
  <h2>Design - {{printLocation.location}}</h2>
  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-divider class="text-border"></mat-divider>

<mat-dialog-content class="flex flex-col gap-2 p-4 items-center">
  <div class="h-36 w-36 sm:h-52 sm:w-52 flex-shrink-0 border rounded bg-gray-100 mx-auto">
    <img [src]="printLocation?.originalUpload" (error)="commonService.imageErrorHandle($event)" alt="Upload Image"
      class="h-full w-full object-contain object-center p-1 rounded-md">
  </div>
  <div class="grid grid-cols-1 gap-4 sm:grid-cols-3 md:grid-cols-5">
    <div class="logoWraper rounded border-gray-200 border w-48">
      <label>For White Products</label>
      <img class="w-48 h-48" alt="Upload Image" [src]="printLocation?.whiteColorway"
        (error)="commonService.imageErrorHandle($event)" *ngIf="printLocation?.whiteColorway" />
      <app-upload-file *ngIf="userService.isAdmin()" class="form-control" [modelId]="printLocation?.id"
        model="print-locations" field="whiteColorway" [disabled]="printLocation?.whiteColorway != undefined"
        [(ngModel)]="printLocation.whiteColorway" [ngModelOptions]="{standalone: true}">
      </app-upload-file>
    </div>
    <div class="logoWraper rounded border-gray-200 border bg-black w-48">
      <label>For Dark Products</label>
      <img class="w-48 h-48" alt="Upload Image" [src]="printLocation?.darkColorway"
        (error)="commonService.imageErrorHandle($event)" *ngIf="printLocation?.darkColorway" />
      <app-upload-file *ngIf="userService.isAdmin()" class="form-control" [modelId]="printLocation?.id"
        model="print-locations" field="darkColorway" [disabled]="printLocation?.darkColorway != undefined"
        [(ngModel)]="printLocation.darkColorway" [ngModelOptions]="{standalone: true}">
      </app-upload-file>
    </div>
    <div class="logoWraper rounded border-gray-200 border bg-gray-300 w-48">
      <label>For Light Products</label>
      <img class="w-48 h-48" alt="Upload Image" [src]="printLocation?.lightColorway"
        (error)="commonService.imageErrorHandle($event)" *ngIf="printLocation?.lightColorway" />
      <app-upload-file *ngIf="userService.isAdmin()" class="form-control" [modelId]="printLocation?.id"
        model="print-locations" field="lightColorway" [disabled]="printLocation?.lightColorway != undefined"
        [(ngModel)]="printLocation.lightColorway" [ngModelOptions]="{standalone: true}">
      </app-upload-file>
    </div>
    <div class="logoWraper rounded border-gray-200 border w-48" [ngStyle]="{'background-color': data.school?.primaryHex}">
      <label>For Primary Products</label>
      <img class="w-48 h-48" alt="Upload Image" [src]="printLocation?.primaryColorway"
        (error)="commonService.imageErrorHandle($event)" *ngIf="printLocation?.primaryColorway" />
      <app-upload-file *ngIf="userService.isAdmin()" class="form-control" [modelId]="printLocation?.id"
        model="print-locations" field="primaryColorway" [disabled]="printLocation?.primaryColorway != undefined"
        [(ngModel)]="printLocation.primaryColorway" [ngModelOptions]="{standalone: true}">
      </app-upload-file>
    </div>
    <div class="logoWraper rounded border-gray-200 border w-48" [ngStyle]="{'background-color': data.school?.secondaryHex}">
      <label>For Secondary Products</label>
      <img class="w-48 h-48" alt="Upload Image" [src]="printLocation?.secondaryColorway"
        (error)="commonService.imageErrorHandle($event)" *ngIf="printLocation?.secondaryColorway" />
      <app-upload-file *ngIf="userService.isAdmin()" class="form-control" [modelId]="printLocation?.id"
        model="print-locations" field="secondaryColorway"
        [disabled]="printLocation?.secondaryColorway != undefined" [(ngModel)]="printLocation.secondaryColorway"
        [ngModelOptions]="{standalone: true}">
      </app-upload-file>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="grow"></div>
  <ng-container *ngIf="userService.isAdmin()">
    <button class="btn-small text-white mt-2" [color]="(printLocation.buildAt) ? 'warn' : 'primary'" mat-flat-button
      [class.spinner]="buildAtLoading"
      *ngIf="userService.isAdmin() && printLocation?.needsDesignCleanup && printLocation.originalUpload"
      (click)="cleanUpToggle(printLocation)">{{ (printLocation.buildAt) ? 'Mark Clean-Up Incomplete' : 'Mark Clean-Up Complete' }}
    </button>
  </ng-container>


  <!-- <button mat-button mat-dialog-close type="button">Cancel</button>
  <button color="primary" mat-flat-button type="submit" (click)="downloadAll()" [class.spinner]="loadingZip">Download All</button> -->
</mat-dialog-actions>
