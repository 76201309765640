import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LicenseEntity } from '../../models/license';
import { HttpHelperService } from '../http-helper.service';
import { HttpParams } from '@angular/common/http';
import { JournalEntrySale, TotalSalesReport } from '../../models/sales';
import { SalesByDay } from '../../models/home';

@Injectable({
  providedIn: 'root'
})
export class LicenseEntityService {
  licenseEntityList$ : LicenseEntity[]=null;
  private endpoint: string = 'license-entity';

  constructor(private httpHelper: HttpHelperService) { }
  resetState(){
    this.licenseEntityList$ = null;
  }
  
  getAll(filter?:any): Observable<LicenseEntity[]> {
    return this.httpHelper.request<LicenseEntity[]>(this.endpoint,{method : 'GET',search : filter});
  }

  get(id: number): Observable<LicenseEntity> {
    return this.httpHelper.request<LicenseEntity>(`${this.endpoint}/${id}`);
  }

  create(item: LicenseEntity): Observable<LicenseEntity> {
    return this.httpHelper.request<LicenseEntity>(`${this.endpoint}`, {body: item, method: 'POST'});
  }

  edit(item: LicenseEntity): Observable<LicenseEntity> {
    return this.httpHelper.request<LicenseEntity>(`${this.endpoint}/${item.id}`, {body: item, method: 'PUT'});
  }
  delete(item : LicenseEntity) : Observable<LicenseEntity>{
    return this.httpHelper.request<LicenseEntity>(`${this.endpoint}/${item.id}`, { method: 'DELETE'});
  }

  totalSalesReport(start: Date, end: Date): Observable<TotalSalesReport> {
    let params = new HttpParams({
      fromObject: {
        'start_date': start.toISOString().split('T')[0],
        'end_date': end.toISOString().split('T')[0]
      }
    });
    return this.httpHelper.request<TotalSalesReport>(`${this.endpoint}/total-sales-report/`, { method: 'GET', search: params });
  }

  journalEntries(start: Date, end: Date): Observable<JournalEntrySale[]> {
    let params = new HttpParams({
      fromObject: {
        'start_date': start.toISOString().split('T')[0],
        'end_date': end.toISOString().split('T')[0]
      }
    });
    return this.httpHelper.request<JournalEntrySale[]>(`${this.endpoint}/journal-entries/`, { method: 'GET', search: params });
  }

  salesByDay(start: Date, end: Date): Observable<SalesByDay[]> {
    let params = new HttpParams({
      fromObject: {
        'start_date': start.toISOString().split('T')[0],
        'end_date': end.toISOString().split('T')[0]
      }
    });
    return this.httpHelper.request<SalesByDay[]>(`${this.endpoint}/sales-by-day/`, { method: 'GET', search: params });
  }
}
