import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Organization } from '../../models/organization';
import { HttpHelperService } from '../http-helper.service';
@Injectable({
  providedIn: 'root'
})
export class AdminOrgService {
  adminOrganizations$: Organization[] = null;
  constructor(private httpHelper: HttpHelperService) { }
  unassignSchoolApiCall$=new BehaviorSubject(false)

  resetState() {
    this.adminOrganizations$ = null;
  }

  getOrganizations(): Observable<Organization[]> {
    return this.httpHelper.request<Organization[]>(`organization`, { method: 'GET' });
  }

  createOrganization(item): Observable<Organization> {
    let createOrgHeaders = new HttpHeaders({
      "Content-Type": "application/json"
    })
    return this.httpHelper.request<Organization>(`organization`, { body: item, method: 'POST', headers: createOrgHeaders });
  }

  updateOrganization(item): Observable<Organization> {
    let updateOrgHeaders = new HttpHeaders({
      "Content-Type": "application/json"
    })
    return this.httpHelper.request<Organization>(`organization/${item.id}`, { body: item, method: 'PUT', headers: updateOrgHeaders });
  }

  deleteOrganization(id) {
    return this.httpHelper.request<void>(`organization/${id}`, { method: 'DELETE' });
  }

  unassignSchool(payload,orgId){
    this.unassignSchoolApiCall$.next(true)
    return this.httpHelper.request<void>(`organization/${orgId}/remove_school`, {body:payload, method: 'POST' });
  }
}
