import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, debounceTime, distinctUntilChanged, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { School } from '../../models/school';
import { PortalAccess, AdminSchoolsPaginatedData } from '../../models/user';
import { AdminOrgService } from '../../services/api/admin-org.service';
import { AdminPaginationService } from '../../services/api/admin-pagination.service';
import { CommonService } from '../../services/api/common.service';
import { SchoolService } from '../../services/api/school.service';
import { UserService } from '../../services/api/user.service';
import { NotifierService } from '../../services/notifier.services';
import { SchoolCreateUpdateComponent } from './school-create-update/school-create-update.component';
import { SchoolDeleteBoxComponent } from './school-delete-box/school-delete-box.component';
import { SchoolImageUploadComponent } from './school-image-upload/school-image-upload.component';
import { CopyToClipboardModalComponent } from '../copy-to-clipboard-modal/copy-to-clipboard-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-schools',
  templateUrl: './schools.component.html',
  styleUrls: ['./schools.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ]
})
export class SchoolsComponent implements OnInit {

  @Input() canCreate: boolean = false;
  totalRows = 0;
  pageSize = 50;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  loader: boolean = false
  dataLoading = false;
  schoolListSub: Subscription

  public rowData$!: Observable<School[]>;

  subject$: ReplaySubject<any> = new ReplaySubject<School[]>(1);
  data$: Observable<any> = this.subject$.asObservable();
  schools: School[];


  columns: TableColumn<School>[] = [
    { label: 'Logo', property: 'mainLogo', type: 'image', visible: true },
    { label: 'Name', property: 'fullName', type: 'text', visible: true },
    { label: 'ID', property: 'id', type: 'text', visible: true },
    { label: 'Abbr', property: 'abbrName', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Nickname', property: 'nickname', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Letters', property: 'letters', type: 'text', visible: false, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Mascot', property: 'mascot', type: 'text', visible: false, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Chant', property: 'chant', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Actions', property: 'actions', type: 'button', visible: true }
  ];

  dataSource: MatTableDataSource<School> | null;
  selection = new SelectionModel<School>(true, []);
  searchCtrl = new UntypedFormControl();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }

  constructor(
    private schoolService: SchoolService,
    private dialog: MatDialog,
    public userService: UserService,
    private router: Router,
    private adminPaginationService: AdminPaginationService,
    public commonService: CommonService,
    public notifier: NotifierService,
    private adminOrgService: AdminOrgService

  ) { }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    let schoolData = this.adminPaginationService.adminSchools$;
    if (!schoolData) {
      this.loadAdminSchoolsData();
    } else {
      this.pageSize = schoolData.pageSize;
      this.currentPage = schoolData.currentPage;
      this.subject$.next(schoolData);
    }

    this.dataSource = new MatTableDataSource();
    this.data$.subscribe((schools: AdminSchoolsPaginatedData) => {
      this.loader = false
      this.schools = schools.results
      this.dataSource.data = schools.results
      setTimeout(() => {
        this.paginator.pageIndex = this.currentPage;
        this.paginator.length = schools.count;
      })
    });
    this.searchCtrl.valueChanges.pipe(
      debounceTime(500), 
      distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe(value => {
      if (value == '' || value.length > 2) {
        this.currentPage = 0;
        this.loadAdminSchoolsData(value)
      };
    });
  }

  loadAdminSchoolsData(value: string = '') {
    this.loader = true
    this.schoolListSub = this.adminPaginationService.adminSchoolsPagination(this.currentPage, this.pageSize, value).subscribe((adminSchoolsData: AdminSchoolsPaginatedData) => {
      adminSchoolsData.pageSize = this.pageSize;
      adminSchoolsData.currentPage = this.currentPage;
      this.adminPaginationService.adminSchools$ = adminSchoolsData;
      this.subject$.next(adminSchoolsData);
    })
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadAdminSchoolsData();
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  updateSchool(school: School): void {
    if (!this.userService.isArtwork()) {
      this.dialog.open(SchoolCreateUpdateComponent, {
        data: school
      }).afterClosed().subscribe((updatedSchool) => {
        if (updatedSchool) {
          const index = this.schools.findIndex(s => s.id === updatedSchool.id);
          this.schools[index] = { ...updatedSchool };
          this.subject$.next(this.schools);
          this.loadAdminSchoolsData();
        }
      });
    }
    
  }

  deleteSchool(school: School, deleteOrUnassign: string) {
    this.dialog.open(SchoolDeleteBoxComponent, {
      data: { school, deleteOrUnassign }
    }).afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.schoolService.deleteSchool(school.id).subscribe(data => {
          this.notifier.alert({ message: `${school.fullName} Deleted Successfully`, type: 'success', duration: 3000 });
          const filteredSchools = this.schools.filter(s => s.id != school.id);
          this.subject$.next(filteredSchools);
          this.loadAdminSchoolsData();
        })
      }
    })
  }
  unassignSchool(school: School, deleteOrUnassign: string) {
    this.dialog.open(SchoolDeleteBoxComponent, {
      data: { school, deleteOrUnassign }
    }).afterClosed().subscribe((confirm) => {
      if (confirm) {
        let payload = {
          school: school.id
        }
        this.adminOrgService.unassignSchool(payload, this.userService?.currentUser?.organizationId).subscribe(data => {
          this.notifier.alert({ message: data[0], type: 'success', duration: 3000 });
          const filteredSchools = this.schools.filter(s => s.id != school.id);
          this.subject$.next(filteredSchools);
          this.loadAdminSchoolsData()
        })
      }
    })
  }

  uploadSchoolImage(school: School) {
    this.dialog.open(SchoolImageUploadComponent, {
      data: school
    }).afterClosed().subscribe((data) => {

    })
  }

  createSchool() {
    this.dialog.open(SchoolCreateUpdateComponent).afterClosed().subscribe((school: School) => {
      if (school) {
        this.schools.unshift(school);
        this.subject$.next(this.schools);
        this.loadAdminSchoolsData();
      }
    });
  }

  loadSchool(school: School): void {
    this.schoolService.get(school.id).subscribe(school => {
      this.userService.setSchool(school);
      this.router.navigate(['/school/home'])
    })
  }

  signinSchool(school: School) {
    let data: PortalAccess = {
      group: 'SCHOOL',
      id: school.id
    }
    this.userService.setPortalAccess(data);
  }

  sendVerificationEmails(school: School): void {
    this.dataLoading = true;
    this.schoolService.sendAllVerificationEmails(school.id).pipe(
      catchError(err => {
        this.dataLoading = false;
        return of(null);
      })
    ).subscribe(() => {
      this.dataLoading = false;
    })
  }

  ngOnDestroy(): void {
    if (this.schoolListSub) this.schoolListSub.unsubscribe();
  }

  getSignupLink(school: School, baseRoute: string): void {
    this.schoolService.signupLink(school.id, baseRoute).subscribe(link => {
      this.dialog.open(CopyToClipboardModalComponent, {
        data: link
      })
    })
  }

}
