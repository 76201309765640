<div mat-dialog-title class="flex flex-row flex-wrap items-start justify-between">
  <h2>Template Preview - {{design.name}}</h2>
  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
</div>
<mat-divider class="text-border"></mat-divider>
<mat-dialog-content *ngIf="school$ | async as school">
  <ng-container *ngIf="categories$ | async as categories">
    <div class="w-full flex flex-row gap-2">
      <mat-form-field appearance="outline" class="w-full" >
        <mat-label appRemoveAriaOwns>Licensing Group</mat-label>
        <mat-select [formControl]="licensingGroupControl" >
          <mat-option *ngFor="let code of licensingGroups$ | async" [value]="code.code">{{code.description}}</mat-option>
        </mat-select>
        <mat-icon class="mr-3" matPrefix>menu_book</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-full" *ngIf="categories">
        <mat-label appRemoveAriaOwns>Product Category</mat-label>
        <mat-select [multiple]="true" [(ngModel)]="selectedCategories">
          <mat-option *ngFor="let cat of categories" [value]="cat">{{cat.description}}</mat-option>
        </mat-select>
        <mat-icon class="mr-3" matPrefix>groups</mat-icon>
      </mat-form-field>
    </div>
    <div class="mx-auto px-4 sm:px-6 lg:px-8" id="templatePdf" #templatePdf>
      <app-license-product-category
       *ngFor="let category of selectedCategories" 
       [sides]="sides"
       [design]="design" 
       [school]="school" 
       [category]="category"/>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button mat-raised-button color="primary" (click)="generatePDF()" [class.spinner]="Loader"
    [disabled]="Loader">Export PDF</button>
</mat-dialog-actions>
