import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { HttpHelperService } from '../http-helper.service';
import { CategorySalesReport } from '../../models/sales';

@Injectable({
  providedIn: 'root'
})
export class PlayerSalesService {
  categorySales$ : CategorySalesReport[]=null;

  constructor(private httpHelper: HttpHelperService) { }

  resetState(){
    this.categorySales$=null;
  }
  categorySalesReport(start: Date, end: Date): Observable<CategorySalesReport[]> {
    var end_date = new Date();
    let categorySalesParams = new HttpParams({
      fromObject: {
        'start_date': start.toISOString().split('T')[0],
        'end_date': end.toISOString().split('T')[0]
      }
    })
    return this.httpHelper.request<CategorySalesReport[]>(`players/category_sales_report`, { method: 'GET', search: categorySalesParams });
  }
}
