import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  template: `      
<div class="loader-container abs-center">  
  <div class="olympicloader">
    <i class="loader-circle second"></i>
  </div>
</div>
 `
})
export class LoadingSpinnerComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {}
}
