import { AfterContentChecked, Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SchoolDesignElements, SchoolTemplateDesign, TemplateSide, licensingStatuses } from '../../models/templates';
import { School } from '../../models/school';
import { CommonService } from '../../services/api/common.service';
import { SchoolTemplateDesignsService } from '../../services/api/school-template-designs.service';
import { CoalesceInkRequest, CoalesceInkService } from '../../services/api/coalesce-ink.service';
import { catchError, forkJoin, mergeMap, of, take } from 'rxjs';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { TemplatePreviewComponent } from 'src/app/pages/school/school-designs/template-preview/template-preview.component';

const DESIGN_IMAGES = [
  'whiteColorway', 'primaryColorway', 'secondaryColorway', 'darkColorway', 'lightColorway', 
  'primaryBackColorway', 'secondaryBackColorway', 'darkBackColorway', 'lightBackColorway', 'whiteBackColorway'
];

@Component({
  selector: 'app-design-licensing',
  templateUrl: './design-licensing.component.html',
  styleUrls: ['./design-licensing.component.scss']
})
export class DesignLicensingComponent implements AfterContentChecked, OnChanges {
  @Input({required: true}) design: SchoolTemplateDesign;
  @Input({required: true}) school: School;
  @Input({required: true}) elements: SchoolDesignElements[];
  @Output() designUpdated = new EventEmitter<SchoolTemplateDesign>();
  commonService = inject(CommonService);
  designService = inject(SchoolTemplateDesignsService);
  coalesceInkService = inject(CoalesceInkService);
  dialog = inject(MatDialog);
  selectedSide: TemplateSide;
  sides: TemplateSide[] = [];

  LICENSING_STATUSES = licensingStatuses;

  loadingDesigns: string[] = [];

  cacheBrake = new Date().getTime();

  constructor() {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if ('elements' in changes && this.elements?.length > 0) {
      this.sides = this.templateSides(this.elements);
      this.selectedSide = this.sides[0];
    }
  }

  ngAfterContentChecked(): void {
    // DESIGN_IMAGES.forEach(field => {
    //   if (this.design[field]) {
    //     this.design[field] = this.design[field] + `?${new Date().getTime()}`;
    //   }
    // });
  }

  generateLicenseSheets(): void {
    this.dialog.open(TemplatePreviewComponent, {
      data: {design: this.design, sides: this.sides}
    })

  }

  generateColorways(): void {
    this.loadingDesigns.push('data');
    this.designService.generateColorways(this.design.id).subscribe(data => {
      data.forEach(d => {
        let designField = 'whiteColorway';
        const colorway = d.cway.replace('c', '');
        designField = d.f6;
        this.loadingDesigns.push(designField);
        const request: CoalesceInkRequest = {
          school_id: d.schoolId,
          template_id: d.templateId.toString(),
          design_id: d.designId.toString(),
          cway: d.cway,
          outputname: designField,
          primary_ink: d.primaryInk,
          primary_thread: d.primaryThread,
          secondary_ink: d.secondaryInk,
          secondary_thread: d.secondaryThread,
          prim_stroke: d.primStroke,
          sec_stroke: d.secStroke,
          c1: d.c1,
          c2: d.c2,
          c3: d.c3,
          e0_name: d.e0Name || '',
          e0_val: d.e0Val,
          e0_font: d.e0Font || '',
          e0_logo: d.e0Logo,
          e0_mark: d.e0Mark,
          e1_name: d.e1Name || '',
          e1_val: d.e1Val || '',
          e1_font: d.e1Font || '',
          e1_logo: d.e1Logo,
          e1_mark: d.e1Mark,
          e2_name: d.e2Name || '',
          e2_val: d.e2Val || '',
          e2_font: d.e2Font || '',
          e2_logo: d.e2Logo,
          e2_mark: d.e2Mark,
          e3_name: d.e3Name || '',
          e3_val: d.e3Val || '',
          e3_font: d.e3Font || '',
          e3_logo: d.e3Logo,
          e3_mark: d.e3Mark,
          e4_name: d.e4Name || '',
          e4_val: d.e4Val || '',
          e4_font: d.e4Font || '',
          e4_logo: d.e4Logo,
          e4_mark: d.e4Mark,
          e5_name: d.e5Name || '',
          e5_val: d.e5Val || '',
          e5_font: d.e5Font || '',
          e5_logo: d.e5Logo,
          e5_mark: d.e5Mark,
          e6_name: d.e6Name || '',
          e6_val: d.e6Val || '',
          e6_font: d.e6Font || '',
          e6_logo: d.e6Logo,
          e6_mark: d.e6Mark
        };
        forkJoin({
          designField: of(designField),
          image: this.coalesceInkService.renderDesign(request)
        }).pipe(
          take(1),
          catchError(err => {
            return of(null); 
          }),
          mergeMap(data => {
            if (!data.image) {
              return of(null);
            }
            let formData = new FormData();
            let file = new File([data.image.body], `${data.designField}.png`, {type: 'image/png'});
            if (!DESIGN_IMAGES.includes(data.designField)) {
              formData.append('file', file);
              formData.append('folder', 'print_files');
              formData.append('fileName', `${data.designField}.png`);
              return this.commonService.uploadGenericFile(formData);
            }
            let formField = (data.designField.split(/(?=[A-Z])/).join('_')).toLowerCase();
            formData.append('field', formField);
            formData.append('file', file);
            return this.commonService.uploadFile('school-template-designs', formData, this.design.id, null);
          })
        ).subscribe(design => {
          if (design?.data) {
            this.cacheBrake = new Date().getTime();
          }
          this.loadingDesigns = this.loadingDesigns.filter(design => design !== designField);
        });
      });
    });
    this.loadingDesigns = this.loadingDesigns.filter(design => design !== 'data');
  }

  updateLicensingStatus(event: MatButtonToggleChange): void {
    this.designService.partialEdit({ id: this.design.id, licensingStatus: event.value, lastLicenseAction: new Date() }).subscribe(design => {
      this.design.licensingStatus = design.licensingStatus;
      this.design.lastLicenseAction = design.lastLicenseAction;
      this.designUpdated.emit(design);
    });
  }

  templateSides(designElements: SchoolDesignElements[]): TemplateSide[] {
    if (this.design.elements?.length === 0) {
      return [];
    }
    const templateSideIds = [];
    const sides = [];

    this.design.elements.forEach(e => {
      let side = e.templateSide;
      if (!side) {
        side = {side: 'Front', versionCode: '', id: 0, orderType: 'Front', printLocation: 'Front', mergeLocation: 'Front', required: true, template: 0};
      }
      if (side && !templateSideIds.includes(side.id)) {
        templateSideIds.push(side.id);
        sides.push(side);
      }
    });
    return sides;
  }

  setSelectedSide(side: TemplateSide): void {
    this.selectedSide = side;
  }

  getPrintFileUrl(colorway: string, versionCode: string | undefined): string {
    versionCode = versionCode ? versionCode : '';
    return `https://ipods.s3.amazonaws.com/media/print_files/${this.design.schoolTemplate}_${this.design.id}${colorway}${versionCode}.png?${this.cacheBrake}`;
  };

}
