import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PopoverService } from '../../../components/popover/popover.service';
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import { UserService } from '../../../../app/core/services/api/user.service';
import { map, startWith, switchMap, tap, take } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services/api/common.service';
@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserComponent implements OnInit {

  dropdownOpen: boolean;

  userFullName$ = this.userService.user.pipe(map(user => `${user.firstName} ${user.lastName}`));
  logo$ = this.userService.user.pipe(map(user => user.profilePicture));
  orgName$ = this.userService.user.pipe(map(user => user.school?.fullName));

  constructor(
    private popover: PopoverService,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    public commonService: CommonService
  ) { }

  ngOnInit() {
  }

  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }  
  navigateHome(): void {
    this.userService.navigateHome();
  }
}

