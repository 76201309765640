import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Manufacturer } from '../../models/blank';
import { HttpHelperService } from '../http-helper.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ManufacturerService {
  public manufacturerList$ : Manufacturer[]=null;
  public modelView$ : string=null;
  private endpoint: string = 'manufacturers';

  constructor(private httpHelper: HttpHelperService) { }

  resetState() {
    this.manufacturerList$ = null;
  }

  getAll(): Observable<Manufacturer[]> {
    return this.httpHelper.request<Manufacturer[]>(this.endpoint);
  }

  get(id: number | string): Observable<Manufacturer> {
    return this.httpHelper.request<Manufacturer>(`${this.endpoint}/${id}`);
  }

  create(item: Manufacturer): Observable<Manufacturer> {
    return this.httpHelper.request<Manufacturer>(`${this.endpoint}`, { body: item, method: 'POST' });
  }

  edit(item: Manufacturer): Observable<Manufacturer> {
    return this.httpHelper.request<Manufacturer>(`${this.endpoint}/${item.id}`, { body: item, method: 'PUT' });
  }

  sales(id: number, start: Date, end: Date): Observable<any> {
    let dateParams = new HttpParams({
      fromObject: {
        'start_date': start.toISOString().split('T')[0],
        'end_date': end.toISOString().split('T')[0]
      }
    })
    return this.httpHelper.request<any>(`${this.endpoint}/${id}/sales`, { search: dateParams });
  }


}
