import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { filter, take, delay } from 'rxjs/operators';
import { AuthService } from '../../core/services/api/auth.service';
import { User } from '../../core/models/user';
import { UserService } from '../../core/services/api/user.service';
import { Store } from '@ngrx/store';
import { loadUser, UserState } from 'src/app/ngrx-store/actions/user.actions';
import { CommonService } from 'src/app/core/services/api/common.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public badCredentials = false;
  public registerForm!: FormGroup;

  manualText = '';
  isPageLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    public commonService : CommonService,
    private store : Store<UserState>,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this.isPageLoading = false;
    const storedUsername = localStorage.getItem('username');
    const rememberMe = !!storedUsername;
    this.registerForm = this.formBuilder.group({
      username: [rememberMe ? storedUsername : '', Validators.required],
      password: ['', Validators.required],
      rememberMe,
    });
  }

  onSubmit(): void {
    if (this.registerForm.invalid) {
      return;
    }
    this.isPageLoading = true;
    const username = this.registerForm.value.username;
    this.authService
      .login(username, this.registerForm.value.password)
      .pipe(
        filter((resp) => !!resp),
        take(1)
      )
      .subscribe(
        (logins) => {
          if (Object.keys(logins).indexOf('token') !== -1) {
            this.store.dispatch(loadUser({payload : {user : logins.user,token : logins.token}}));
            this.storeToken(logins.token);
            this.setRememberMe();
            this.navigateLastOrDefault(logins.user);
          } else {
            this.badCredentials = true;
            this.isPageLoading = false;
          }
        },
        (error) => {
          this.isPageLoading = false;
          this.badCredentials = true;
        }
      );
  }

  forgotPassword(): void {
    this.router.navigate(['/password-reset-request']);
  }

  private storeToken(token: string) {
    localStorage.setItem('token', token);
  }

  private setRememberMe() {
    const shouldRemember = this.registerForm.value.rememberMe;
    const userName = this.registerForm.value.username;
    if (shouldRemember) {
      localStorage.setItem('username', userName);
    } else {
      localStorage.removeItem('username');
    }
  }

  private navigateLastOrDefault(user: User) {
    // if user attempted another URL before getting kicked to
    // the login screen, we'll send them there OR if they closed
    // the app, it will navigate them where they were last.
    const attemptedUrl = localStorage.getItem('lastUrl');
    if (attemptedUrl && attemptedUrl !== '/') {
      this.router.navigate([attemptedUrl]);
      localStorage.removeItem('lastUrl');
    } else {
      this.userService.navigateHome();
    }
  }

  moreInfo(): void {
    this.router.navigate(['/user-signup']);;
  }
}
