import { Component, Inject } from '@angular/core';
import { PrintLocation } from '../../models/design';
import { catchError, of } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from '../../services/api/common.service';
import { UserService } from '../../services/api/user.service';
import { PrintLocationService } from '../../services/api/print-location.service';
import { School } from '../../models/school';

@Component({
  selector: 'app-print-location-modal',
  templateUrl: './print-location-modal.component.html',
  styleUrls: ['./print-location-modal.component.scss']
})
export class PrintLocationModalComponent {
  public printLocation: PrintLocation;
  public buildAtLoading = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { printLocation: PrintLocation, school: School },
    public dialogRef: MatDialogRef<PrintLocationModalComponent>,
    public commonService: CommonService,
    public userService: UserService,
    private printLocationService: PrintLocationService
  ) {
    this.printLocation = data.printLocation;
  }

  cleanUpToggle(selectedDesign: PrintLocation): void {
    const buildAt = (selectedDesign.buildAt) ? null : new Date();
    const design: PrintLocation = {
      id: selectedDesign.id,
      buildAt: buildAt
    };
    this.buildAtLoading = true;
    this.printLocationService.partialEdit(design).pipe(
      catchError(() => {
        this.buildAtLoading = false;
        return of(null);
      })
    ).subscribe(design => {
      if (design) {
        selectedDesign.buildAt = buildAt;
        this.buildAtLoading = false;
      }
    })

  }
}
