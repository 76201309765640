import { createAction, props } from '@ngrx/store';
import { CategorySalesReport, SportSalesReport, TotalSalesReport } from 'src/app/core/models/sales';

export interface SchoolSalesState {
    totalSalesReport: TotalSalesReport;
    categorySalesReport:CategorySalesReport[];
    sportSalesReport:SportSalesReport[];
    
}

export const setSchoolSalesState = createAction(
    '[School Sales] Set School Sales Success',
    props<{ payload: SchoolSalesState }>()
);
