import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VerificationEmailResponse } from '../../models/utils';

@Component({
  selector: 'app-verification-response-dialog',
  templateUrl: './verification-response-dialog.component.html',
  styleUrls: ['./verification-response-dialog.component.scss']
})
export class VerificationResponseDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: VerificationEmailResponse,
    private dialogRef: MatDialogRef<VerificationResponseDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close()
  }

}
