import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, catchError, mergeMap, of, tap } from 'rxjs';
import { Blank, Model } from 'src/app/core/models/blank';
import { BlankService } from '../api/blank.service';

@Injectable({
  providedIn: 'root'
})
export class BlankSelectionService {
  private blankService = inject(BlankService);

  private readonly _selectedModelSource = new BehaviorSubject<Model>(null);
  public readonly selectedModel$ = this._selectedModelSource.asObservable();

  private readonly _blanksLoading = new BehaviorSubject<boolean>(false);
  public readonly blanksLoading$ = this._blanksLoading.asObservable();

  public readonly selectedBlanks$: Observable<Blank[]> = this.selectedModel$.pipe(
    tap(() => this._blanksLoading.next(true)),
    mergeMap(model => {
      if (!model) {
        return of([] as Blank[]);
      }
      return this.blankService.modelFilter(model.model)
    }),
    catchError(() => of([])),
    tap(() => this._blanksLoading.next(false))
  )

  setModel(model: Model): void {
    this._selectedModelSource.next(model);
  }






}
