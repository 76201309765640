import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SchoolDocument } from 'src/app/core/models/school';
import { SchoolDocumentsService } from 'src/app/core/services/api/school-documents.service';
import { take } from 'rxjs';
import { PandaDocumentsService } from '../../../services/api/panda-documents.service';
import { PlayerDocument } from '../../../models/school';
import { DocumentsPageService } from '../../documents/documents-page.service';

@Component({
  selector: 'app-player-docs-view',
  templateUrl: './player-docs-view.component.html',
  styleUrls: ['./player-docs-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlayerDocsViewComponent implements OnInit {

  public sessionLoaded: boolean = false;
  public document$ = this.documentsPagesService.getSelectedDocument();

  constructor(
    private sanitizer: DomSanitizer,
    private documentsPagesService: DocumentsPageService,
    private cd: ChangeDetectorRef,
    private schoolDocumentService: SchoolDocumentsService,
    private pandaDocumentsService: PandaDocumentsService
  ) {}

  ngOnInit(): void {
  }

  public pandaUrl(sessionId: string): SafeResourceUrl {

    const url = `https://app.pandadoc.com/s/${sessionId}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  toggleDrawer(event : boolean) {
    this.documentsPagesService.drawerOpen.next(event);
    this.cd.markForCheck();
  }

  createDocument(document: SchoolDocument | PlayerDocument): void {
    this.schoolDocumentService.create(document).subscribe(data => {
      this.documentsPagesService.setSelected(data);
      this.documentsPagesService.updateDocument(data);
    });
  }

  refreshSession(document: SchoolDocument | PlayerDocument): void {
    if (document?.pandaDocument.id) {
      this.pandaDocumentsService.refreshSession(document?.pandaDocument.id).pipe(
        take(1)
      ).subscribe(doc => {
        if (doc.sessionId) {
          this.sessionLoaded = true;
          document.pandaDocument.sessionId = doc.sessionId;
          this.documentsPagesService.updateDocument(document);
        }
      })
    }
  }

}
