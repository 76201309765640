<div class="w-full h-full overflow-hidden flex flex-col">
    <div class="bg-app-bar p-4 border-b flex-none flex justify-between items-center gap-4 h-20">
      <div class="flex">
        <button (click)="toggleDrawer(true)" class="block md:hidden" color="primary" mat-icon-button type="button">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <div class="flex">
        <button [matMenuTriggerFor]="chatMenu" mat-icon-button class="icon-2xl" type="button">
          <mat-icon class="text-secondary">more_vert</mat-icon>
        </button>
      </div>
    </div>
    <div *ngIf="document$ | async as currentDocument; else noDocument" class="h-full">
      <div *ngIf="currentDocument?.pandaDocument && currentDocument?.pandaDocument?.sessionId && sessionLoaded" class="h-full w-full">
        <iframe class="h-full w-full" [src]="pandaUrl(currentDocument.pandaDocument.sessionId)"></iframe>
      </div>
      <div *ngIf="!currentDocument?.pandaDocument && currentDocument?.pandaTemplate" class="grid h-screen place-items-center">
        <button color="primary" mat-flat-button (click)="createDocument(currentDocument)">Create Document</button>
      </div>
      <div *ngIf="currentDocument?.pandaDocument && !currentDocument.pandaDocument?.sessionId" class="grid h-screen place-items-center">
        <div class="grid h-full place-items-center">
          <div class="flex flex-col">
            <h1>Load Document</h1>
            <button color="primary" mat-flat-button (click)="refreshSession(currentDocument)"><mat-icon>refresh</mat-icon></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <mat-menu #chatMenu="matMenu" xPosition="after" yPosition="below">
    <button mat-menu-item>
      <mat-icon>add_circle</mat-icon>
      <span>Create Document</span>
    </button>
  
    <button mat-menu-item>
      <mat-icon>mail</mat-icon>
      <span>Send To Email</span>
    </button>
  </mat-menu>
  
  <ng-template #noDocument>
    <div class="grid h-full place-items-center"><h1>Please Select Document</h1></div>
  </ng-template>
  