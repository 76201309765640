import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { combineLatest, filter, map, startWith, switchMap, tap } from 'rxjs';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { CommonService } from 'src/app/core/services/api/common.service';
import { UserService } from 'src/app/core/services/api/user.service';
import { SchoolTemplateDesign, TemplateSide } from 'src/app/core/models/templates';
import { LicenseCategoryModel, SchoolTemplateDesignsService } from 'src/app/core/services/api/school-template-designs.service';
import { LicenseProductCategoryComponent } from './license-product-category/license-product-category.component';
import { CodeStateService } from 'src/app/core/services/state/code-state.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-template-preview',
  templateUrl: './template-preview.component.html',
  styleUrls: ['./template-preview.component.scss']
})
export class TemplatePreviewComponent implements OnInit {
  Loader: boolean;
  school$ = this.userService.user.pipe(map(user => user.school));
  loadingCategories = false;
  design: SchoolTemplateDesign;
  sides: TemplateSide[];


  @ViewChildren(LicenseProductCategoryComponent) categoryComponents!: LicenseProductCategoryComponent[];
  @ViewChild('templatePdf') templatePdf!: ElementRef;
  private datetime = new Date();
  selectedCategories: LicenseCategoryModel[];
  licensingGroups$ = this.codeStateService.codes$.pipe(
    filter(Boolean),
    map(codes => {
      return codes.filter(c => c.codeType === 'licensorType');
    })
  );
  licensingGroupControl = new FormControl('clcCategory');
  categories$ = this.licensingGroupControl.valueChanges.pipe(
    startWith('clcCategory'),
    tap(() => this.loadingCategories = true),
    switchMap(group => this.stdService.licensingSheet(this.design.id, group)),
    tap(categories => {
      if (categories.length > 0) {
        this.selectedCategories = [categories[0]];
      }
      this.loadingCategories = false
    }),
  );

  constructor(
    private dialogRef: MatDialogRef<TemplatePreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {design: SchoolTemplateDesign, sides: TemplateSide[]},
    public commonService: CommonService,
    private userService: UserService,
    private stdService: SchoolTemplateDesignsService,
    private codeStateService: CodeStateService
  ) { 
    this.design = data.design;
    this.sides = data.sides;
    this.codeStateService.init();
  }

  ngOnInit(): void {}

  

  public generatePDF(): void {
    this.Loader = true;
    let PDF = new jsPDF('p', 'mm', 'a4');
    const fileName = `${this.design.id}_licensing_sheet.pdf`;
    this.categoryComponents.forEach((categoryComponent, idx) => {
      html2canvas(categoryComponent.elementRef.nativeElement, { scale: 0.75, useCORS: true }).then((canvas) => {
        const imageGeneratedFromTemplate = canvas.toDataURL('image/png');
        const fileWidth = 200;
        let pageHeight = 290;
        let fileHeight = (canvas.height * fileWidth) / canvas.width;
        let fileHeightLeft = fileHeight;
        let position = 10;
        PDF.addImage(imageGeneratedFromTemplate, 'PNG', 0, position, fileWidth, fileHeight, '', 'FAST');
        fileHeightLeft -= pageHeight;
        position += fileHeightLeft - fileHeight;
        fileHeightLeft -= pageHeight;
        PDF.html(categoryComponent.elementRef.nativeElement.innerHTML);
        if (idx === this.categoryComponents.length - 1) {
          PDF.addPage();
          PDF.save(fileName);
          this.Loader = false;
        } else {
          PDF.addPage();
        }
      });
    });
    this.dialogRef.close();
  }

  mockupImage(category: LicenseCategoryModel): string {
    return 'https://ipods.s3.amazonaws.com/media/mockup_files/' + this.design.id + '_' + category.blankId + '.jpg';
  }

}
