import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import { UserService } from '../../../../../app/core/services/api/user.service';
import { map, startWith, switchMap, tap, take } from 'rxjs/operators';
import { AuthService } from '../../../../../app/core/services/api/auth.service';
import { TotalDue } from 'src/app/core/models/payouts';
import { UserPayoutsService } from 'src/app/core/services/api/user-payouts.service';
import { ToolbarComponent } from '../../toolbar.component';
import { CommonService } from 'src/app/core/services/api/common.service';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: string;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
})
export class ToolbarUserDropdownComponent implements OnInit {
  userFullName$ = this.userService.user.pipe(map(user => `${user.firstName} ${user.lastName}`));
  logo$ = this.userService.user.pipe(map(user => user.profilePicture));
  orgName$ = this.userService.user.pipe(map(user => user.school?.fullName));
  loader = false;
  hidePayouts = false;
  totalDueData: TotalDue;
  menuItems: MenuItem[] = [
    {
      id: '1',
      icon: 'mat:account_circle',
      label: 'My Account',
      description: 'User Information',
      colorClass: 'text-teal',
      route: '/user/settings'
    },
  ];

  statuses: OnlineStatus[] = [
    {
      id: 'online',
      label: 'Online',
      icon: 'mat:check_circle',
      colorClass: 'text-green'
    },
    {
      id: 'away',
      label: 'Away',
      icon: 'mat:access_time',
      colorClass: 'text-orange'
    },
    {
      id: 'dnd',
      label: 'Do not disturb',
      icon: 'mat:do_not_disturb',
      colorClass: 'text-red'
    },
    {
      id: 'offline',
      label: 'Offline',
      icon: 'mat:offline_bolt',
      colorClass: 'text-gray'
    }
  ];

  activeStatus: OnlineStatus = this.statuses[0];

  trackById = trackById;

  constructor(
    private cd: ChangeDetectorRef,
    private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
    private userService: UserService,
    private authService: AuthService,
    private userPayoutService: UserPayoutsService,
    private disableDarkmode: ToolbarComponent,
    public commonService: CommonService
  ) {
    this.userService.user.pipe(take(1)).subscribe(user => {
      if (this.userService.payoutsDisabled() || this.userService.isArtwork() || this.userService.isManufacturer() || this.userService.isOrg()){
        this.hidePayouts = true;
      } else {
        this.hidePayouts = false;
        this.menuItems.push({
          id: '2',
          icon: 'mat:payments',
          label: 'Payouts',
          description: 'View Payment and Trigger Payouts',
          colorClass: 'text-green',
          route: '/user/payouts'
        })
      }
    })
  }

  ngOnInit() {
    this.totalDueData = this.userPayoutService.totalDue$;
    if (!this.totalDueData && this.userService?.currentUser?.group != "ORG") {
      this.getTotalDue();
    }
  }

  getTotalDue() {
    this.loader = true
    this.userPayoutService.TotalDue().subscribe((data: TotalDue) => {
      this.totalDueData = data;
      // this.userPayoutService.totalDue$ = data;
      this.loader = false;
    }, (err) => {
      this.loader = false;
    })
  }

  setStatus(status: OnlineStatus) {
    this.activeStatus = status;
    this.cd.markForCheck();
  }

  close() {
    this.popoverRef.close();
  }

  logout() {
    this.disableDarkmode.disableDarkMode();
    this.popoverRef.close();
    this.authService.logout();
  }
}
