<div [blockLoader]="this.loader" class="loaderDiv" *ngIf="this.loader"></div>
<div @stagger class="flex flex-col h-full w-full px-2 md:px-4 py-4">
  <!-- top -->
  <div class="rounded-2xl overflow-hidden border" *ngIf="model$ | async as selectedModel else noModelSelection">
    <div class="flex justify-center p-5 bg-[#f5f5f8] dark-bg" >
      <div class="w-full bg-gradient-to-r rounded-lg border bg-[#fff] dark-bg">
        <div class="flex m-2 place-items-center gap-2">
          <div class="rounded-full overflow-hidden shadow-md">
            <img class="object-cover w-8" [src]="selectedModel.manufacturer?.icon"
              (error)="commonService.imageErrorHandle($event)"
              alt="{{selectedModel.manufacturer?.name}}" />
          </div>
          <h1 class="text-gray-900 font-normal font-os text-lg color-white">
            {{selectedModel?.description}}
          </h1>
        </div>
        <div class="flex flex-col justfiy-end  px-4 py-2 text-gray-900 font-quick color-white">
          <p class="font-thin text-xs">
            <span class="text-sm leading-tight dark:text-white/80">Model :
              <span
                class="sm:ml-1">{{selectedModel.model}}</span>
            </span>
          </p>
          <h4 class="uppercase tracking-wider text-xs color-white">
            {{selectedModel?.productDetails}}
          </h4>
        </div>
      </div>
    </div>
    <div class="text-center font-quick p-2 md:p-4">
      <div *ngIf="false;else products"
        class="w-1/2 mx-auto my-12 text-center text-primary rounded py-6">
        <mat-icon class="text-2xl">filter_alt</mat-icon>
        <div class="text-2xl font-bold">No Products !!</div>
      </div>
    </div>
  </div>
  <!-- top -->
</div>

<ng-template #noModelSelection>
  <div class="text-center">
    <mat-icon>laundry</mat-icon>
    <h3 class="mt-2 text-sm font-semibold text-gray-900">No Product Selected</h3>
    <p class="mt-1 text-sm text-gray-500">Get started by searching for a product, or selecting from our highlight products.</p>
  </div>
</ng-template>

<ng-template #products>
  <div class="flex flex-col" [blockLoader]="loading$ | async">
    <div class="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-4 w-full">
      <app-blank class="card-product-padding" *ngFor="let blank of blanks$ | async"
        (selectedProduct)="selectedProductFun($event)" (productDeleted)="deleteProduct(blank)" [blank]="blank"
        [blockLoader]="blank.id == blankLoader"></app-blank>
    </div>
  </div>
</ng-template>
