import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { map, take } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/core/components/confirm-dialog/confirm-dialog.component';
import { Player, School } from 'src/app/core/models/school';
import { User } from 'src/app/core/models/user';
import { PlayersService } from 'src/app/core/services/api/players.service';
import { SchoolService } from 'src/app/core/services/api/school.service';

@Component({
  selector: 'app-user-opt-out',
  templateUrl: './user-opt-out.component.html',
  styleUrls: ['./user-opt-out.component.scss']
})
export class UserOptOutComponent implements OnInit {
  jwtToken = "";

  fb = inject(FormBuilder);
  route = inject(ActivatedRoute);
  router = inject(Router);
  schoolService = inject(SchoolService);
  dialog = inject(MatDialog);
  playerService = inject(PlayersService);

  school: School;

  errorInOptOut = false;
  isPageLoading = false;

  control = new FormControl('');
  selectedPlayer: Player;
  documentLoaded = false;
  optOutComplete = false;
  userEmail = "";


  filteredPlayers$ = this.control.valueChanges.pipe(
    map(value => {
      if (typeof value !== 'string' || value === '' || value.length < 2) {
        return [];
      }
      return this._filter(value);
    })
  )

  ngOnInit(): void {
    const token = this.route.snapshot.queryParamMap.get('token');
    if (!token) {
      this.router.navigate(['/login']);
      return
    }
    this.isPageLoading = true;
    this.jwtToken = token;
    this.schoolService.loadOptOutForm(token).pipe(
      take(1),
    ).subscribe(data => {
      if (!data) {
        this.router.navigate(['/login']);
        return
      }
      this.school = data;
      this.isPageLoading = false;
    })
  }

  private _filter(value: string): User[] {
    const filterValue = value.toLowerCase();
    return this.school.allPlayers.filter(p => this.displayedPlayerText(p).replace(",", "").toLowerCase().includes(filterValue));
  }

  updateUser(event: MatAutocompleteSelectedEvent): void {
    this.selectedPlayer = event.option.value;
  }

  displayedPlayerText(player: Player): string {
    if (!player) {
      return '';
    }
    return `${player.lastName}, ${player.firstName}`;
  }

  playerOptOut(): void {
    const confirmDialog = {
      content: `Opt out and disable player store for ${this.selectedPlayer.firstName} ${this.selectedPlayer.lastName}`
    }
    this.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog
    }).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.isPageLoading = true;
        this.playerService.playerOptOut(this.jwtToken, this.selectedPlayer.id).subscribe(player => {
          if (player) {
            this.optOutComplete = true;
            this.isPageLoading = false;
          } else {
            this.errorInOptOut = false;
          }
        })
      }
    })
  }

}
