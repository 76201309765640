import { createFeatureSelector, createReducer, on ,Action, createSelector} from '@ngrx/store';
import { SchoolSalesState,setSchoolSalesState } from '../actions/school-sales.actions';

export const initialState: SchoolSalesState = { 
  totalSalesReport:null,
  categorySalesReport:null,
  sportSalesReport:null,
}

export const schoolSalesReducer = createReducer(
  initialState,
  on(setSchoolSalesState, (state, { payload }) => {
    return payload;
  })
);

export const SchoolSalesReducer = (state: any, action: Action) => {
    return schoolSalesReducer(state, action);
}

export const SchoolSales = createFeatureSelector<SchoolSalesState>('schoolSales');

export const getSchoolSalesState = createSelector(
  SchoolSales,
  (state: SchoolSalesState) => state,
)
