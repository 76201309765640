import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SchoolDocument } from 'src/app/core/models/school';
import { DocumentsPageService } from '../documents-page.service';
import { take, tap } from 'rxjs';
import { PandaDocumentsService } from '../../../services/api/panda-documents.service';
import { PlayerDocument } from '../../../models/school';
import { MatDialog } from '@angular/material/dialog';
import { DocumentSignersModalComponent } from '../document-signers-modal/document-signers-modal.component';
import { DocumentAdditionalSigner } from '../../../models/document';
import { NotifierService } from 'src/app/core/services/notifier.services';

@Component({
  selector: 'app-document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentViewComponent implements OnInit {
  documentLoader:boolean=false
  public sessionLoaded: boolean = false;

  public document$ = this.documentsPagesService.getSelectedDocument().pipe(tap(data => (data)));

  constructor(
    private sanitizer: DomSanitizer,
    private documentsPagesService: DocumentsPageService,
    private cd: ChangeDetectorRef,
    private pandaDocumentsService: PandaDocumentsService,
    private dialog: MatDialog,
    private notifierService: NotifierService
  ) {}

  ngOnInit(): void {
  }

  public pandaUrl(sessionId: string): SafeResourceUrl {

    const url = `https://app.pandadoc.com/s/${sessionId}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  openDrawer() {
    this.documentsPagesService.drawerOpen.next(true);
    this.cd.markForCheck();
  }

  closeDrawer() {
    this.documentsPagesService.drawerOpen.next(false);
    this.cd.markForCheck();
  }

  createDocument(document: SchoolDocument | PlayerDocument): void {
    if (document.pandaTemplate.additionalSigners && document.pandaTemplate.additionalSigners.length > 0) {
      this.dialog.open(DocumentSignersModalComponent, {
        data: document.pandaTemplate
      }).afterClosed().subscribe((signers: DocumentAdditionalSigner[]) => {
        if (signers) {
          document.pandaDocument = {additionalSigners: signers};
          this.documentsPagesService.createDocument(document);
        }
      });
    } else {
      this.documentsPagesService.createDocument(document);
    }

  }

  refreshSession(document: SchoolDocument | PlayerDocument): void {
    this.documentLoader=true;
    if (document?.pandaDocument.id) {
      this.pandaDocumentsService.refreshSession(document?.pandaDocument.id).pipe(
        take(1)
      ).subscribe(doc => {
        this.documentLoader=false;
        if (doc.sessionId) {
          this.sessionLoaded = true;
          document.pandaDocument.sessionId = doc.sessionId;
          this.documentsPagesService.updateDocument(document);
          this.documentsPagesService.setSelected(document);
          this.cd.markForCheck();
        }
      })
    }
  }

  sendDocument(document: SchoolDocument | PlayerDocument): void {
    if (document?.pandaDocument?.id) {
      this.pandaDocumentsService.send(document.pandaDocument.id).subscribe(() => {
        this.notifierService.alert({type: "success", message: "Docuemnt Sent to All Recipients! Please Check your email!"})
      })
    }
  }

}
