import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Blank, BlankModel, BlankPaginate } from '../../models/blank';
import { HttpHelperService } from '../http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class BlankService {
  blankList$: Blank[] = null;
  blankPaginateList$: BlankPaginate = null;
  private endpoint: string = 'blanks';

  constructor(private httpHelper: HttpHelperService) { }
  resetState() {
    this.blankPaginateList$ = this.blankList$ = null;
  }

  getAll(filter?: any): Observable<Blank[]> {
    return this.httpHelper.request<Blank[]>(this.endpoint, { search: filter });
  }

  getAllByFilter(filter?: any): Observable<BlankPaginate> {
    return this.httpHelper.request<BlankPaginate>(this.endpoint, { search: filter });
  }

  get(id: number): Observable<Blank> {
    return this.httpHelper.request<Blank>(`${this.endpoint}/${id}`);
  }

  getWithModel(id: number): Observable<BlankModel> {
    return this.httpHelper.request<BlankModel>(`${this.endpoint}/${id}/model`);
  }

  filter(item): Observable<Blank> {
    const params = new HttpParams().set('color', item);
    return this.httpHelper.request<Blank>(`${this.endpoint}`, { search: params, method: 'GET' });
  }

  create(item: Blank): Observable<Blank> {
    return this.httpHelper.request<Blank>(`${this.endpoint}`, { body: item, method: 'POST' });
  }

  edit(item: Blank): Observable<Blank> {
    return this.httpHelper.request<Blank>(`${this.endpoint}/${item.id}`, { body: item, method: 'PUT' });
  }

  modelFilter(model: string): Observable<Blank[]> {
    let search = {
      model: model
    }
    return this.httpHelper.request<Blank[]>(`${this.endpoint}/model_filter`, { search, method: 'GET' });
  }


}
