
import { createFeatureSelector, createReducer, on ,Action, createSelector} from '@ngrx/store';
import { setSchoolHomeState, SchoolHomeState } from '../actions/school-home.actions';

export const initialState: SchoolHomeState = { 
  homes:null,
  salesOverview:null,
  schoolRecentSales:null,
  schoolSalesByDay:null
}

export const schoolHomeReducer = createReducer(
  initialState,
  on(setSchoolHomeState, (state, { payload }) => {
    return payload;
  })
);

export const SchoolHomeReducer = (state: any, action: Action) => {
    return schoolHomeReducer(state, action);
}

export const SchoolHome = createFeatureSelector<SchoolHomeState>('schoolHomes');

export const getSchoolHomeState = createSelector(
  SchoolHome,
  (state: SchoolHomeState) => state,
)
