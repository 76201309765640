import { Action, createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { loadUser, UserState } from "../actions/user.actions";

export const initialState: UserState = { user: {},token : null }

export const userReducers = createReducer(
    initialState,
    on(loadUser, (state, { payload }) => {
        return payload;
    })
)

export const userReducer = (state: any, action: Action) => {
    return userReducers(state, action);
}

export const getUserState = createFeatureSelector<UserState>('userState');
export const getUser = createSelector(getUserState, (state: UserState) => state);
export const getSelectUser = createSelector(getUserState, (state: UserState) => state.user);
export const getSelectToken = createSelector(getUserState, (state: UserState) => state.token);