<h1 mat-dialog-title>
  Confirm Action
</h1>

<div mat-dialog-content>
  <p> Are you sure to <span>{{this.defaults?.deleteOrUnassign==='deleteSchool'?'delete ':'unassign '}}</span>  <span class="schoolName">{{this.defaults?.school?.fullName}}</span> ?</p>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="onConfirm()">Ok</button>
  <button mat-button (click)="onDismiss()">Cancel</button>
</div>
