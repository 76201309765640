import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap, Subject, Subscription } from 'rxjs';
import { User } from '../../models/user';
import { UserService } from '../../services/api/user.service';
import { AuthService } from '../../services/api/auth.service';
import { AlertService } from '../../services/api/alert.service';
import { BreakpointObserver,Breakpoints, BreakpointState } from '@angular/cdk/layout';


@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit, OnDestroy {
  opened:boolean=true;
  @Output() toggleSideNavEvent: EventEmitter <any> = new EventEmitter();
  @Input() navItems: any[] = [];
  @Input() sidenav: any;
  @Input() globaltrend: any;
  userName_:any;
  alertsArray:any=[]

  public activatedRoute = '';
  private routeSub: Subscription;
  private connServSub!: Subscription;
  private unsubscribe = new Subject<void>();
  public isConnected = navigator.onLine;
  private isUserAdmin = false;
  private userSub!: Subscription;
  public user!: User;
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private userService: UserService,
    private authService: AuthService,
    private alertService:AlertService,
    public breakpointObserver: BreakpointObserver
  ) {
    // this.getNavItems();
    this.routeSub = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map(r => {
          if (r.firstChild) {
            r = r.firstChild;
          }
          return r;
        }),
        filter(r => r !== null),
        filter(r => r.outlet !== null),
        filter(r => r.outlet === 'primary'),
        mergeMap(r => r.data)
      )
      .subscribe((event) => {
        this.activatedRoute = event['label'];
      });
  }

  ngOnInit() {
    this.breakpointObserver
    .observe([Breakpoints.Small, Breakpoints.HandsetPortrait])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.opened = false;
      }else{
        this.opened = true;
      }
    });


    this.userSub = this.userService.user.subscribe(user => {
      this.user = user;
    })

    this.userName_=localStorage.getItem('username')
    this.alertsArray=this.alertService.Alerts;
  }

  ngOnDestroy(): void {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    if (this.connServSub) {
      this.connServSub.unsubscribe();
    }
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }

  logout(): void {
    this.authService.logout();
  }

  toggleSideNav(opened:any){
    this.toggleSideNavEvent.emit(opened)

  }

  notificationButton(item:any){
   item.seen=true;
  }

}
