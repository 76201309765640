import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/services/api/common.service';
import { UserService } from 'src/app/core/services/api/user.service';

@Component({
  selector: 'app-profile-pic-upload',
  templateUrl: './profile-pic-upload.component.html',
  styleUrls: ['./profile-pic-upload.component.scss']
})
export class ProfilePicUploadComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<ProfilePicUploadComponent>, private commonService: CommonService, public userService: UserService) { }
  subscription: Subscription

  ngOnInit(): void {
    this.subscription = this.commonService.closeDialog.subscribe(closeDialog => {
      if (closeDialog) {
        this.dialogRef.close('image uploaded')
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()

  }

}
