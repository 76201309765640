import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ApexOptions } from '../../chart/chart.component';
import { defaultChartOptions } from '../../../utils/default-chart-options';
import { createDateArray } from '../../../utils/create-date-array';
import { ExportService } from 'src/app/core/services/api/export.service';
import { MonthlySales, SalesByDay, SalesOverview } from 'src/app/core/models/home';

@Component({
  selector: 'vex-widget-large-goal-chart',
  templateUrl: './widget-large-goal-chart.component.html',
  styleUrls:['./widget-large-goal-chart.component.scss']
})
export class WidgetLargeGoalChartComponent implements OnInit {
  monthlySalesCsvheaders: string[] = ['day', 'count', 'sales'];
  monthlySalesNewCsvheaders: string[] = ['DAY', 'Count', 'Sales'];
  @ViewChild('salesOverview') salesOverview!: ElementRef;
  @Input() total: string;
  @Input() salesOverviewData: SalesOverview;
  @Input() salesByDay: SalesByDay[]
  @Input() series: any;
  salesPer: number;
  @Input() options: ApexOptions = defaultChartOptions({
    grid: {
      show: true,
      strokeDashArray: 3,
      padding: {
        left: 16
      }
    },
    chart: {
      type: 'line',
      height: "384",
      sparkline: {
        enabled: false
      },
      zoom: {
        enabled: false
      }
    },
    stroke: {
      width: 4
    },
    labels: createDateArray(12),
    xaxis: {
      type: 'datetime',
      labels: {
        show: true
      }
    },
    yaxis: {
      labels: {
        show: true
      }
    }
  });

  constructor(private exportService: ExportService) { }

  ngOnInit() {
    this.calculateSalesPct();
  }

  calculateSalesPct() {
    if (this.salesOverviewData) {
      let a = (this.salesOverviewData.totalMonthSales - (this.salesOverviewData.lastMonthSale)) / (this.salesOverviewData.lastMonthSale)
      this.salesPer = parseFloat((a * 100).toFixed(2))
    }
  }

  salesOverviewExportCSV() {
    if (this.salesByDay) {
      this.exportService.downloadCsvFile(this.salesByDay, this.monthlySalesCsvheaders, 'SalesByDay.csv', this.monthlySalesNewCsvheaders)
    }
  }

  salesOverviewExportPDF() {
    this.exportService.generatePDF(this.salesOverview.nativeElement, 'salesOverview')
  }

}
