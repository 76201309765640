
<div class="flex flex-col sm:flex-row gap-5 mb-5 px-auto">
  <div class="flex-1" @fadeInUp  *ngFor="let item of configItems"  (click)="navigate(item?.navPath)">
    <div class="card h-full relative flex flex-col min-w-0 break-words shadow-soft-xl rounded-2xl bg-clip-border cursor-pointer hover:shadow" [ngClass]="{'border border-red-300': !item.complete}">
      <div class="flex-auto p-4">
        <div class="flex flex-row -mx-3">
          <div class="flex-1 px-3">
            <div>
              <p class="mb-0 font-sans font-semibold leading-normal text-sm">{{item.heading}}</p>
              <h5 class="mb-0 ">
                <span *ngIf="item?.complete" class="leading-normal text-sm font-bold text-primary"> Completed</span><br>
                <span *ngIf="item?.text">{{item.text}}</span>
              </h5>
            </div>
          </div>
          <div class="pr-4">
            <div class="flex items-center justify-center ml-auto w-12 h-12 text-center rounded-lg border border-gray-300">
              <mat-icon class="icon-xl text-primary">{{item.icon}}</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
