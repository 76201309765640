<div class="card overflow-hidden w-full flex flex-col rounded-2xl">
  <div class="bg-app-bar td-bg-app-bar-mobile px-3 h-16 border-b sticky left-0 flex items-center">
    <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">{{ tableHeader }}</h2>
    <div *ngIf="searchEnabled"
      class="bg-foreground td-roster-search-bar-hide-mobile rounded-full px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
      <mat-icon class="icon-sm text-secondary">search</mat-icon>
      <input [formControl]="searchCtrl" class="px-4 py-2 border-0 outline-none w-full bg-transparent"
        placeholder="Search..." type="search" />
    </div>
    <span class="flex-1"></span>
    <button mat-icon-button type="button" [matMenuTriggerFor]="menu">
      <mat-icon class="text-secondary">cloud_download</mat-icon>
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
      <button mat-menu-item (click)="exportCsv()" [disabled]="this.dataSource?.data?.length===0">Export
        CSV</button>
      <button mat-menu-item (click)="exportPDF()" [disabled]="this.dataSource?.data?.length===0">Export
        PDF</button>
    </mat-menu>
  </div>
  <div class="w-1/2 mx-auto my-20 text-center" *ngIf="this.dataSource?.data?.length===0;else tableTemplate">
    <h2 class=" text-sm sm:text-md md:text-lg lg:text-lg text-gray-400">No Data Found</h2>
  </div>
  <ng-template #tableTemplate>
    <div class="overflow-auto" #table>
      <table [dataSource]="dataSource" class="w-full" mat-table matSort>
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
        <ng-container matColumnDef="checkbox">
          <th *matHeaderCellDef mat-header-cell>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          color="primary">
            </mat-checkbox>
          </th>
          <td *matCellDef="let row" class="w-4" mat-cell>
            <mat-checkbox (change)="$event ? toggleSelect(row) : null"
                          (click)="$event.stopPropagation()"
                          [checked]="selection.isSelected(row)"
                          color="primary">
            </mat-checkbox>
          </td>
        </ng-container>

              <!-- Model Properties Column -->
        <ng-container *ngFor="let column of columns">
          <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{column.label=="DATE"?(row[column.property]|date):row[column.property] }}</td>
          </ng-container>
          <ng-container *ngIf="column.type === 'currency'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] | currency }}</td>
          </ng-container>
          <ng-container *ngIf="column.type === 'date'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] | date }}</td>
          </ng-container>
          <ng-container *ngIf="column.type === 'badge'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <div *ngIf="row[column.property] === 'ready'" class="w-3 h-3 rounded-full bg-green cursor-pointer"
                matTooltip="Ready to ship"></div>
              <div *ngIf="row[column.property] === 'pending'" class="w-3 h-3 rounded-full bg-orange cursor-pointer"
                matTooltip="Pending Payment"></div>
              <div *ngIf="row[column.property] === 'warn'" class="w-3 h-3 rounded-full bg-red cursor-pointer"
                matTooltip="Missing Payment"></div>
            </td>
          </ng-container>
          <ng-container *ngIf="column.type === 'image'" [matColumnDef]="column.property">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>
              <img [src]="row[column.property]" (error)="commonService.imageErrorHandle($event)"
                class="avatar h-8 w-8 align-middle object-contain">
            </td>
          </ng-container>
        </ng-container>
        <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: visibleColumns;" mat-row></tr>
      </table>
      <mat-paginator [pageSize]="pageSize" class="paginator" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
    </div>
  </ng-template>
</div>
