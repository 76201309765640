<div class="my-3" >
  <dl *ngIf="statItems && statItems.length > 0 else noData"
    class="grid grid-cols-1 divide-y overflow-hidden rounded-lg md:grid-cols-3 md:divide-y-2 md:divide-x-2">
    <div class="relative overflow-hidden rounded-lg bg-white px-4 pt-5 sm:px-6 sm:pt-6" *ngFor="let item of statItems">
      <dt>
        <div class="absolute rounded-full w-12 h-12 flex items-center justify-center text-primary bg-primary/10">
          <mat-icon>{{item.icon}}</mat-icon>
        </div>
        <p class="ml-16 truncate text-lg font-medium text-primary">{{item.header}}</p>
        <p class="ml-16 truncate text-sm font-medium text-primary/2">{{item.subHeader}}</p>
      </dt>
      <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
        <p class="text-2xl font-semibold text-primary">{{item.stat | currency}}</p>
        <p class="ml-2 flex items-baseline text-sm font-semibold"
          [class.text-green-600]="item?.increase && item?.increase > 0"
          [class.text-red-600]="item?.increase && item?.increase <= 0">
          {{item.increase}}
        </p>
        <div class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6" *ngIf="item.viewAll">
          <div class="text-sm">
            <a href="#" class="font-medium text-primary/10 hover:text-primay"> View all<span class="sr-only"> Total Subscribers stats</span></a>
          </div>
        </div>
      </dd>
    </div>
  </dl>
</div>

<ng-template #noData>
  <div class="text-center">
    <div class="rounded-full w-12 h-12 flex items-center justify-center text-primary bg-primary/10 mx-auto">
      <mat-icon>close</mat-icon>
    </div>
    <h3 class="mt-2 text-sm font-semibold text-gray-900">No Data</h3>
    <p class="mt-1 text-sm text-gray-500">There is no data for your selected time range</p>
  </div>
</ng-template>
