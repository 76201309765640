import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Code, DesignCodes } from '../../models/code';
import { HttpHelperService } from '../http-helper.service';
@Injectable()
export class CodeService {
  codeList$ : Code[]=null;
  public designCodes$: BehaviorSubject<DesignCodes> = new BehaviorSubject<DesignCodes>(null);
  private endpoint: string = 'codes';
  constructor(private httpHelper: HttpHelperService) { }

  resetState(){
    this.codeList$=null;
  }

  getAll(filter?:any): Observable<Code[]> {
    return this.httpHelper.request<Code[]>(this.endpoint,{search : filter});
  }

  get(id: number): Observable<Code> {
    return this.httpHelper.request<Code>(`${this.endpoint}/${id}`);
  }

  filter(item: Code): Observable<Code[]> {
    return this.httpHelper.request<Code[]>(`${this.endpoint}`, {search: item, method: 'GET'});
  }

  create(item: Code): Observable<Code> {
    return this.httpHelper.request<Code>(`${this.endpoint}`, {body: item, method: 'POST'});
  }

  edit(item: Code): Observable<Code> {
    return this.httpHelper.request<Code>(`${this.endpoint}/${item.id}`, {body: item, method: 'PUT'});
  }

  getDesignCodes(){
    if(!this.codeList$){
      this.getAll().subscribe(res=>{
        this.codeList$ = res;
        this.setDesignCodes();
      });
    }else{
      this.setDesignCodes();
    }
  }

  setDesignCodes(){
    return this.designCodes$.next({
      school: this.codeList$.filter(res=> res.codeType == 'dataSchoolAttribute') || [],
      player: this.codeList$.filter(res=> res.codeType == 'dataPlayerAttribute') || [],
      sport: this.codeList$.filter(res=> res.codeType == 'dataSportAttribute') || [],
      arch: this.codeList$.filter(res=> res.codeType == 'dataArchAttribute') || [],
      playerNumber: this.codeList$.filter(res=> res.codeType == 'dataPlayerNumberAttribute') || []
    })
  }
}
