import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit, Input, ViewChild, AfterViewInit, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Payout, Payouts, PayoutsFilter, PayoutsPaginatedData } from 'src/app/core/models/payouts';
import { UserPayoutsService } from 'src/app/core/services/api/user-payouts.service';
import { UsersService } from 'src/app/core/services/api/users.service';
import { PayoutDisclosureComponent } from 'src/app/pages/user/user-payouts/payout-disclosure/payout-disclosure.component';
import { PaginatedRequest } from '../../models/utils';

@Component({
  selector: 'app-payout-table',
  templateUrl: './payout-table.component.html',
  styleUrls: ['./payout-table.component.scss']
})
export class PayoutTableComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() players: boolean = false;
  @Input() refresh: boolean = false;
  @Input() filter: Partial<Payout> = {};
  @Input() payoutsDisplayedColumns: string[] = ['submittedAt', 'payoutType', 'receiver', 'amount', 'itemStatus', 'actions'];
  @Output() sendGraphData = new EventEmitter();

  payoutsLoader: boolean = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  payoutsElementData!: Payout[];
  payoutData: Payout[];
  payoutsDataSource = new MatTableDataSource<Payout>(this.payoutsElementData);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  userPayoutsSeries: ApexAxisChartSeries = [
    {
      name: 'Paypal',
      data: []
    },
    {
      name: 'Venmo',
      data: []
    },
    {
      name: 'Direct',
      data: []
    }
  ];
  constructor(
    private userPayoutService: UserPayoutsService,
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    public usersService: UsersService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.payouts();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.refresh){
      this.pageSize = 10;
      this.currentPage = 0;
      this.payouts();
    }
  }

  ngAfterViewInit() {
    this.payoutsDataSource.paginator = this.paginator;
    this.payoutsDataSource.sort = this.sort;
  }

  payouts() {
    this.payoutsLoader = true;
    let filter : PaginatedRequest<Payout>={
      ...this.filter,
      'page': this.currentPage + 1,
      'page_size': this.pageSize,
    };
    this.userPayoutService.paginated(filter).subscribe(data => {
      if (data.results.length > 0) {
        this.payoutData = data.results;
        this.payoutsDataSource.data = data.results
        this.userPayoutsSeries[0].data = data.results.filter(guide => (guide.payoutType === 'Paypal')).map((d) => ({ x: new Date(d.submittedAt).getTime(), y: d.amount }));
        this.userPayoutsSeries[1].data = data.results.filter(guide => guide.payoutType === 'Venmo').map((d) => ({ x: new Date(d.submittedAt).getTime(), y: d.amount }));
        this.userPayoutsSeries[2].data = data.results.filter(guide => guide.payoutType === 'Direct').map((d) => ({ x: new Date(d.submittedAt).getTime(), y: d.amount }));
        this.sendGraphData.emit(this.userPayoutsSeries);
        setTimeout(() => {
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = data.count;
        })
      }
      this.payoutsLoader = false;
    })
  }

  getStatusStyles(element): string[] {
    if (element.itemStatus === "Failed") {
      return ["text-red", "bg-red-light"]
    } else if (element.itemStatus === "Success") {
      return ["text-green", "bg-green-light"]
    } else if (element.itemStatus === "Unclaimed") {
      return ["text-orange", "bg-orange-light"]
    } else if (element.itemStatus === "Pending") {
      return ["text-blue", "bg-blue-light"]
    } else {
      return []
    }
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  showDisclosure(payout: Payouts) {
    this.dialog.open(PayoutDisclosureComponent, {
      data: payout,
      width: '100%',
      panelClass: ['custom-modalbox', 'disclosure'],
    }).afterClosed().subscribe(data => { });
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.payouts();
  }

}
