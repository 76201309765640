import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentAdditionalSigner } from '../../models/document';
import { PlayerDocument } from '../../models/school';
import { HttpHelperService } from '../http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class PlayerDocumentsService {

  private endpoint: string = 'player-documents';

  constructor(private httpHelper: HttpHelperService) { }

  getAll(): Observable<PlayerDocument[]> {
    return this.httpHelper.request<PlayerDocument[]>(this.endpoint);
  }

  get(id: number): Observable<PlayerDocument> {
    return this.httpHelper.request<PlayerDocument>(`${this.endpoint}/${id}`);
  }

  filter(item: PlayerDocument): Observable<PlayerDocument> {
    return this.httpHelper.request<PlayerDocument>(`${this.endpoint}`, {params: item, method: 'GET'});
  }

  create(templateId: number, additionalSigners: DocumentAdditionalSigner[]): Observable<PlayerDocument> {
    const body = {templateId, additionalSigners};
    return this.httpHelper.request<PlayerDocument>(`${this.endpoint}`, {body, method: 'POST'});
  }

  edit(item: PlayerDocument): Observable<PlayerDocument> {
    return this.httpHelper.request<PlayerDocument>(`${this.endpoint}/${item.id}`, {body: item, method: 'PUT'});
  }

  createFromVerification(token: string, templateId: number, additionalSigners: DocumentAdditionalSigner[]): Observable<PlayerDocument> {
    const body = {token, templateId, additionalSigners};
    return this.httpHelper.request<PlayerDocument>(`create-document-verification`, {body, method: 'POST'});
  }
}
