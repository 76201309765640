<div class="">
  <button mat-icon-button type="button" [matMenuTriggerFor]="menu" (click)="showNotifications()">
    <mat-icon color="primary" >notifications_active</mat-icon>
  </button>
  <mat-menu #menu="matMenu" class="td-notification-popup dropdown" id="notification-toolbar">
    <div class="td-main-top-bar">
      <div class="td-header">
        <div class="text-heading">
          <h3>Notifications</h3>
          <div class="dropdown-subheading text-white" *ngIf="notifications.length>0">You have {{unseenNotificationCount? unseenNotificationCount : 'no'}} new notifications.</div>
        </div>
        <div (click)="markAllAsRead()" class="icon-right font-xs mark-read">
          <mat-icon title="Mark all as read" class="cursor-pointer">mark_email_read</mat-icon>
        </div>
      </div>
      <div class="td-body-main">
        <div class="td-inner-box">
          <div class="text-lable no-notification" *ngIf="notifications.length<1">
            <div class="notification-label">
              <p>No Notifications</p>
            </div>
          </div>
          <div class="mt-2" [ngClass]="{'spinner': loader}">
            <div class="notification px-3 py-4 border-b-2 hover:bg-primary/10" *ngFor="let row of notifications; let i=index;">
              <div class="font-bold notification-day" *ngIf="i == todayIndex">Today</div>
              <div class="font-bold notification-day" *ngIf="i == yesterdayIndex">Yesterday</div>
              <div class="font-bold notification-day" *ngIf="i == olderIndex">Older</div>
              <div *ngIf="!row?.seenAt" class="float-right mr-1" (click)="markAsRead(row?.id)"><div title="Mark as read" class="dot-icon"></div></div>
              <div class="notification-label text-sm  " [ngClass]="{'font-bold' : !row?.seenAt}">{{row?.noteData}}</div>
              <div class="date">{{row?.createdAt | date : 'MMM dd yyyy, hh:mm a'}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-menu>

</div>


