import { Action, createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { getRecentSales, getSalesByDay, getSalesOverview, getTotalSales, PlayerHomeState, setPlayerHome } from "../actions/player-home.actions";

export const initialState: PlayerHomeState = { 
    totalSales : null,
    recentSales : [],
    salesByDay : [],
    salesOverview : null
}

export const playerHomeReducers = createReducer(
    initialState,
    
    on(setPlayerHome, (state, { payload }) => {
        return payload; 
    }),
    on(getTotalSales, (state) => { 
        return { totalSales: state.totalSales} 
    }),
    on(getRecentSales, (state) => { 
        return { recentSales: state.recentSales} 
    }),
    on(getSalesByDay, (state) => { 
        return { salesByDay: state.salesByDay} 
    }),
    on(getSalesOverview, (state) => { 
        return { salesOverview: state.salesOverview} 
    })
)

export const playerHomeReducer = (state: any, action: Action) => {
    return playerHomeReducers(state, action);
}

export const getPlayerHomeState = createFeatureSelector<PlayerHomeState>('playerHomeState');
export const getPlayerHome = createSelector(getPlayerHomeState, (state: PlayerHomeState) => state);
export const getPlayerHomeTotalSales = createSelector(getPlayerHomeState, (state: PlayerHomeState) => state.totalSales);
export const getPlayerHomeRecentSales = createSelector(getPlayerHomeState, (state: PlayerHomeState) => state.recentSales);
export const getPlayerHomeSalesByDay = createSelector(getPlayerHomeState, (state: PlayerHomeState) => state.salesByDay);
export const getPlayerHomeSalesOverview = createSelector(getPlayerHomeState, (state: PlayerHomeState) => state.salesOverview);
