<mat-card class="p-2 mat-elevation-z shadow-none border border-gray-200" [ngClass]="blank.selected?'itemSelected':''" @fadeInRight >
  <mat-card-content class="text-primary">
    <div class="flex-col justify-between">
      <div class="text-xs font-semibold text-left">#{{blank.pms}}</div>
      <div class="flex justify-center items-center py-2 ">
        <img mat-card-md-image class="object-cover grow-0"  [src]="blank.frontImageThumbnail" (error)="commonService.imageErrorHandle($event)" alt="No Image found">
      </div>
      <div class="flex flex-col items-center gap-1">
        <span class="font-bold">{{blank.color | titlecase}}</span>
        <button *ngIf="!blank.selected" mat-raised-button color="primary" [disabled]="blank.selected" (click)="selectProduct(blank)">{{blank.selected?"Selected":"Select"}}
          <mat-icon *ngIf="blank.selected" class="flex-none">done</mat-icon>
        </button>
        <button *ngIf="blank.selected" mat-raised-button color="primary" [disabled]="!blank.selected" (click)="deleteProduct(blank)">{{blank.selected?"Selected":"Select"}}
          <mat-icon *ngIf="blank.selected" class="flex-none">done</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
