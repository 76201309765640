<div @stagger class="max-w-sm w-full sm:px-0 py-4">
  <div>
    <mat-form-field appearance="outline" class="w-full mt-2 pb-0 custom-select">
      <mat-label appRemoveAriaOwns>Filter Product Models By</mat-label>
      <mat-select #selected_notselected [(value)]="filterBy" (selectionChange)="filterByUpdate(selected_notselected)">
        <mat-option value="department">
          Department
        </mat-option>
        <mat-option value="manufacturer">
          Manufacturer
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-accordion class="custom-pad">
    <ng-container *ngFor="let item of items$ | async; first as isFirst">
      <mat-expansion-panel [expanded]="isFirst" class="mat-elevation-z0 border-b border-gray-200">
        <mat-expansion-panel-header *ngIf="item.type === 'subheading'" @fadeInRight class="text-md font-medium px-4">
          {{ item.label }}
        </mat-expansion-panel-header>
        <p *ngFor="let i of item.items" class="hover:bg-primary/10 rounded-full leftbar leading-3" [ngClass]="{ 'bg-primary/10 text-primary': isActive(i.id) }" @fadeInRight>
          <a matRipple (click)="setFilter(i.object)" *ngIf="i.type === 'link'" class="text-sm flex items-center transition duration-200 ease-out cursor-pointer">
            <mat-icon color="primary" class="ltr:mr-2 rtl:ml-1 blue">check_circle</mat-icon>

            <span class="text-primary">{{ i.label }}</span>
          </a>
        </p>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>
