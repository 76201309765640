<div class="text-center py-24 header h-full">
  <div class="container">
    <div @fadeInUp class="bg-foreground py-3 md:py-16 px-gutter footer">
      <h2 class="display-1 mt-0 mb-4 text-center">Still have questions?</h2>
      <h3 class="subheading-2 text-hint text-center max-w-lg mx-auto m-0">
        We want to get your merchandise in front of your fans as soon as possible!
        Please reach out to us for any questions you might have about getting started!
      </h3>
      <div class="col-12 mb-5"> 
        <div id="hubspotForm"></div>
      </div> 
      
    </div>
  </div>
</div>
