import { Component, OnInit, inject } from '@angular/core';
import { School } from '../../models/school';
import { MatDialogRef } from '@angular/material/dialog';
import { ModelStateService } from '../../services/state/model-state.service';
import { FormControl } from '@angular/forms';
import { map, startWith, combineLatest, filter, take, debounceTime, distinctUntilChanged, mergeMap, tap, of } from 'rxjs';
import { Model } from '../../models/blank';
import { SelectedProductsService } from 'src/app/pages/school/school-products/selected-products.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModelService } from '../../services/api/model.service';
import { BlankSelectionService } from '../../services/state/blank-selection.service';

@UntilDestroy()
@Component({
  selector: 'app-blank-selection-modal',
  templateUrl: './blank-selection-modal.component.html',
  styleUrls: ['./blank-selection-modal.component.scss'],

})
export class BlankSelectionModalComponent implements OnInit {
  dialogRef = inject(MatDialogRef<BlankSelectionModalComponent>);
  modelStateService = inject(ModelStateService);
  modelService = inject(ModelService);
  blankSelectionService = inject(BlankSelectionService);
  loadingModels = false;

  control = new FormControl('');

  filteredModels$ = this.control.valueChanges.pipe(
    mergeMap(value => {
      this.loadingModels = true
      if (typeof value !== 'string' || value === '' || value.length < 3) {
        return of([]);
      }
      return this.modelService.search(value);
    }),
    tap(() => this.loadingModels = false)
  )



  menuOpen = true;
  searchProducts: any;
  School: School;
  resetInput: any;


  ngOnInit(): void {
    this.modelStateService.init();
  }

  updateModel(event: MatAutocompleteSelectedEvent): void {
    this.blankSelectionService.setModel(event.option.value);
  }

  closeMenu(): void {

  }

  displayedModelText(model: Model): string {
    if (!model) {
      return '';
    }
    return `${model.model} ${model.manufacturer?.name} ${model.description}`;
  }
}
