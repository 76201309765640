import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-block',
  template: `
  <div class="loader-container abs-center">  
  <div class="olympicloader">
    <i class="loader-circle second"></i>
  </div>
</div>`
})
export class LoadingBlockComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
