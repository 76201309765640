import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SchoolLogo } from '../../models/school';
import { HttpHelperService } from '../http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class SchoolLogoService {

  private endpoint: string = 'school-logos';

  constructor(private httpHelper: HttpHelperService) { }

  getAll(filter: SchoolLogo = {}): Observable<SchoolLogo[]> {
    let search = {}
    if (filter) {
      search = filter
    }
    return this.httpHelper.request<SchoolLogo[]>(this.endpoint, {search});
  }

  get(id: number): Observable<SchoolLogo> {
    return this.httpHelper.request<SchoolLogo>(`${this.endpoint}/${id}`);
  }

  filter(item: SchoolLogo): Observable<SchoolLogo[]> {
    return this.httpHelper.request<SchoolLogo[]>(`${this.endpoint}`, {params: item, method: 'GET'});
  }

  needsBuild(): Observable<SchoolLogo[]> {
    const params: any = {
      build: true
    };
    return this.httpHelper.request<SchoolLogo[]>(`${this.endpoint}`, {search: params, method: 'GET'});
  }

  create(item: SchoolLogo): Observable<SchoolLogo> {
    return this.httpHelper.request<SchoolLogo>(`${this.endpoint}`, {body: item, method: 'POST'});
  }

  edit(item: SchoolLogo): Observable<SchoolLogo> {
    return this.httpHelper.request<SchoolLogo>(`${this.endpoint}/${item.id}`, {body: item, method: 'PUT'});
  }

  partialEdit(item: SchoolLogo): Observable<SchoolLogo> {
    return this.httpHelper.request<SchoolLogo>(`${this.endpoint}/${item.id}`, {body: item, method: 'PATCH'});
  }

  downloadAll(schoolLogoId: number): Observable<any> {
    return this.httpHelper.request<any>(`${this.endpoint}/${schoolLogoId}/download_all`, {method: 'GET', responseType: "arrayBuffer"});
  }

  delete(schoolLogoId: number): Observable<SchoolLogo> {
    return this.httpHelper.request<SchoolLogo>(`${this.endpoint}/${schoolLogoId}`, {method: "DELETE"});
  }
}
