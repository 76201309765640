<div class="flex h-full max-h-full flex-col justify-center sm:py-12 sm:px-6 lg:px-8 bg-slate-100 overflow-y-auto">
  <div class="sm:mx-auto sm:w-full sm:max-w-md mb-3">
    <img class="mx-auto h-10 w-auto" src="assets/img/icon.png" alt="Athletic Solutions">
    <h2 class="mt-2 text-center text-2xl font-bold leading-9 tracking-tight text-primary">Create your account</h2>
  </div>
  <div @stagger class="sm:container h-5/6 sm:max-w-5xl max-w-full mx-auto">
    <div @fadeInUp >
      <mat-stepper [linear]="true" #stepper>
        <ng-template matStepperIcon="edit">
          <mat-icon>done_all</mat-icon>
        </ng-template>

        <ng-template matStepperIcon="done">
          <mat-icon>done_all</mat-icon>
        </ng-template>

        <mat-step [stepControl]="accountFormGroup">
          <form [formGroup]="accountFormGroup">
            <ng-template matStepLabel><span class="hidden sm:block">User Details</span></ng-template>
            <div class="block sm:hidden border-b border-gray-200 py-2">
              <div class="title text-xl font-semibold">User Details</div>
            </div>
            <div class="grid grid-cols-12 gap-x-2 gap-y-3 my-3">
              <div class="col-span-full sm:col-span-6">
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                <div class="mr-4">
                  <input id="email" formControlName="email" autocomplete="email" name="email" type="text" required [disabled]="true"
                    class="appearance-none block px-2 w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                </div>
                <p *ngIf="accountFormGroup.controls.email.errors?.required"
                class="mt-2 text-sm text-red-600" id="email-error">This Field is required</p>
                <p *ngIf="accountFormGroup.controls.email.errors?.email" class="mt-2 text-sm text-red-600" id="email-error">Must be a valid email address</p>
              </div>
              <div class="col-span-full sm:col-span-6">
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Phone</label>
                <div class="mr-4">
                  <input id="phone" formControlName="phone" autocomplete="phone" name="phone" type="text"
                    class="appearance-none block px-2 w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                </div>
              </div>
              <div class="col-span-6">
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">First Name</label>
                <div class="mr-4">
                  <input id="first-name" formControlName="firstName" autocomplete="first-name" name="firstName" type="text" required
                    class="appearance-none block px-2 w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                </div>
                <p *ngIf="accountFormGroup.controls.firstName.errors?.required" class="mt-2 text-sm text-red-600" id="email-error">This
                  Field is required</p>
              </div>
              <div class="col-span-6">
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Last Name</label>
                <div class="mr-4">
                  <input id="last-name" formControlName="lastName" autocomplete="last-name" name="lastName" type="text" required
                    class="appearance-none block px-2 w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                </div>
                <p *ngIf="accountFormGroup.controls.lastName.errors?.required" class="mt-2 text-sm text-red-600" id="email-error">This
                  Field is required</p>
              </div>
              <div class="pt-4 pb-2 col-span-full border-b border-gray-200">
                <div class="title text-xl font-semibold">Social Handles</div>
              </div>
              <div class="col-span-full sm:col-span-4">
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Instagram</label>
                <div class="mr-4 flex">
                  <span
                    class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm py-1.5">@</span>
                  <input id="last-name" formControlName="instagramHandle" name="instagramHandle" type="text"
                    class="appearance-none block px-2 w-full rounded-none  rounded-r-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                </div>
              </div>
              <div class="col-span-full sm:col-span-4">
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Twitter</label>
                <div class="mr-4 flex">
                  <span
                    class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm py-1.5">@</span>
                  <input id="last-name"  formControlName="twitterHandle" name="twitterHandle" type="text"
                    class="appearance-none block px-2 w-full rounded-none  rounded-r-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                </div>
              </div>
              <div class="col-span-full sm:col-span-4">
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">TikTok</label>
                <div class="mr-4 flex">
                  <span
                    class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm py-1.5">@</span>
                  <input id="last-name" formControlName="tiktokHandle" name="tiktokHandle" type="text"
                    class="appearance-none block px-2 w-full rounded-none  rounded-r-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                </div>
              </div>
            </div>
            <div class="actions flex items-center justify-end gap-2">
              <button (click)="stepper.reset()" [disabled]="accountFormGroup.pristine" color="primary" mat-button
                type="button">Reset
              </button>
              <button [disabled]="accountFormGroup.invalid" color="primary" mat-raised-button matStepperNext>
                Next
              </button>
            </div>
          </form>
        </mat-step>
        <mat-step *ngIf="requiredDocs">
          <div class="p-1 flex flex-col gap-2">
            <ng-template matStepLabel><span class="hidden sm:block">Create Agreement(s)</span></ng-template>
            <div class="title m-2 flex-1">Before we can create your free store, we will need to collect your signature on
              our agreement.</div>
            <div
              class="card relative hover:shadow-lg trans-shadow rounded-xl overflow-hidden justify-center flex flex-col">
              <div class="px-gutter py-4 grid grid-cols-1 sm:grid-cols-2 gap-2">
                <div class="py-3 flex items-center" *ngFor="let document of requiredDocs">
                  <div
                    class="w-16 h-16 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                    <mat-icon class="icon-sm" svgIcon="mat:description"></mat-icon>
                  </div>

                  <div>
                    <p class="m-0 title">{{ document?.pandaTemplate?.name }}</p>
                    <p class="m-0 caption text-gray-500">
                      Required: True
                    </p>
                    <p class="m-0 caption text-gray-500">
                      <button *ngIf="!document?.pandaDocument?.createdAt" class="rounded-full max-w-full w-200 mt-4"
                        (click)="createDocument(document)" color="primary" mat-raised-button type="button"
                        [class.spinner]="documentLoading">Create Documents</button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="actions flex items-center justify-end gap-2 m-1">
            <button color="primary" mat-button matStepperPrevious type="button">Previous</button>
            <button #documentNext color="primary" mat-raised-button matStepperNext>
              {{ documentNextText }}
            </button>
          </div>
        </mat-step>
        <mat-step [stepControl]="passwordFormGroup">
          <form [formGroup]="passwordFormGroup">
            <ng-template matStepLabel><span class="hidden sm:block">Select a Password</span></ng-template>
            <div class="block sm:hidden border-b border-gray-200 py-2">
              <div class="title text-xl font-semibold">Select a Password</div>
            </div>
            <div class="mt-4 flex flex-col gap-2">
              <div>
                <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Password</label>
                <div class="flex rounded-md shadow-sm">
                  <div class="relative flex flex-grow items-stretch focus-within:z-10">
                    <input name="password" id="password" matInput required
                      [type]="passwordInputType" formControlName="password"
                      class="appearance-none block w-full rounded-none rounded-l-md border-0 py-1.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                      placeholder="Password">
                  </div>
                  <button type="button"
                    tabindex="-1"
                    (click)="toggleVisibility()"
                    class="relative -ml-px inline-flex items-center gap-x-0.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    <mat-icon class="w-8 px-1 text-gray-400" *ngIf="passwordInputType === 'password'">visibility</mat-icon>
                    <mat-icon class="w-8 px-1 text-gray-400" *ngIf="passwordInputType === 'text'">visibility_off</mat-icon>
                  </button>
                </div>
                <p *ngIf="passwordFormGroup.controls.password.errors?.required" class="mt-2 text-sm text-red-600" id="email-error">This
                  Field is required</p>
                <p *ngIf="passwordFormGroup.controls.password.errors?.minLength" class="mt-2 text-sm text-red-600" id="email-error">Password must be at least 8 characters long</p>
              </div>
              <div>
                <label for="passwordConfirm" class="block text-sm font-medium leading-6 text-gray-900">Confirm Password</label>
                <div class="flex rounded-md shadow-sm">
                  <div class="relative flex flex-grow items-stretch focus-within:z-10">
                    <input name="passwordConfirm" id="passwordConfirm" matInput required
                      [type]="passwordInputType" formControlName="passwordConfirm"
                      class="appearance-none block w-full rounded-none rounded-l-md border-0 py-1.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                      placeholder="Confirm Password">
                  </div>
                  <button type="button" (click)="toggleVisibility()"
                    tabindex="-1"
                    class="relative -ml-px inline-flex items-center gap-x-0.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    <mat-icon class="w-8 px-1 text-gray-400" *ngIf="passwordInputType === 'password'">visibility</mat-icon>
                    <mat-icon class="w-8 px-1 text-gray-400" *ngIf="passwordInputType === 'text'">visibility_off</mat-icon>
                  </button>
                </div>
                <p *ngIf="passwordFormGroup.controls.passwordConfirm.errors?.confirmedValidator" class="mt-2 text-sm text-red-600" id="email-error">Passwords do not match</p>
              </div>
            </div>

            <div class="actions flex items-center justify-end gap-2 mt-3">
              <button color="primary" mat-button matStepperPrevious type="button">Previous</button>
              <button [disabled]="passwordFormGroup.invalid" color="primary" mat-raised-button matStepperNext>
                Next
              </button>
            </div>
          </form>
        </mat-step>

        <mat-step [stepControl]="confirmFormGroup">
          <form [formGroup]="confirmFormGroup">
            <ng-template matStepLabel><span class="hidden sm:block">Confirm Creation</span></ng-template>

            <div class="title m-0">You're almost there!</div>
            <div class="subheading-1">To finish registration, accept our terms of service and click on "Create Account".
            </div>
            <a href="https://ipods.s3.us-east-2.amazonaws.com/media/legal/athletic_solutions_terms.pdf" target="_blank"
              class="text-blue-500 under cursor-pointer">View Terms Here</a>

            <div class="mt-4 flex flex-col gap-2">
              <mat-checkbox class="checkbox" formControlName="terms" color="primary">
                I agree to the terms of service. *
              </mat-checkbox>
            </div>

            <div class="actions flex items-center justify-end gap-2">
              <button color="primary" mat-button matStepperPrevious type="button">Previous</button>
              <button (click)="submit()" [disabled]="confirmFormGroup.invalid" color="primary" mat-raised-button
                matStepperNext [class.spinner]="isLoading">
                Create Account
              </button>
            </div>
          </form>

        </mat-step>
      </mat-stepper>
    </div>
  </div>
</div>

