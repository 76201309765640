import { AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable, of } from "rxjs";
import { debounceTime, distinctUntilChanged, first, map, switchMap } from "rxjs/operators";
import { HandleService } from '../services/api/handle.service';

export function handleUniqueValidator(initialValue: string, handleService: HandleService, prefix: string = ''): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (initialValue === prefix + control.value) {
      return of(null);
    }
    return control.valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        map(value => prefix + value),
        switchMap(value => {
          console.log(value);
          return handleService.available(value);
        }),
        map(available => (available ? null : {'handleExists': true})),
        first()
      );
  }
}
