<div mat-dialog-title>E-Mail Verification Status</div>
<div mat-dialog-content>
  <div class="flow-root">
    <ul role="list" class="-mb-2 divide-y divide-slate-500">
      <li *ngFor="let item of this.data.Success">
        <div class="relative pb-2">
          <span class="absolute top-4 left-4 -ml-px h-full w-0.5" aria-hidden="true"></span>
          <div class="relative flex space-x-3">
            <div>
              <span class="h-6 w-6 rounded-full flex items-center justify-center">
                <mat-icon color="primary" class="pt-1">done</mat-icon>
              </span>
            </div>
            <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
              <div>
                <p class="text-sm text-gray-500">{{item?.firstName}} {{item?.lastName}} <span class="font-medium text-gray-900">{{item?.email}}</span></p>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li *ngFor="let item of this.data.Failure">
        <div class="relative pb-2">
          <span class="absolute top-4 left-4 -ml-px h-full w-0.5" aria-hidden="true"></span>
          <div class="relative flex space-x-3">
            <div>
              <span class="h-6 w-6 rounded-full flex items-center justify-center">
                <mat-icon color="warn" class="pt-1">close</mat-icon>
              </span>
            </div>
            <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
              <div>
                <p class="text-sm text-gray-500">{{item?.firstName}} {{item?.lastName}} <span class="font-medium text-gray-900">{{item?.email}}</span></p>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close color="warn" type="button">Close</button>
</div>
