<div class="flex flex-col h-full">
  <div class="flex-0 flex items-center" mat-dialog-title>
    <h2 class="text-base md:text-2xl m-0 flex-auto">Address</h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-divider class="text-border"></mat-divider>
  <mat-dialog-content class="flex-1 flex flex-col py-2 overflow-y">
    <form [formGroup]="form">
      <div class="grid grid-cols-1 gap-y-2 sm:grid-cols-2 sm:gap-x-4">
        <div>
          <label for="first-name" class="block text-md py-2 pl-2 text-black font-medium text-gray-700">First name</label>
          <div class="mt-1">
            <input type="text" id="first-name" name="first-name" autocomplete="given-name" formControlName="firstName"
              class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-md py-2 pl-2 text-black">
          </div>
        </div>

        <div>
          <label for="last-name" class="block text-md py-2 pl-2 text-black font-medium text-gray-700">Last name</label>
          <div class="mt-1">
            <input type="text" id="last-name" name="last-name" autocomplete="family-name" formControlName="lastName"
              class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-md py-2 pl-2 text-black">
          </div>
        </div>

        <div class="sm:col-span-2">
          <label for="company" class="block text-md py-2 pl-2 text-black font-medium text-gray-700">Company</label>
          <div class="mt-1">
            <input type="text" name="company" id="company" formControlName="company"
              class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-md py-2 pl-2 text-black">
          </div>
        </div>

        <div class="sm:col-span-2">
          <label for="address" class="block text-md py-2 pl-2 text-black font-medium text-gray-700">Address</label>
          <div class="mt-1">
            <input type="text" name="address" id="address" autocomplete="street-address" formControlName="address1"
              class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-md py-2 pl-2 text-black">
          </div>
        </div>

        <div class="sm:col-span-2">
          <label for="apartment" class="block text-md py-2 pl-2 text-black font-medium text-gray-700">Apartment, suite, etc.</label>
          <div class="mt-1">
            <input type="text" name="apartment" id="apartment" formControlName="address2"
              class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-md py-2 pl-2 text-black">
          </div>
        </div>

        <div>
          <label for="city" class="block text-md py-2 pl-2 text-black font-medium text-gray-700">City</label>
          <div class="mt-1">
            <input type="text" name="city" id="city" autocomplete="address-level2" formControlName="city"
              class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-md py-2 pl-2 text-black">
          </div>
        </div>

        <div>
          <label for="country" class="block text-md py-2 pl-2 text-black font-medium text-gray-700">Country</label>
          <div class="mt-1">
            <select id="country" name="country" autocomplete="country-name" formControlName="countryCode"
              class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-md py-2 pl-2 text-black">
              <option value="US">United States</option>
              <option value="CA">Canada</option>
              <option value="MX">Mexico</option>
            </select>
          </div>
        </div>

        <div>
          <label for="region" class="block text-md py-2 pl-2 text-black font-medium text-gray-700">State / Province</label>
          <div class="mt-1">
            <select id="region" name="region" autocomplete="address-level1" formControlName="provinceCode"
              class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-md py-2 pl-2 text-black">
              <option [value]="state.key" *ngFor="let state of states | keyvalue">{{state.value}}</option>
            </select>
            <!-- <input type="text" name="region" id="region" autocomplete="address-level1" formControlName="province"
              class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-md py-2 pl-2 text-black"> -->
          </div>
        </div>

        <div>
          <label for="postal-code" class="block text-md py-2 pl-2 text-black font-medium text-gray-700">Postal code</label>
          <div class="mt-1">
            <input type="text" name="postal-code" id="postal-code" autocomplete="postal-code" formControlName="zip"
              class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-md py-2 pl-2 text-black">
          </div>
        </div>

        <div class="sm:col-span-2">
          <label for="phone" class="block text-md py-2 pl-2 text-black font-medium text-gray-700">Phone</label>
          <div class="mt-1">
            <input type="text" name="phone" id="phone" autocomplete="tel" formControlName="phone"
              class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-md py-2 pl-2 text-black">
          </div>
        </div>
        <div class="sm:col-span-2">
          <label for="email" class="block text-md py-2 pl-2 text-black font-medium text-gray-700">Email Address</label>
          <div class="mt-1">
            <input type="text" name="email" id="email" autocomplete="email" formControlName="email"
              class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-md py-2 pl-2 text-black">
          </div>
        </div>
      </div>
    </form>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Cancel</button>
    <button color="primary" mat-flat-button (click)="submit()" [disabled]="!form.valid">Submit</button>
  </mat-dialog-actions>
</div>
