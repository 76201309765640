<form [formGroup]="form">
  <div class="flex items-center" mat-dialog-title>
    <h2 *ngIf="form.get('nickname').value" class="text-base md:text-2xl m-0 flex-auto">{{ form.get('nickname').value }}</h2>
    <h2 *ngIf="!form.get('nickname').value" class="text-base md:text-2xl m-0 flex-auto">New Sport</h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider class="text-border"></mat-divider>

  <mat-dialog-content class="flex flex-col py-2">
    <div class="flex flex-col sm:flex-row">
      <mat-form-field class="sm:mt-1 flex-auto">
        <mat-label appRemoveAriaOwns>Nickname</mat-label>
        <input formControlName="nickname" matInput oninput="this.value = this.value.toUpperCase()">
      </mat-form-field>
      <mat-form-field class="sm:mt-1 sm:ml-6 flex-auto">
        <mat-label appRemoveAriaOwns>Gender</mat-label>
        <mat-select formControlName="gender">
          <mat-option value="B">Boys</mat-option>
          <mat-option value="G">Girls</mat-option>
          <mat-option value="M">Mens</mat-option>
          <mat-option value="W">Women</mat-option>
          <mat-option value="U">Both</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="flex flex-col sm:flex-row">
      <mat-form-field class="sm:mt-1 flex-auto">
        <mat-label appRemoveAriaOwns>Gender Display Name</mat-label>
        <input formControlName="genderDisplayName" matInput oninput="this.value = this.value.toUpperCase()">
      </mat-form-field>
      <mat-form-field class="sm:mt-1 sm:ml-6 flex-auto">
        <mat-label appRemoveAriaOwns>Sport</mat-label>
        <mat-select formControlName="sport">
          <mat-option *ngFor="let sport of sports" [value]="sport.id">{{sport.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="updateHandle else handleDisplay">
      <div class="flex flex-col sm:flex-row items-center" *ngIf="isUpdateMode()">
        <mat-form-field  class="w-full">
          <mat-label appRemoveAriaOwns>Handle</mat-label>
          <span matPrefix class="px-2 text-sm">{{schoolHandle}}- &nbsp;</span>
          <input matInput class="form-control" [formControl]="handleControl">
          <mat-hint>Page handle of the team (i.e shop.athsolutions.net/pages/handle)</mat-hint>
          <mat-error *ngIf="handleControl.errors?.handleExists">This handle is already in use</mat-error>
        </mat-form-field>
        <button [buttonLoader]="savingHandle" mat-icon-button aria-label="Save Handle" [disabled]="!handleControl.valid" (click)="saveHandle(handleControl.value)">
          <mat-icon>save</mat-icon>
        </button>
      </div>
    </div>
    <div class="flex flex-col sm:flex-row">
      <mat-slide-toggle matInput
        class="sm:mt-1 flex-auto"
        color="primary"
        name="useNicknameOnProducts"
        formControlName="useNicknameOnProducts">Use Nickname On Products
      </mat-slide-toggle>
    </div>
    <div class="flex flex-col sm:flex-row">
      <mat-slide-toggle matInput
        class="sm:mt-1 flex-auto"
        color="primary"
        name="noStore"
        formControlName="noStore">No Store for Team
      </mat-slide-toggle>
    </div>
    <div class="flex flex-col sm:flex-row">
      <mat-slide-toggle matInput
        class="sm:mt-1 flex-auto"
        color="primary"
        name="noRoster"
        formControlName="noRoster">No Roster
      </mat-slide-toggle>
    </div>
    <div class="flex flex-col sm:flex-row">
      <mat-slide-toggle matInput
        class="sm:mt-1 flex-auto"
        color="primary"
        name="imageOnlyInStore"
        formControlName="imageOnlyInStore">Remove nickname in store tile
      </mat-slide-toggle>
    </div>
    <ng-container *ngIf="userService.isAdmin()">
      <mat-form-field class="sm:mt-1 sm:ml-6 flex-auto">
        <mat-label appRemoveAriaOwns>Document Template</mat-label>
        <mat-select formControlName="requiredPlayerTemplate">
          <mat-option *ngFor="let template of templates$ | async" [value]="template.id">{{template.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="sm:mt-1 sm:ml-6 flex-auto">
        <mat-label appRemoveAriaOwns>License</mat-label>
        <mat-select formControlName="license">
          <mat-option *ngFor="let license of licenses$ | async" [value]="license.id">{{license.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <mat-form-field class="sm:mt-1 flex-auto">
      <mat-label appRemoveAriaOwns>Sort Order</mat-label>
      <input formControlName="sortOrder" matInput type="number">
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Cancel</button>
    <button (click)="save()" *ngIf="isCreateMode()" color="primary" mat-flat-button type="submit" [disabled]="!this.form.valid" [buttonLoader]="saving">Create Sport</button>
    <button (click)="save()" *ngIf="isUpdateMode()" color="primary" mat-flat-button type="submit" [disabled]="!this.form.valid" [buttonLoader]="saving">Update Sport</button>
  </mat-dialog-actions>
</form>


<ng-template #handleDisplay>
  <div class="flex flex-row justify-between">
    <span>{{defaults.handleString}}</span>
    <button mat-icon-button aria-label="Save Handle" [disabled]="!handleControl.valid" (click)="updateHandle = true">
      <mat-icon>edit</mat-icon>
    </button>
  </div>
</ng-template>