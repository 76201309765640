import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { School } from 'src/app/core/models/school';
import { CommonService } from 'src/app/core/services/api/common.service';

@Component({
  selector: 'app-school-image-upload',
  templateUrl: './school-image-upload.component.html',
  styleUrls: ['./school-image-upload.component.scss']
})
export class SchoolImageUploadComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public school: School,
    private dialogRef: MatDialogRef<SchoolImageUploadComponent>,private commonService : CommonService) { }
    subscription:Subscription

  ngOnInit(): void {
    this.subscription=this.commonService.closeDialog.subscribe(closeDialog=>{
      if(closeDialog){
        this.dialogRef.close()
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()

  }

}
