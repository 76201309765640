import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TableColumn } from '../../../interfaces/table-column.interface';
import { ExportService } from 'src/app/core/services/api/export.service';
import { CommonService } from '../../../../app/core/services/api/common.service';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'vex-widget-table',
  templateUrl: './widget-table.component.html',
  styleUrls: ['./widget-table.component.scss']
})
export class WidgetTableComponent<T> implements OnInit, OnChanges, AfterViewInit {

  @Input() RecentSales: any[];
  @Input() data: any[];
  @Input() columns: TableColumn<T>[];
  @Input() pageSize = 15;
  @Input() tableHeader: string = "";
  @Input() pageSizeOptions: number[] = [5, 10, 15,20];
  @Input() selectionEmitter: BehaviorSubject<T[]>;
  @Input() searchEnabled: boolean = false;
  @Input() searchFields: string[];
  @ViewChild('table') table!: ElementRef;
  selection = new SelectionModel<T>(true, []);

  visibleColumns: Array<keyof T | string>;
  dataSource = new MatTableDataSource<T>();
  private paginator: MatPaginator;
  private sort: MatSort;
  
  searchCtrl = new FormControl<string>('');
  
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  constructor(
    private exportService: ExportService,
    public commonService: CommonService
  ) { }

  ngOnInit() { 
    this.searchCtrl.valueChanges.pipe(
      untilDestroyed(this),
    ).subscribe((value) => {
      this.dataSource.data = this.filterData(value, this.data);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.columns) {
      this.visibleColumns = this.columns.map(column => column.property);
    }

    if (changes.data) {
      this.dataSource.data = this.data;
    }
  }

  ngAfterViewInit() {

  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  exportPDF() {
    this.exportService.generatePDF(this.table.nativeElement, this.tableHeader)
  }

  exportCsv() {

    if (this.data) {
      const newHeaders = [];
      const headers = [];
      if (this.columns) {
        this.columns.forEach(c => {
          newHeaders.push(c.label);
          headers.push(c.property);
        })
      }
      this.exportService.downloadCsvFile(this.data, headers, `${this.tableHeader}.csv`, newHeaders);
    }
  }

  toggleSelect(row: T): void {
    this.selection.toggle(row);
    if (this.selectionEmitter) {
      this.selectionEmitter.next(this.selection.selected);
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
      if (this.selectionEmitter) {
        this.selectionEmitter.next(this.selection.selected);
      }
  }

  filterData(searchValue: string, data: T[]): T[] {
    if (!searchValue) {
      return data;
    }
    searchValue = searchValue.toLowerCase();
    return data.filter(item => {
      let found = false;
      const fields = this.searchFields || this.columns.map(c => c.property);
      fields.forEach(field => {
        if (item[field] && item[field].toString().toLowerCase().includes(searchValue)) {
          found = true;
        }
      });
      return found;
    });
  }
}
