import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/api/auth.service';
import { take, catchError } from 'rxjs/operators';
import { User } from 'src/app/core/models/user';
import { ValuesMatchValidator } from 'src/app/core/validators/values-match.validator';
import { NotifierService } from '../../core/services/notifier.services';
import { AlertNotification } from 'src/app/core/models/utils';
import { of } from 'rxjs';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  public resetPasswordFormGroup: UntypedFormGroup;
  public user!: User
  private jwtToken!: string;
  public visible = false;
  public badToken: boolean = false;
  public passwordInputType = 'password';
  public confirmPasswordInputType = 'password';

  public isPageLoading: boolean = false;



  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private notifierService: NotifierService,
    private cd: ChangeDetectorRef,
    private notifier: NotifierService
  ) {
    this.resetPasswordFormGroup = this.fb.group(
      {
        password: [
          null,
          Validators.compose(
            [
              Validators.required,
              Validators.minLength(8)
            ]
          )
        ],
        passwordConfirm: [null, Validators.required]
      }, {
        validators: ValuesMatchValidator("password", "passwordConfirm")
      }
    );
  }

  ngOnInit(): void {
    const token = this.route.snapshot.queryParamMap.get('token');
    if (!token) {
      this.router.navigate(['/login']);
      return
    }
    this.jwtToken = token;
    this.authService.getUserFromJwt(token).pipe(
      take(1),
      catchError(data => {
        this.badToken = true;
        this.notifier.alert({ message: "Bad Token, Please request new Reset Password e-mail", type: 'error', duration: 5000})
        return of(null)
      })
    ).subscribe(data => {
      if (data?.user) {
        this.user = data.user;
      } else {
        this.badToken = true;
        this.router.navigate(['/login']);
      }
    })
  }

  onSubmit(): void {
    if (this.resetPasswordFormGroup.valid) {
      this.isPageLoading = true;
      this.authService.passwordReset(this.user.id, this.jwtToken, this.resetPasswordFormGroup.value.password).pipe(take(1)).subscribe(data => {
        this.isPageLoading = false;
        if (data) {
          const notification: AlertNotification = {
            message: "Password has been Reset!",
            type: "success"
          }
          this.notifierService.alert(notification);
          this.router.navigate(['/login']);
        } else {
          const notification: AlertNotification = {
            message: "There was an issues resetting your Password!",
            type: "error"
          }
          this.notifierService.alert(notification);
        }
      })
    }
  }

  toggleVisibility(name: string) {
    if (name === "password") {
      this.passwordInputType = (this.passwordInputType === 'password') ? 'text' : 'password';
    } else if (name === "confirm") {
      this.confirmPasswordInputType = (this.confirmPasswordInputType === 'password') ? 'text' : 'password';
    }
    this.cd.markForCheck();
  }
}
