import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-copy-to-clipboard-modal',
  template: `
    <div class="bg-gray-50 sm:rounded-lg">
      <div class="px-4 py-5 sm:p-6">
        <div class="mt-2 max-w-xl text-sm text-gray-500">
          <p class="break-all">{{data}}</p>
        </div>
        <div class="mt-5">
          <button
            [cdkCopyToClipboard]="data"
            type="button"
            [ngClass]="{'!ring-primary !bg-gray-200': clipboard}"
            (click)="clipboard = true"
            class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            Copy To Clipboard
            <svg class="-mr-0.5 h-5 w-5" viewBox="0 0 20 20" fill="primary" aria-hidden="true" *ngIf="clipboard">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  `,
})
export class CopyToClipboardModalComponent {
  clipboard = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) { }

}
