import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PlayerVideoData } from '../../models/school';
import { User, UserCreate, PaypalAuth, PaypalData } from '../../models/user';
import { HttpHelperService } from '../http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private endpoint: string = 'users';
  userType:string = ''
  constructor(private httpHelper: HttpHelperService) { }

  getAll(): Observable<User[]> {
    return this.httpHelper.request<User[]>(this.endpoint);
  }

  get(id: number): Observable<User> {
    return this.httpHelper.request<User>(`${this.endpoint}/${id}`);
  }

  filter(item: User): Observable<User[]> {
    return this.httpHelper.request<User[]>(`${this.endpoint}`, { search: item, method: 'GET' });
  }

  create(item: UserCreate): Observable<UserCreate> {
    return this.httpHelper.request<UserCreate>(`${this.endpoint}`, { body: item, method: 'POST' });
  }

  edit(item: UserCreate): Observable<UserCreate> {
    return this.httpHelper.request<UserCreate>(`${this.endpoint}/${item.id}`, { body: item, method: 'PATCH' });
  }

  sendVerificationEmail(userId: number): Observable<User> {
    return this.httpHelper.request<User>(`${this.endpoint}/${userId}/send_verification/`)
  }

  updatePassword(userId: number, oldPassword: string, newPassword: string): Observable<User> {
    const body = { oldPassword, newPassword };
    return this.httpHelper.request<User>(`${this.endpoint}/${userId}/update_password/`, { body, method: 'POST' })
  }

  syncSocialAccount(userId: number): Observable<PlayerVideoData | null> {
    return this.httpHelper.request<PlayerVideoData | null>(`${this.endpoint}/${userId}/sync_social_account/`, { method: 'POST' })
  }

  paypalAuth(paypal: PaypalAuth): Observable<PaypalData> {
    return this.httpHelper.request<PaypalData>(`${this.endpoint}/paypal_auth/`, { method: 'POST' , body: paypal})

  }


}
