import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { SchoolService } from '../../core/services/api/school.service';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap, of, take } from 'rxjs';
import { School } from 'src/app/core/models/school';
import { User } from 'src/app/core/models/user';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-user-signup-form',
  templateUrl: './user-signup-form.component.html',
  styleUrls: ['./user-signup-form.component.scss']
})
export class UserSignupFormComponent implements OnInit {

  jwtToken = "";

  fb = inject(FormBuilder);
  route = inject(ActivatedRoute);
  router = inject(Router);
  schoolService = inject(SchoolService);

  school: School;

  badCredentials = false;
  isPageLoading = false;

  control = new FormControl('');
  selectedUser: User;
  documentLoaded = false;
  documentComplete = false;
  userEmail = "";


  filteredUsers$ = this.control.valueChanges.pipe(
    map(value => {
      if (typeof value !== 'string' || value === '' || value.length < 2) {
        return [];
      }
      return this._filter(value);
    })
  )

  ngOnInit(): void {
    const token = this.route.snapshot.queryParamMap.get('token');
    if (!token) {
      this.router.navigate(['/login']);
      return
    }
    this.isPageLoading = true;
    this.jwtToken = token;
    this.schoolService.loadSignupForm(token).pipe(
      take(1),
    ).subscribe(data => {
      if (!data) {
        this.router.navigate(['/login']);
        return
      }
      this.school = data;
      this.isPageLoading = false;
    })
  }

  private _filter(value: string): User[] {
    const filterValue = value.toLowerCase();
    return this.school.users.filter(user => this.displayedUserText(user).replace(",", "").toLowerCase().includes(filterValue));
  }

  updateUser(event: MatAutocompleteSelectedEvent): void {
    this.selectedUser = event.option.value;
  }

  displayedUserText(user: User): string {
    if (!user) {
      return '';
    }
    return `${user.lastName}, ${user.firstName}`;
  }

  createForm(): void {
    var config = {
      nodeId: 'form-container-6656e655-1ca0-4d8f-b7aa-700f6189b899',
      width: '100%',
      height: '80vh',
      url: 'https://eform.pandadoc.com/?eform=08e81f0f-0db2-4946-82c4-f23c042456b0',
      events: {
        loaded: function () {
          console.log("loaded");
        },
        started: function (data) {
          console.log("started");
          console.log(data);
        },
        completed: function (data) {
          this.documentLoaded = true;
          this.userEmail = this.currentUser.email;
          console.log(data);
        },
        exception: function (data) {
          console.log("exception");
          console.log(data);
        }
      },
      data: {
        recipients: {
          Signer: {
            FirstName: this.selectedUser.firstName,
            LastName: this.selectedUser.lastName
          }
        },
        metadata: {
          user_id: this.selectedUser.id
        },
        variables: {
          SchoolName: this.school.fullName,
          SchoolAddress: this.school.location,
          UserId: this.selectedUser.id
        },
      },
    };

    const dataQueryString = config.data ? Object.keys(config.data)
      .map(function (key) {
        return '&' + key + '=' + encodeURIComponent(JSON.stringify(config.data[key]));
      })
      .join('') : '';

    var iframe = document.createElement('iframe');
    iframe.frameBorder = '0';
    iframe.src = config.url + dataQueryString;
    this.documentLoaded = true;
    if (config.nodeId) {
      var node = document.getElementById(config.nodeId);
      node.style.height = config.height;
      node.style.width = config.width;
      iframe.height = '100%';
      iframe.width = '100%';
      node.append(iframe);
    } else {
      iframe.height = config.height;
      iframe.width = config.width;
      document.body.append(iframe);
    }

    var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
    var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

    window[eventMethod](messageEvent, function (e) {
      if (e && e.data && config && iframe && e.source === iframe.contentWindow) {

        try {
          var message = JSON.parse(e.data);
          if (message && message.event) {
            var event = message.event.replace('embed.form.', '');
            var callback = config.events ? config.events[event] : null;
            if (callback) {
              callback(message.data);
            }
          }
        } catch (e) { }
      }
    }, false);
  }
}
