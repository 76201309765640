import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { Blank } from '../../models/blank';
import { CommonService } from '../../services/api/common.service';

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss'],
  animations: [fadeInRight400ms]

})
export class BlankComponent implements OnInit {
  result: string;

  @Input() blank!: Blank;
  @Output() selectedProduct: EventEmitter<any> = new EventEmitter();
  @Output() productDeleted: EventEmitter<Blank> = new EventEmitter();
  selectedProducts: any = []

  constructor(public commonService : CommonService) { }

  ngOnInit(): void {
  }

  selectProduct(blank: any) {
    if (!this.blank.selected) {
      this.selectedProduct.emit(blank);
    }
  }

  deleteProduct(blank: any) {
    if (this.blank.selected) {
      this.productDeleted.emit(blank);
    }
  }

}
