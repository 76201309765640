import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/api/auth.service';
import { UserService } from '../services/api/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(
    public authService: AuthService,
    public router: Router,
    private userService: UserService,
  ) {}
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.authService.isAuthenticated()) {
      localStorage.setItem('lastUrl', state.url);
      this.authService.logout();
      return false;
    }
    return true;
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService.isAuthenticated()) {
      localStorage.setItem('lastUrl', state.url);
      this.authService.logout();
      return false;
    }
    return this.userService.getUser();
  }
}
