import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/api/user.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  homeRoute:string;

  constructor(
    public userService:UserService
    ) { }

  ngOnInit(): void {
    this.homeRoute=`/${this.userService?.currentUser?.group?.toLowerCase()}/home`;
  }

}
