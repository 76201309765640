import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { School } from '../../models/school';
import { User, UserGroup } from '../../models/user';
import { HttpHelperService } from '../http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class UserGroupsService {
  allSchoolList$ : School[]=null;
  allGroupsList$:UserGroup[]=null;

  private endpoint: string = 'user-groups';

  constructor(private httpHelper: HttpHelperService) { }
  
  resetState(){
    this.allSchoolList$=null;
    this.allGroupsList$=null;
  }

  getAll(): Observable<UserGroup[]> {
    return this.httpHelper.request<UserGroup[]>(this.endpoint);
  }

  get(id: number): Observable<UserGroup> {
    return this.httpHelper.request<UserGroup>(`${this.endpoint}/${id}`);
  }

  filter(item: User): Observable<UserGroup> {
    return this.httpHelper.request<UserGroup>(`${this.endpoint}`, {params: item, method: 'GET'});
  }

  create(item: UserGroup): Observable<UserGroup> {
    return this.httpHelper.request<UserGroup>(`${this.endpoint}`, {body: item, method: 'POST'});
  }

  edit(item: UserGroup): Observable<UserGroup> {
    return this.httpHelper.request<UserGroup>(`${this.endpoint}/${item.id}`, {body: item, method: 'PUT'});
  }
}
