import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/api/user.service';

@Injectable({
  providedIn: 'root'
})
export class PayoutsGuard  {
  userService = inject(UserService);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.userService.user.value;
    if (user.group === "PLAYER") {
      if (this.userService.payoutsDisabled()) {
        this.userService.navigateHome();
        return false;
      }
      return true;
    }
    return true;
  }
}
