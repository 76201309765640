import { Component, inject } from '@angular/core';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-unsaved-changes-modal',
  template: `
  <div mat-dialog-title>You have unsaved changes</div>
  <div mat-dialog-content class="p-4 m-2">
    <p>If you leave this page, all unsaved changes will be lost!</p>
  </div>
  <div mat-dialog-actions align="end">
      <button mat-button (click)="onDismiss()">Leave</button>
      <button mat-raised-button color="primary" (click)="onConfirm()">Save Changes</button>
  </div>
`
})
export class UnsavedChangesModalComponent {
  dialogRef = inject(MatDialogRef<ConfirmDialogComponent>)
  onConfirm(): void {
    this.dialogRef.close(true);
  }
  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
