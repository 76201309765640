import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PlayerRecentSales, PlayerTotalSalesReport, SalesByDay, SalesOverview, PlayerTotalSalesReportFilter, BestSelling } from '../../models/home';
import { HttpHelperService } from '../http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class PlayerHomeService {
  totalSales$ : PlayerTotalSalesReport=null;
  recentSales$ : PlayerRecentSales[]=null;
  salesByDay$: SalesByDay[]=null;
  totalMonthSales$: SalesOverview=null;
  playerBestSelling$:BestSelling[]=null;
  constructor(private httpHelper: HttpHelperService) { }

  resetState(){
    this.totalSales$ = this.recentSales$ = this.salesByDay$ = this.totalMonthSales$ =this.playerBestSelling$= null;
  }

  totalSalesReportAll(): Observable<PlayerTotalSalesReport> {
    return this.httpHelper.request<PlayerTotalSalesReport>(`player-home/total_sales_report`, { method: 'GET'});
  }

  totalSalesReport(start: Date, end: Date): Observable<PlayerTotalSalesReport> {
    let salesByDayParams = new HttpParams({
      fromObject: {
        'start_date': start.toISOString().split('T')[0],
        'end_date': end.toISOString().split('T')[0]
      }
    })
    return this.httpHelper.request<PlayerTotalSalesReport>(`player-home/total_sales_report`, { method: 'GET',search : salesByDayParams});
  }

  playerRecentSales():Observable<PlayerRecentSales[]>{
    return this.httpHelper.request<PlayerRecentSales[]>(`player-home/sales`, { method: 'GET' });
  }

  playerSalesByDay(start: Date, end: Date): Observable<SalesByDay[]> {
    let search = new HttpParams({
      fromObject: {
        'start_date': start.toISOString().split('T')[0],
        'end_date': end.toISOString().split('T')[0]
      }
    })
   return this.httpHelper.request<SalesByDay[]>(`player-home/sales_by_day`, { method: 'GET', search});
  }

  playerTotalMonthSales():Observable<SalesOverview>{
    var today = new Date();
    var thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    let totalMonthSalesParams = new HttpParams({
      fromObject: {
        'start_date': thirtyDaysAgo.toISOString().substring(0, 10),
        'end_date': today.toISOString().substring(0, 10)
      }
    })
    return this.httpHelper.request<SalesOverview>(`player-home/total_month_sales`, { method: 'GET', search: totalMonthSalesParams});
  }

  playerBestSelling():Observable<BestSelling[]>{
    return this.httpHelper.request<BestSelling[]>(`player-home/best_selling`, { method: 'GET'});

  }

}
