import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PandaDocument } from '../../models/document';
import { HttpHelperService } from '../http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class PandaDocumentsService {

  private endpoint: string = 'panda-documents';

  constructor(private httpHelper: HttpHelperService) { }

  get(id: number): Observable<PandaDocument> {
    return this.httpHelper.request<PandaDocument>(`${this.endpoint}/${id}`);
  }

  refreshSession(id: number): Observable<PandaDocument> {
    return this.httpHelper.request<PandaDocument>(`${this.endpoint}/${id}/refresh_session`);
  }

  send(id: number): Observable<void> {
    return this.httpHelper.request<void>(`${this.endpoint}/${id}/send`);
  }
}
