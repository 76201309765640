import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { License } from '../../models/license';
import { HttpHelperService } from '../http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class SchoolLicenseAgreementsService {
  licenceList$ : License[]=null;

  private endpoint: string = 'license';

  constructor(private httpHelper: HttpHelperService) { }

  resetState(){
    this.licenceList$ = null;
  }

  getAll(schoolId: number = null): Observable<License[]> {
    const search = {}
    if (schoolId) {
      search['school'] = schoolId;
    }
    return this.httpHelper.request<License[]>(this.endpoint, {search});
  }

}
