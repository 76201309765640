<div class="vex-user-menu">
  <a (click)="close()"
     [routerLink]="['/apps/social']"
     class="vex-user-menu-item"
     matRipple
     matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm">account_circle</mat-icon>
    <div class="vex-user-menu-item__label">User Settings</div>
  </a>
  <a (click)="logout()"
     class="vex-user-menu-item"
     matRipple
     matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm">logout</mat-icon>
    <div class="vex-user-menu-item__label">Sign Out</div>
  </a>
</div>
