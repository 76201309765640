import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

export const badgeTypes = ['gray', 'red', 'yellow', 'green', 'blue',  'purple', 'pink'] as const;
export type BadgeType = typeof badgeTypes[number];

export const badgeColors = {
  gray: {
    circle: 'text-gray-600 bg-gray-100',
    dot: 'fill-gray-400'
  },
  red: {
    circle: 'text-red-700 bg-red-100',
    dot: 'fill-red-500'
  },
  yellow: {
    circle: 'text-yellow-800 bg-yellow-100',
    dot: 'fill-gray-500'
  },
  green: {
    circle: 'text-green-700 bg-green-100',
    dot: 'fill-green-500'
  },
  blue: {
    circle: 'text-blue-700 bg-blue-100',
    dot: 'fill-blue-500'
  },
  purple: {
    circle: 'text-purple-700 bg-purple-100',
    dot: 'fill-purple-500'
  },
  pink: {
    circle: 'text-gray-600 bg-gray-100',
    dot: 'fill-gray-400'
  }
}

@Component({
  selector: 'app-badge',
  standalone: true,
  imports: [CommonModule],
  template: `
    <span class="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium {{badgeColors[type].circle}}">
      {{text}}
    </span>
  `,
})
export class BadgeComponent {
  @Input({required: false}) type: BadgeType = 'gray';
  @Input({required: false}) text: string = 'Badge';

  badgeColors = badgeColors;
}
