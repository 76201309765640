import { createAction, props } from "@ngrx/store";
import { User } from "src/app/core/models/user";

export interface UserState {
    user : User;
    token : string;
}

export const loadUser = createAction(
    '[USER] load user',
    props<{payload: UserState}>()
);
