import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/core/models/user';
import { UserService } from 'src/app/core/services/api/user.service';
import { ValuesMatchValidator } from 'src/app/core/validators/values-match.validator';
import { map, take } from 'rxjs/operators';
import { UsersService } from '../../../core/services/api/users.service';
import { AlertNotification } from 'src/app/core/models/utils';
import { NotifierService } from 'src/app/core/services/notifier.services';
import { UserCreate } from '../../../core/models/user';
import { UserPayoutsService } from 'src/app/core/services/api/user-payouts.service';
import { LastPayout, Total, TotalDue } from 'src/app/core/models/payouts';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/api/common.service';
import { MatDialog } from '@angular/material/dialog';
import { ProfilePicUploadComponent } from './profile-pic-upload/profile-pic-upload.component';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})

export class UserSettingsComponent implements OnInit {
  profileImageIsLoading: boolean = false
  Loader = false
  centered = false;
  disabled = false;
  unbounded = false;
  logo$ = this.userService.user.pipe(map(user => user.profilePicture));
  radius: number;
  color: string;
  totalDueData: TotalDue;
  totalData: Total
  public breadcrumbs = [{ name: 'User Settings', link: '/user/settings' }];
  public user: User;
  public accountFormGroup: UntypedFormGroup;
  public passwordFormGroup: UntypedFormGroup;
  public passwordInputType = 'password';
  public newPasswordInputType = 'password';
  public confirmPasswordInputType = 'password';
  public visible = false;
  public payoutsVisible = true;
  lastPayoutsArray: LastPayout[];

  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    public userService: UserService,
    public usersService: UsersService,
    private notifierService: NotifierService,
    private userPayoutService: UserPayoutsService,
    private router: Router,
    public commonService: CommonService,
    private dialog: MatDialog

  ) {

    this.logo$.subscribe(data => {

    })

    this.userService.user.pipe(take(1)).subscribe(user => {
      this.user = user;
      if (this.userService.payoutsDisabled() || this.userService.isArtwork() || this.userService.isOrg() || this.userService.isManufacturer()) {
        this.payoutsVisible = false;
      }
      this.accountFormGroup = this.fb.group({
        username: [user?.username, Validators.required],
        firstName: [user?.firstName, Validators.required],
        lastName: [user?.lastName, Validators.required],
        email: [user?.email, [Validators.required, Validators.email]],
        phone: [user?.phone, [Validators.required]],
      });
    })

    this.passwordFormGroup = this.fb.group(
      {
        password: [null, Validators.required],
        newPassword: [
          null,
          Validators.compose(
            [
              Validators.required,
              Validators.minLength(8)
            ]
          )
        ],
        passwordConfirm: [null, Validators.required]
      }, {
      validators: ValuesMatchValidator("newPassword", "passwordConfirm")
    }
    );
  }

  ngOnInit(): void {
    this.totalData = this.userPayoutService.total$;
    this.totalDueData = this.userPayoutService.totalDue$;
    this.lastPayoutsArray = this.userPayoutService.lastPayoutsDetails$;

    if (!this.totalData && this.user.group != "ORG") this.getTotal();
    if (!this.totalDueData && this.user.group != "ORG") this.getTotalDue();
    if (!this.lastPayoutsArray && this.user.group != "ORG") this.lastPayoutDetails(false)
  }

  lastPayoutDetails(deleted: boolean) {
    this.Loader = true
    this.userPayoutService.lastPayout().subscribe((data: LastPayout[]) => {
      this.lastPayoutsArray = data
      this.userPayoutService.lastPayoutsDetails$ = data;
      this.Loader = false
      if (deleted)
        this.notifierService.alert({ type: "success", message: "Deleted Successfully..." })
    })
  }

  goToPayout(item) {
    this.router.navigate(['user/payouts'], { state: item })
  }

  deleteLastPayout(item) {
    this.Loader = true;
    this.userPayoutService.deletePayout(item.id).subscribe((data) => {
      this.lastPayoutDetails(true)

    }, (err) => {
      this.Loader = false
    })
  }

  getTotalDue() {
    this.userPayoutService.TotalDue().subscribe((data: TotalDue) => {
      this.totalDueData = data;
    })
  }

  getTotal() {
    this.userPayoutService.Total().subscribe((data: Total) => {
      this.totalData = data;
      this.userPayoutService.total$ = data;
    })
  }

  saveAccount(): void {
    if (this.accountFormGroup.valid) {
      const user: UserCreate = {
        id: this.user.id,
        ...this.accountFormGroup.value
      }
      this.usersService.edit(user).pipe(take(1)).subscribe(user => {
        const notification: AlertNotification = {
          message: "Successfully Save Your Account Info",
          type: "success"
        }
        this.notifierService.alert(notification);
      })
    }

  }

  updatePassword(): void {
    if (this.passwordFormGroup.valid) {
      this.usersService.updatePassword(
        this.user.id,
        this.passwordFormGroup.value.password,
        this.passwordFormGroup.value.newPassword
      ).pipe(take(1)).subscribe(data => {
        const notification: AlertNotification = {
          message: "Successfully Updated Password",
          type: "success"
        }
        this.passwordFormGroup.reset();
        this.passwordFormGroup.markAsPristine();
        this.passwordFormGroup.markAsUntouched();
        this.notifierService.alert(notification);

      })
    }

  }

  toggleVisibility(name: string) {
    if (name === "password") {
      this.passwordInputType = (this.passwordInputType === 'password') ? 'text' : 'password';
    } else if (name === "new") {
      this.newPasswordInputType = (this.newPasswordInputType === 'password') ? 'text' : 'password';
    } else if (name === "confirm") {
      this.confirmPasswordInputType = (this.confirmPasswordInputType === 'password') ? 'text' : 'password';
    }
    this.cd.markForCheck();
  }

  goToPayoutPage() {
    this.router.navigate(['user/payouts'])
  }

  uploadProfilePicture() {
    this.dialog.open(ProfilePicUploadComponent, {
    }).afterClosed().subscribe((data) => {
      if (data) this.refreshGetUser()
    })
  }

  refreshGetUser() {
    this.profileImageIsLoading = true
    this.userService.loadUser().subscribe(data => {
      this.profileImageIsLoading = false
    })
  }

}
