<div #originRef
     (click)="showPopover(originRef)"
     [class.bg-hover]="dropdownOpen"
     class="flex items-center rounded cursor-pointer relative trans-ease-out select-none py-1 pr-1 pl-3 hover:bg-hover"
     matRipple>
  <div class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3 hidden sm:block">{{userFullName$ | async}}</div>
  <div class="rounded-full h-9 w-9 flex items-center justify-center text-primary bg-primary/10 overflow-hidden">
    <img alt="User Avatar" 
    class="vex-sidenav-user__image flex-none object-cover object-top w-full h-full" 
    [src]="logo$ | async"
    (error)="commonService.imageErrorHandle($event)"/>
  </div>
</div>
