<div (mouseenter)="collapseOpenSidenav()" (mouseleave)="collapseCloseSidenav()" [class.collapsed]="collapsed"
  [class.open]="collapsed && collapsedOpen$ | async" class="sidenav flex flex-col">
  <div class="vex-sidenav-toolbar__container">
    <div class="sidenav-toolbar flex-none flex items-center">
      <img (click)="navigateHome()" [src]="imageUrl$ | async" alt="Logo"
        class="w-6 select-none flex-none cursor-pointer" />
      <h2 (click)="navigateHome()" class="vex-sidenav-toolbar__headline flex-auto cursor-pointer">{{ title$ | async }}
      </h2>
      <button (click)="toggleCollapse()" *ngIf="showCollapsePin$ | async"
        class="w-8 h-8 -mr-2 leading-none flex-none hidden lg:block" mat-icon-button type="button">
        <mat-icon *ngIf="!collapsed" class="text-[0.875rem] w-[0.875rem] h-[0.875rem]"
          svgIcon="mat:radio_button_checked"></mat-icon>
        <mat-icon *ngIf="collapsed" class="text-[0.875rem] w-[0.875rem] h-[0.875rem]"
          svgIcon="mat:radio_button_unchecked"></mat-icon>
      </button>
      <mat-icon class="closeIcon" (click)="closeSidenav()">close</mat-icon>
    </div>

    <div *ngIf="searchVisible$ | async" class="vex-sidenav-search__container">
      <div class="vex-sidenav-search relative" (click)="openSearch()" matRipple
        matRippleColor="var(--sidenav-item-ripple-color)">
        <mat-icon class="flex-none vex-sidenav-search__icon">search</mat-icon>
        <div class="flex-auto vex-sidenav-search__placeholder">Quick Search</div>
        <div class="flex-none vex-sidenav-search__keybind">Ctrl K</div>
      </div>
    </div>

    <div class="vex-sidenav-toolbar__divider"></div>
  </div>

  <vex-scrollbar class="flex-1 flex flex-col">
    <div class="pt-6 flex-1">
      <vex-sidenav-item *ngFor="let item of items; trackBy: trackByRoute" [item]="item" [level]="0"></vex-sidenav-item>
    </div>

    <div class="vex-sidenav-footer flex items-center justify-center opacity-20 px-6 py-6 select-none flex-none">
      <img [src]="imageUrl$ | async" alt="Logo" class="w-8 select-none" />
    </div>
  </vex-scrollbar>
</div>