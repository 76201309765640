import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotifierComponent } from '../components/notifier/notifier.component';
import { AlertNotification } from '../models/utils';

@Injectable({
  providedIn: 'root'
})
export class NotifierService {


  private _notificationsSubject = new BehaviorSubject<AlertNotification[]>([]);
  public notifications$ = this._notificationsSubject.asObservable();

  constructor(private snackBar : MatSnackBar) { }

  alert(notification: AlertNotification){
      this.snackBar.openFromComponent(NotifierComponent,{
          data : {
              message : notification.message,
              buttonText : 'close',
              type : notification.type
          },
          duration : notification.duration || 3000,
          horizontalPosition : 'center',
          verticalPosition : 'bottom',
          panelClass : notification.type
      });
      let alertList = this._notificationsSubject.value;
      alertList.push(notification);
      this._notificationsSubject.next(alertList);
  }
}
