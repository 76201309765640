<div class="card rounded-2xl h-full" #salesOverview>
  <div class="border-b py-4 px-6">
  <div class="flex items-center">
    <h2 class="m-0 title flex-auto">Sales Overview</h2>
    <button mat-icon-button type="button" [matMenuTriggerFor]="menuSalesOverview" data-html2canvas-ignore="true">
      <mat-icon class="text-secondary">cloud_download</mat-icon>
    </button>
    <mat-menu #menuSalesOverview="matMenu" xPosition="before">
      <button mat-menu-item (click)="salesOverviewExportCSV()" [disabled]="this.series[0]?.data?.length===0">Export
        CSV</button>
      <button mat-menu-item (click)="salesOverviewExportPDF()" [disabled]="this.series[0]?.data?.length===0">Export
        PDF</button>
    </mat-menu>
    <button mat-icon-button type="button" data-html2canvas-ignore="true" class="hidden">
      <mat-icon class="text-secondary">more_horiz</mat-icon>
    </button>
  </div>
  <div class="gap-2 flex flex-col md:flex-row justify-between">
    <h2 class="display-1 font-bold m-0 flex items-center gap-2">{{ total }} <span class="text-sm font-thin mb-0">Sales this month</span></h2>
    <div class="flex items-center gap-4" *ngIf="this.salesOverviewData">
      <div class="w-8 h-8 rounded flex-none flex items-center justify-center"
        [ngClass]="(this.salesOverviewData?.totalMonthSales)>(this.salesOverviewData?.lastMonthSale)?'text-green bg-green-light':'text-red bg-red-light'">
        <mat-icon class="icon-sm">
          {{(this.salesOverviewData?.totalMonthSales)>(this.salesOverviewData?.lastMonthSale)?'arrow_drop_up':'arrow_drop_down'}}
        </mat-icon>
      </div>
      <p class="text-secondary m-0" *ngIf="this.salesPer">
        <span class="font-medium"
          [ngClass]="(this.salesOverviewData?.totalMonthSales)>(this.salesOverviewData?.lastMonthSale)?'text-green':'text-red'">{{this.salesPer}}%
          sales</span> in comparison to last
        month.
      </p>
    </div>
  </div>
</div>
  <div class="py-6 px-6 ">
   
    <div class="homepage-series-nodata-found">
      <h1 *ngIf="this.series[0]?.data?.length===0">No Data Found</h1>
      <vex-chart [class.set-td-opacity]="this.series[0]?.data?.length===0" [options]="options" [series]="series" class="h-full">
      </vex-chart>
    </div>
  </div>
</div>