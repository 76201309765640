import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/core/models/user';
import { AuthService } from '../../core/services/api/auth.service';
import { catchError, take } from 'rxjs/operators';
import { stagger80ms } from 'src/@vex/animations/stagger.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { ValuesMatchValidator } from 'src/app/core/validators/values-match.validator';
import { UserVerification } from '../../core/models/user';
import { UserService } from '../../core/services/api/user.service';
import { PlayerDocument } from '../../core/models/school';
import { DocumentSignersModalComponent } from 'src/app/core/components/documents/document-signers-modal/document-signers-modal.component';
import { DocumentAdditionalSigner } from 'src/app/core/models/document';
import { MatDialog } from '@angular/material/dialog';
import { PlayerDocumentsService } from '../../core/services/api/player-documents.service';
import { NotifierService } from 'src/app/core/services/notifier.services';
import { of } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-user-verification',
  templateUrl: './user-verification.component.html',
  styleUrls: ['./user-verification.component.scss'],
  animations: [
    stagger80ms,
    fadeInUp400ms,
    scaleIn400ms,
    fadeInRight400ms
  ]
})
export class UserVerificationComponent implements OnInit {

  public userVerificationForm!: FormGroup;
  public passwordsMatch = true;
  public user!: User;
  private jwtToken!: string;
  public badVerificationResponse = false;
  public visible = false;
  public passwordInputType = 'password';
  public inputText = '';
  public requiredDocs: PlayerDocument[];
  public badToken: boolean = false;
  public isLoading: boolean = false;
  public documentLoading = false;
  public documentNextText = 'Skip';
  @ViewChild(MatStepper) private stepper;

  accountFormGroup: FormGroup;
  passwordFormGroup: FormGroup = this.fb.group(
    {
      password: [null, {
        validators: [
          Validators.required,
          Validators.minLength(8)
        ],
        updateOn: 'blur'
      }
      ],
      passwordConfirm: [null, Validators.required]
    }, {
    validators: ValuesMatchValidator("password", "passwordConfirm")
  }
  );
  confirmFormGroup: FormGroup;
  socialFormGroup: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private playerDocumentsService: PlayerDocumentsService,
    private notifier: NotifierService

  ) {
    localStorage.removeItem('token');
    this.accountFormGroup = this.fb.group({
      username: [null, [Validators.required, Validators.email]],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, Validators.email],
      phone: [],
      twitterHandle: [null],
      instagramHandle: [null],
      tiktokHandle: [null]
    });

    this.passwordFormGroup.markAllAsTouched();

    this.confirmFormGroup = this.fb.group({
      terms: [null, Validators.requiredTrue]
    });
  }

  ngOnInit(): void {
    const token = this.route.snapshot.queryParamMap.get('token');
    if (!token) {
      this.router.navigate(['/login']);
      return
    }
    this.jwtToken = token;
    this.authService.getUserFromJwt(token).pipe(
      take(1),
    ).subscribe(data => {
      if (data?.user) {
        this.user = data.user;
        this.accountFormGroup.patchValue({
          username: this.user.username,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
          phone: this.user.phone,
          twitterHandle: this.user.twitterHandle,
          instagramHandle: this.user.instagramHandle,
          tiktokHandle: this.user.tiktokHandle,
        });
        this.requiredDocs = data.documents;
      } else {
        this.badToken = true;
      }
    });
  }

  submit(): void {
    if (this.user?.id && this.jwtToken && this.accountFormGroup.valid && this.passwordFormGroup.valid && this.confirmFormGroup.valid) {
      this.isLoading = true;
      const userVerification: UserVerification = {
        id: this.user.id,
        firstName: this.accountFormGroup.value.firstName,
        lastName: this.accountFormGroup.value.lastName,
        username: this.accountFormGroup.value.username,
        phone: this.accountFormGroup.value.phone,
        password: this.passwordFormGroup.value.password,
        twitterHandle: this.accountFormGroup.value.twitterHandle,
        instagramHandle: this.accountFormGroup.value.instagramHandle,
        tiktokHandle: this.accountFormGroup.value.tiktokHandle,
        jwtToken: this.jwtToken
      }
      this.authService.userVerification(userVerification).pipe(
        take(1)
      ).subscribe(logins => {
        if (Object.keys(logins).indexOf('token') !== -1) {
          localStorage.setItem('token', logins.token);
          this.userService.getUser().pipe(take(1)).subscribe(user => {
            this.userService.navigateHome();
            this.isLoading = false;
          })
        } else {
          this.badVerificationResponse = true;
          this.isLoading = false;
        }
      });
    }
  }

  toggleVisibility() {
    if (this.visible) {
      this.passwordInputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.passwordInputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }

  createDocument(document: PlayerDocument): void {
    if (document.pandaTemplate.additionalSigners && document.pandaTemplate.additionalSigners.length > 0) {
      this.dialog.open(DocumentSignersModalComponent, {
        data: document.pandaTemplate
      }).afterClosed().subscribe((signers: DocumentAdditionalSigner[]) => {
        if (signers) {
          this.documentLoading = true;
          document.pandaDocument = { additionalSigners: signers };
          this.playerDocumentsService.createFromVerification(this.jwtToken, document.pandaTemplate.id, document?.pandaDocument?.additionalSigners).pipe(
            catchError(err => {
              this.documentLoading = false;
              return of()
            })
          ).subscribe(savedDoc => {
            const idx = this.requiredDocs.findIndex(d => document.pandaTemplate.id === d.pandaTemplate.id);
            if (idx > -1) {
              this.requiredDocs[idx].pandaDocument = { createdAt: new Date() };
              this.requiredDocs = [...this.requiredDocs];
            }
            this.documentLoading = false;
            this.notifier.alert({ message: 'Document has been created, please check your email!', type: 'success' });
            this.documentNextText = "Next";
            this.stepper.next();
          })
        }
      });
    } else {
      this.documentLoading = true;
      this.playerDocumentsService.createFromVerification(this.jwtToken, document.pandaTemplate.id, document?.pandaDocument?.additionalSigners).pipe(
        catchError(err => {
          this.documentLoading = false;
          return of();
        })
      ).subscribe(savedDoc => {
        const idx = this.requiredDocs.findIndex(d => document.pandaTemplate.id === d.pandaTemplate.id);
        if (idx > -1) {
          this.requiredDocs[idx].pandaDocument = { createdAt: new Date() };
          this.requiredDocs = [...this.requiredDocs];
        }
        this.notifier.alert({ message: 'Document has been created, please check your email!', type: 'success' });
        this.documentNextText = "Next";
        this.documentLoading = false;
      })
    }
  }

}
