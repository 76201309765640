import { ChangeDetectionStrategy, Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Order, OrderAuditLog, OrderStatus } from '../../models/prints';
import { OrdersService } from '../../services/api/orders.service';
import { combineLatest, map, of, startWith } from 'rxjs';
import { FormControl } from '@angular/forms';

const LOG_TYPES = ["ALL", "STATUS_CHANGE", "COMMENT"];
type LogType = typeof LOG_TYPES[number]

@Component({
  selector: 'app-order-audit-logs-modal',
  templateUrl: './order-audit-logs-modal.component.html',
  styleUrls: ['./order-audit-logs-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderAuditLogsModalComponent {
  ordersService = inject(OrdersService);

  filterOptions: {id: number, status: OrderStatus}[] = [];
  auditFilter = new FormControl(-1);
  logTypeFilter = new FormControl<LogType>("ALL");
  auditMessage = "";
  auditLoading = false;
  LOG_TYPES = LOG_TYPES;
  filteredLogs$ = combineLatest({
    logs: of(this.order.logs).pipe(startWith(this.order.logs)),
    filter: this.auditFilter.valueChanges.pipe(startWith(this.auditFilter.value)),
    logType: this.logTypeFilter.valueChanges.pipe(startWith(this.logTypeFilter.value))
  }).pipe(
    map(({logs, filter, logType}) => this.filterLogs(logs, filter, logType))
  )
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public order: Order
  ) { 

    if (!order) {
      this.filterOptions = [];
    }
    this.filterOptions = order.orderParts.map(op => op.orderPartItems.map(opi => { return {id: opi.id, status: opi.status} })).flat();
  }

  filterLogs(logs: OrderAuditLog[], filter: number, logType: LogType): OrderAuditLog[] {
    return logs.filter(log => {
      if (filter === -1 && logType === "ALL") {
        return true;
      }
      if (filter === -1) {
        return log.action === logType;
      }
      if (filter === 0 && logType === "ALL") {
        return log.orderPartItem === null;
      }
      if (filter === 0) {
        return log.orderPartItem === null && log.action === logType;
      }
      if (logType === "ALL") {
        return log.orderPartItem === filter;
      }
      return log.orderPartItem === filter && log.action === logType;
    })
  }

  addAudit(order: Order, message: string): void {
    this.auditLoading = true;
    this.ordersService.addAuditMessage(order.id, message).subscribe((audit: OrderAuditLog) => {
      order.logs.push(audit);
      this.auditMessage = '';
      this.auditLoading = false;
    })    
  }

  isLastItem(index: number, length: number): boolean {
    return index === length - 1;
  }
}
