import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { BehaviorSubject } from 'rxjs';
import { Alert } from '../../models/alert';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  public alerts$ = new BehaviorSubject<Alert[]>([]);

  constructor() {
    this.alerts$.next(this.Alerts);
  }

  Alerts: Alert[] = [];
}
