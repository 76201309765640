import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../config/config.service';
import { filter, map, startWith, switchMap, take } from 'rxjs/operators';
import { NavigationService } from '../../services/navigation.service';
import { PopoverService } from '../../components/popover/popover.service';
import { MegaMenuComponent } from '../../components/mega-menu/mega-menu.component';
import { Observable, of } from 'rxjs';
import { ColorSchemeName } from '../../config/colorSchemeName';
import { VexConfig } from '../../config/vex-config.interface';
import { UserService } from 'src/app/core/services/api/user.service';
import { PortalAccess } from 'src/app/core/models/user';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/api/common.service';
import { Breadcrumb } from 'src/app/core/models/utils';
import { School } from 'src/app/core/models/school';
@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  configs: VexConfig[] = this.configService.configs;
  config$: Observable<VexConfig> = this.configService.config$;
  breadcrumbs : Breadcrumb[]=[];
  isDark$: Observable<boolean> = this.config$.pipe(
    map((config) => config.style.colorScheme === ColorSchemeName.dark)
  );
  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;
  userGroup: string = 'admin';

  navigationItems = this.navigationService.items;

  isHorizontalLayout$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.layout === 'horizontal')
  );
  isVerticalLayout$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.layout === 'vertical')
  );
  isNavbarInToolbar$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.navbar.position === 'in-toolbar')
  );
  isNavbarBelowToolbar$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.navbar.position === 'below-toolbar')
  );
  userVisible$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.toolbar.user.visible)
  );

  school$: Observable<School> = this.userService.user.pipe(
    map(user => user.school)
  );

  megaMenuOpen$: Observable<boolean> = of(false);
  accessExist: PortalAccess = null;

  constructor(
    private layoutService: LayoutService,
    private configService: ConfigService,
    private navigationService: NavigationService,
    private popoverService: PopoverService,
    public userService: UserService,
    public commonService : CommonService,
    private router: Router
  ) {
    this.accessExist = this.userService.getPortalAccess();
    this.userService.user.pipe(take(1)).subscribe(user => {
      this.userGroup = user.accessBy?.toLowerCase();
    })
    this.breadcrumbs = this.commonService?.getDynamicBreadcrumbs();
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      ).subscribe(event=>{
      this.breadcrumbs = this.commonService?.getDynamicBreadcrumbs();
    })
  }

  enableDarkMode(): void {
    this.configService.updateConfig({
      style: {
        colorScheme: ColorSchemeName.dark,
      },
    });
  }

  openHelpCenter() {
    this.router.navigate(['help-center'])
  }
  disableDarkMode(): void {
    this.configService.updateConfig({
      style: {
        colorScheme: ColorSchemeName.light,
      },
    });
  }

  openQuickpanel(): void {
    this.layoutService.openQuickpanel();
  }

  openSidenav(): void {
    this.layoutService.openSidenav();
  }

  openMegaMenu(origin: ElementRef | HTMLElement): void {
    this.megaMenuOpen$ = of(
      this.popoverService.open({
        content: MegaMenuComponent,
        origin,
        offsetY: 12,
        position: [
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
        ],
      })
    ).pipe(
      switchMap((popoverRef) => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true)
    );
  }

  openSearch(): void {
    this.layoutService.openSearch();
  }
  switchAccess() {
    this.userService.portalAccessLogout();
  }
}
