import { Component, Inject, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SchoolTemplateDesign } from '../../models/templates';

@Component({
  selector: 'app-design-selection-modal',
  templateUrl: './design-selection-modal.component.html',
  styleUrls: ['./design-selection-modal.component.scss']
})
export class DesignSelectionModalComponent {
  dialogRef = inject(MatDialogRef<DesignSelectionModalComponent>);

  constructor(
    @Inject(MAT_DIALOG_DATA) public designs: SchoolTemplateDesign[],
  ) { }
}
