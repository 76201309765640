<div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8 bg-slate-100">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <img class="mx-auto h-10 w-auto" src="assets/img/icon.png" alt="Athletic Solutions">
    <h2 class="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-primary">Reset your password</h2>
  </div>

  <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
    <div class="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12" [componentIsLoading]="this.isPageLoading">
      <form class="space-y-6" [formGroup]="resetPasswordFormGroup" (ngSubmit)="onSubmit()">
        <div>
          <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Password</label>
          <div class="mt-2">
            <input
              id="password"
              name="password"
              type="password"
              formControlName="password"
              autocomplete="current-password"
              required
              class="block px-2 w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
          </div>
          <div class="help-block" *ngIf="resetPasswordFormGroup.get('password').touched">
            <p class="mt-2 text-sm text-red-600" *ngIf="resetPasswordFormGroup.get('password').errors?.minlength">Your password should be at least 8 characters long.</p>
          </div>
        </div>
        <div>
          <label for="password-confirm" class="block text-sm font-medium leading-6 text-gray-900">Password Confirm</label>
          <div class="mt-2">
            <input
              id="password-confirm"
              name="password-confirm"
              type="password"
              formControlName="passwordConfirm"
              autocomplete="current-password"
              required
              class="block px-2 w-full rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
          </div>
          <div class="help-block" *ngIf="resetPasswordFormGroup.get('passwordConfirm').touched">
            <p class="mt-2 text-sm text-red-600" *ngIf="resetPasswordFormGroup.get('passwordConfirm').errors?.confirmedValidator">Passwords must match!</p>
          </div>
        </div>

        <div>
          <button
            [disabled]="!resetPasswordFormGroup.valid"
            (click)="onSubmit()"
            type="submit"
            class="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">Reset Password</button>
        </div>
      </form>
    </div>
  </div>
</div>


<!-- <link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Nunito&display=swap" rel="stylesheet">

<div [componentIsLoading]="this.isPageLoading" class="login flex flex-col items-center justify-center h-screen">
  <div class="w-full flex items-center justify-center">
    <mat-card class="login-card flex items-stretch">
      <div class="left items-stretch flex-grow">
        <h2>Please sumbit form to reset your password!</h2>
        <div class="content">
          <form [formGroup]="resetPasswordFormGroup" class="flex flex-col">
            <div class="form-group">
              <mat-form-field appearance="outline" color="accent">
                <mat-label>Password</mat-label>
                <input [type]="passwordInputType" formControlName="password" class="form-control" autocomplete="off" matInput />
                <button (click)="toggleVisibility('password')" *ngIf="passwordInputType === 'password'" mat-icon-button matSuffix
                      type="button" tabindex="-1">
                  <mat-icon>visibility</mat-icon>
                </button>
                <button (click)="toggleVisibility('password')" *ngIf="passwordInputType === 'text'" mat-icon-button matSuffix
                        type="button" tabindex="-1">
                  <mat-icon>visibility_off</mat-icon>
                </button>
                <mat-hint>Your password should be at least 8 characters long.</mat-hint>
              </mat-form-field>
              <mat-form-field appearance="outline" color="accent">
                <mat-label>Confirm Password</mat-label>
                <input [type]="confirmPasswordInputType" formControlName="passwordConfirm" class="form-control" autocomplete="off" matInput />
                <button (click)="toggleVisibility('confirm')" *ngIf="confirmPasswordInputType === 'password'" mat-icon-button matSuffix
                      type="button" tabindex="-1">
                  <mat-icon>visibility</mat-icon>
                </button>
                <button (click)="toggleVisibility('confirm')" *ngIf="confirmPasswordInputType === 'text'" mat-icon-button matSuffix
                        type="button" tabindex="-1">
                  <mat-icon>visibility_off</mat-icon>
                </button>
                <mat-hint>Confirm password field must match password.</mat-hint>
              </mat-form-field>
            </div>
            <div class="form-group text-center  md:text-right">
              <button class="btn btn-primary ml-auto" color="primary" mat-flat-button [disabled]="!resetPasswordFormGroup.valid" (click)="onSubmit()" >Reset Password</button>
            </div>
          </form>
        </div>
      </div>
    </mat-card>
  </div>
</div> -->
