import { createAction, props } from "@ngrx/store";
import { Player } from "src/app/core/models/school";

export interface PlayerMySportsState{
    sportsList : Player[];
}

export const setPlayerMySports = createAction(
    '[PLAYER] set player home',
    props<{payload: PlayerMySportsState}>()
);