import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { ConfirmDialogComponent } from 'src/app/core/components/confirm-dialog/confirm-dialog.component';
import { SchoolTemplateDesign } from 'src/app/core/models/templates';
import { User } from 'src/app/core/models/user';
import { ConfirmDialog } from 'src/app/core/models/utils';
import { CommonService } from 'src/app/core/services/api/common.service';
import { SchoolTemplateDesignsService } from 'src/app/core/services/api/school-template-designs.service';
import { NotifierService } from 'src/app/core/services/notifier.services';

@Component({
  selector: 'app-school-template-design-view',
  templateUrl: './school-template-design-view.component.html',
  styleUrls: ['./school-template-design-view.component.scss'],
  animations: [
    fadeInUp400ms
  ]
})
export class SchoolTemplateDesignViewComponent implements OnInit {
  form: UntypedFormGroup;
  loader: boolean;
  user: User;
  enable: boolean;
  designStatus: string;
  @ViewChild('approvalEvent') approvalEvent: MatSlideToggle;

  constructor(@Inject(MAT_DIALOG_DATA) public initialData: SchoolTemplateDesign,
    private dialogRef: MatDialogRef<SchoolTemplateDesignViewComponent>,
    private fb: UntypedFormBuilder,
    private notifier: NotifierService,
    public commonService: CommonService,
    private schoolTemplateDesignsService: SchoolTemplateDesignsService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      player: [this.initialData.player || ''],
      schoolTemplate: [this.initialData.id || ''],
      schoolSport: [''],
      name: ['', Validators.required]
    });
  }

  updateSchoolTemplate(data) {
    this.loader = true;
    this.schoolTemplateDesignsService.edit(data).subscribe(result => {
      this.loader = false;
      if (!result) return this.notifier.alert({ type: 'warning', message: 'Something went wrong' });
      this.initialData.approved = result.approved;
      this.initialData.approvedAt = result.approvedAt;
      this.initialData.rejectedAt = result.rejectedAt;
      this.initialData.rejectedReason = result.rejectedReason;
      this.notifier.alert({ type: 'success', message: 'Updated Successfully' });
    }, err => {
      this.loader = false;
    })
  }

  change(event: MatSlideToggleChange) {

    let value = event.checked ? 'Aprrove' : 'Un Approve';
    let data: ConfirmDialog = { content: value };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: data });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return this.approvalEvent.checked = !event.checked;
      let data = {
        id: this.initialData.id,
        approved: event.checked
      };
      this.updateSchoolTemplate(data);
    });

  }

  approve() {
    this.schoolTemplateDesignsService.get(this.initialData.id).subscribe(resultData => {
      let value = !resultData.approved ? 'Aprrove' : 'Un Approve';
      let dialogData: ConfirmDialog = { content: value };
      const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: dialogData });

      dialogRef.afterClosed().subscribe(result => {
        if (!result) return resultData.approved = !resultData.approved;
        let data = {
          id: this.initialData.id,
          approved: !resultData.approved
        };
        this.updateSchoolTemplate(data);
      });
    });


  }

  rejectDesign(value : string){
    if(!value || value == '') return this.notifier.alert({type : 'warning',message : 'Write some content !!'})
    let data={
      id : this.initialData.id,
      rejectedReason : value,
      approved : false
    }
    this.updateSchoolTemplate(data);
  }

  designBackgroundChange() {
    if (!this.initialData.approved && !this.initialData.rejectedAt) {
      this.designStatus = 'Under Review';
      return 'bg-amber-100';
    } else if (!this.initialData.approved && this.initialData.rejectedAt) {
      this.designStatus = 'Rejected';
      return 'bg-red-100';
    } else if (this.initialData.approved) {
      this.designStatus = 'Approved';
      return 'bg-lime-100';
    } else {
      this.designStatus = 'Un Approved';
      return 'bg-slate-100';
    }
  }

  setImage(field : string, value : string){
    this.initialData[field]=value;
  }
}


