import { Component, Input, OnInit } from '@angular/core';
import { trackByValue } from '../../utils/track-by';
import { UserService } from '../../../app/core/services/api/user.service';

@Component({
  selector: 'vex-breadcrumbs',
  template: `
    <div class="flex items-center">
      <vex-breadcrumb>
        <a (click)="goHome()">
          <mat-icon class="icon-sm">home</mat-icon>
        </a>
      </vex-breadcrumb>
      <ng-container *ngFor="let crumb of crumbs; trackBy: trackByValue">
        <div class="w-1 h-1 bg-gray rounded-full ltr:mr-2 rtl:ml-2"></div>
        <vex-breadcrumb>
          <a [routerLink]="crumb.link">{{ crumb.name }}</a>
        </vex-breadcrumb>
      </ng-container>
    </div>
  `
})
export class BreadcrumbsComponent implements OnInit {

  @Input() crumbs: any[] = [];

  trackByValue = trackByValue;

  constructor(private userService: UserService) {
  }

  ngOnInit() {
  }
  goHome(): void {
    this.userService.navigateHome();
  }
}
