import { createFeatureSelector, createReducer, on ,Action, createSelector} from '@ngrx/store';
import { AdminCodesState, setAdminCodesState } from '../actions/admin-codes.actions';


export const initialState: AdminCodesState = { 
 codes:null
}

export const adminCodesReducer = createReducer(
  initialState,
  on(setAdminCodesState, (state, { payload }) => {
    return payload;
  })
);

export const AdminCodesReducer = (state: any, action: Action) => {
    return adminCodesReducer(state, action);
}

export const AdminCodes = createFeatureSelector<AdminCodesState>('adminCodes');

export const getAdminCodesState = createSelector(
    AdminCodes,
  (state: AdminCodesState) => state,
)
