import { createAction, props } from "@ngrx/store";
import { PlayerRecentSales, SalesByDay, SalesOverview, PlayerTotalSalesReport } from "src/app/core/models/home";

export interface PlayerHomeState {

    totalSales? : PlayerTotalSalesReport,
    recentSales? : PlayerRecentSales[],
    salesByDay? : SalesByDay[],
    salesOverview? : SalesOverview
}

export const setPlayerHome = createAction(
    '[PLAYER] set player home',
    props<{payload: PlayerHomeState}>()
);

export const getTotalSales = createAction(
    '[PLAYER] get total sales'
);


export const getRecentSales = createAction(
    '[PLAYER] get recent sales'
);

export const getSalesByDay = createAction(
    '[PLAYER] get sales by day sales'
);

export const getSalesOverview = createAction(
    '[PLAYER] get sales overview sales'
);
