import { ActionReducerMap } from "@ngrx/store";
import { AppState } from "../actions";
import { userReducer } from "./user.reducers";
import { playerHomeReducer } from "./player-home.reducers";
import { PlayerSalesReducer } from "./player-sales.reducers";
import { playerMySportsReducer } from "./player.mysports.reducers";
import { SchoolHomeReducer } from "./school-home.reducers";
import { SchoolSalesReducer } from "./school-sales.reducers";
import { AdminCodesReducer } from "./admin-codes.reducers";

export const reducers: ActionReducerMap<AppState> = {
    userState : userReducer,
    schoolHomes : SchoolHomeReducer,
    playerHomeState  : playerHomeReducer,
    schoolSales : SchoolSalesReducer,
    playerMySportsState : playerMySportsReducer,
    playerSales:PlayerSalesReducer,
    adminCodes:AdminCodesReducer

}