<mat-toolbar color="primary">
  <mat-toolbar-row>
    <div class="flex">
      <span class="active-route">{{activatedRoute}}</span>
    </div>
    <mat-icon *ngIf="!opened" class="example-icon" aria-hidden="false" aria-label="Menu" (click)="opened=!opened;toggleSideNav(opened)" matTooltip="Open SideNav" matTooltipPosition="left" matTooltipShowDelay="1000">
      menu</mat-icon>
    <mat-icon  *ngIf="opened" class="example-icon" aria-hidden="false" aria-label="Menu" (click)="opened=!opened;toggleSideNav(opened)" matTooltip="Close SideNav" matTooltipPosition="left" matTooltipShowDelay="1000">
      menu</mat-icon>

    <div class="top-icons flex items-end align-middle">
      <div>
        <mat-icon class="example-icon" aria-hidden="false" aria-label="Messages" [matMenuTriggerFor]="menuContactInfo" matTooltip="Contact Info" matTooltipPosition="below" matTooltipShowDelay="1000">
          call</mat-icon>

        <mat-icon class="example-icon" aria-hidden="false" aria-label="Notifications"
          [matMenuTriggerFor]="menuNotifications" matTooltip="Notifications" matTooltipPosition="below" matTooltipShowDelay="1000">notifications</mat-icon>


        <span [matMenuTriggerFor]="menu"
          style="display:flex;align-items:center;justify-content:space-evenly; margin-left: 25px; margin-right: 15px;">
          <span style="font-weight:lighter; font-size: 12px; position: relative; top:3px;">{{userName_}}</span>
          <mat-icon class="example-icon" aria-hidden="false" aria-label="User">account_circle</mat-icon>


        </span>
        <mat-menu #menu="matMenu">
          <button mat-menu-item>Account Settings</button>
          <button mat-menu-item (click)="logout()">Logout</button>
        </mat-menu>

        <mat-menu #menuContactInfo="matMenu" class="td-contact-details">
          <p class="td-padding-p">Mr John Smith. 132, My Street, Kingston, New York 12401 USA.</p>
        </mat-menu>

        <mat-menu #menuNotifications="matMenu" class="mat-menu-panel mat-menu-notifications">
          <div class="notification-container td-notification">
            <mat-list>
              <mat-list-item *ngFor="let item of alertsArray" [ngClass]="item.seen?'seen':'unseen'"
                (click)="notificationButton(item)"> {{item.msg}}<mat-divider></mat-divider>
              </mat-list-item>
            </mat-list>
          </div>
        </mat-menu>

      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
