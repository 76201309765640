import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelperService } from '../http-helper.service';
import { PrintLocation } from '../../models/design';
import { PrintType } from '../../models/bulk-order';

@Injectable({
  providedIn: 'root'
})
export class PrintLocationService {
  private endpoint: string = 'print-locations';

  constructor(private httpHelper: HttpHelperService) { }

  getAll(): Observable<PrintLocation[]> {
    return this.httpHelper.request<PrintLocation[]>(this.endpoint);
  }

  get(id: number): Observable<PrintLocation> {
    return this.httpHelper.request<PrintLocation>(`${this.endpoint}/${id}`);
  }

  create(item: PrintLocation): Observable<PrintLocation> {
    return this.httpHelper.request<PrintLocation>(`${this.endpoint}`, { body: item, method: 'POST' });
  }

  edit(item: PrintLocation): Observable<PrintLocation> {
    return this.httpHelper.request<PrintLocation>(`${this.endpoint}/${item.id}`, { body: item, method: 'PUT' });
  }

  partialEdit(item: PrintLocation): Observable<PrintLocation> {
    return this.httpHelper.request<PrintLocation>(`${this.endpoint}/${item.id}`, { body: item, method: 'PATCH' });
  }

  delete(id: number): Observable<void> {
    return this.httpHelper.request<void>(`${this.endpoint}/${id}`, { method: 'DELETE' });
  }

  createWithImage(designId: number, printType: PrintType, location: string, field: string, file: File): Observable<{ message: string, data: PrintLocation }> {
    const formData = new FormData();
    formData.append('field', field);
    formData.append('file', file);
    formData.append('designId', `${designId}`);
    formData.append('printType', printType);
    formData.append('location', location);
    return this.httpHelper.request<{ message: string, data: PrintLocation }>(`${this.endpoint}/create_with_image`, { body: formData, method: 'POST' });
  }


}
