<div mat-dialog-title>Tile Image for <span class="school-name">{{schoolSport.nickname}}</span></div>
<div mat-dialog-content>
  <div class="grid grid-cols-1 mysports">
    <div class="p-4 text-center trans-ease-out relative main-container">
      <div class="p-2 rounded border-gray-200 border h-full">
        <div class="title font-semibold">Tile Image</div>
        <img class="sm:h-full w-full sm:w-auto max-h-72 aspect-auto mx-auto rounded" [src]="schoolSport.tileImage"
        (error)="commonService.imageErrorHandle($event)">
        <div class="mt-2" >
          <app-upload-file class="form-control" [modelId]="schoolSport?.id" model="school-sports"
            field="tileImage" (output)="setImage('tileImage', $event)">
          </app-upload-file>
        </div>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close color="warn" type="button">Close</button>
</div>


