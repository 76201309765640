<div (mouseenter)="showButton = true" (mouseleave)="showButton = false"
  class="relative h-full flex flex-col  justify-center full text-primary">

  <h3 class="text-base md:text-lg text-center md:text-left font-thin m-0 color-dark">{{ label }}</h3>
  <div class="flex items-center flex-col md:flex-row justify-between  mt-auto">   
    <h2 class="font27 font-bold color-dark">{{ value }}</h2>   
    <h4 [class.text-green]="change > 0" [class.text-red]="change <= 0"
      class="subheading-2 font-semibold mt-4 mb-0 flex items-center color-dark">
      <mat-icon *ngIf="change > 0" class="icon-sm">arrow_drop_up</mat-icon>
      <mat-icon *ngIf="change <= 0" class="icon-sm">arrow_drop_down</mat-icon>
      <span *ngIf="change">{{ change }}%</span>
    </h4>

    <button *ngIf="helpText" [matTooltip]="helpText" class="absolute top-0 left-0 mt-2 ml-2 text-hint" mat-icon-button
      matTooltipPosition="after" type="button">
      <mat-icon class="icon-sm">help</mat-icon>
    </button>
    <div [ngClass]="iconClass" class="rounded-full w-12 h-12 flex items-center justify-center color-dark">
      <mat-icon>{{icon}}</mat-icon>
    </div>
  </div>
</div>