import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, map } from 'rxjs';
import { Code } from '../../models/code';
import { CodeService } from '../api/code.service';

@Injectable({
  providedIn: 'root'
})
export class CodeStateService {

  private readonly _codesSource = new BehaviorSubject<Code[]>(null);
  public readonly codes$ = this._codesSource.asObservable();

  constructor(
    private codeService: CodeService
  ) { }

  public init(): void {
    if (!this._codesSource.getValue()) {
      this.codeService.getAll().subscribe(codes => {
        this._codesSource.next(codes);
      })
    }
  }

  getCodes(): Code[] {
    return this._codesSource.getValue();
  }

  setCodes(models: Code[]): void {
    this._codesSource.next(models);
  }

  getCodesByType(codeType: string): Observable<Code[]> {
    return this.codes$.pipe(
      filter(Boolean),
      map(codes => {
        return codes.filter(c => c.codeType === codeType);
      })
    );
  }
}
