import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild ,Input} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { stagger80ms } from 'src/@vex/animations/stagger.animation';
import { ConfigService } from 'src/@vex/config/config.service';
import { LayoutService } from 'src/@vex/services/layout.service';
import { trackById } from 'src/@vex/utils/track-by';
import { SchoolDocument } from '../../models/school';
import { DocumentsPageService } from '../documents/documents-page.service';
@Component({
  selector: 'app-player-docs',
  templateUrl: './player-docs.component.html',
  styleUrls: ['./player-docs.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms,
    stagger80ms,
    fadeInUp400ms
  ],
})
export class PlayerDocsComponent implements OnInit {
  @Input() loader:boolean;
  ltLg$ = this.layoutService.ltLg$;
  mobileQuery = this.mediaMatcher.matchMedia('(max-width: 959px)');
  drawerOpen$ = this.documentsPagesService.drawerOpen$;
  documents$ = this.documentsPagesService.getDocuments();

  drawerOpen = true;

  searchCtrl = new UntypedFormControl();
  selectedDocument: SchoolDocument;
  private _mobileQueryListener: () => void;

  @ViewChild(MatDrawer, { static: true }) private drawer: MatDrawer;

  trackById = trackById;


  constructor(private layoutService: LayoutService,
              private readonly configService: ConfigService,
              private dialog: MatDialog,
              private mediaMatcher: MediaMatcher,
              private documentsPagesService: DocumentsPageService,
              private cd: ChangeDetectorRef) {

              }

  ngOnInit(): void {
    this.mobileQuery.matches ? this.closeDrawer() : this.openDrawer();
    this._mobileQueryListener = () => {
      this.mobileQuery.matches ? this.closeDrawer() : this.openDrawer();
      this.cd.detectChanges();
    };
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
  }

  documentSelected(document: SchoolDocument): void {
    this.selectedDocument = document;
    this.mobileQuery.matches ? this.closeDrawer() : this.openDrawer();
  }

  drawerChange(drawerOpen: boolean) {
    this.documentsPagesService.drawerOpen.next(drawerOpen);
  }

  openDrawer() {
    this.documentsPagesService.drawerOpen.next(true);
    this.cd.markForCheck();
  }

  closeDrawer() {
    this.documentsPagesService.drawerOpen.next(false);
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }
}
