import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Blank } from 'src/app/core/models/blank';
import { CommonService } from 'src/app/core/services/api/common.service';

@Component({
  selector: 'app-admin-blank-view',
  templateUrl: './admin-blank-view.component.html',
  styleUrls: ['./admin-blank-view.component.scss']
})
export class AdminBlankViewComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public blank: Blank,
    private dialogRef: MatDialogRef<AdminBlankViewComponent>,
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
  }
}
