import { Component, ElementRef, Inject, OnInit, Query, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { CommonService } from 'src/app/core/services/api/common.service';
import { Payouts } from 'src/app/core/models/payouts';
import { UserPayoutsService } from 'src/app/core/services/api/user-payouts.service';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';

@Component({
  selector: 'app-payout-disclosure',
  templateUrl: './payout-disclosure.component.html',
  styleUrls: ['./payout-disclosure.component.scss'],
  animations : [fadeInUp400ms]
})
export class PayoutDisclosureComponent implements OnInit {
  loader: boolean;
  exportLoader : boolean;
  blanks: any = {};
  categories = [];
  playerProfileImage = '';
  @ViewChild('exportPdf') exportPdf!: ElementRef;
  private datetime = new Date();
  constructor(
    private dialogRef: MatDialogRef<PayoutDisclosureComponent>,
    @Inject(MAT_DIALOG_DATA) public payout: Payouts,
    public commonService: CommonService,
    private payoutsService : UserPayoutsService
  ) { }

  ngOnInit(): void {
    this.loadOnePayout();
  }

  loadOnePayout(){
    this.loader=true;
    this.payoutsService.get(this.payout.id).subscribe(data=>{
      this.payout = data;
      if(data.user && data.user?.players.length>0)
      this.playerProfileImage = data.user?.players[0].image
      this.loader=false;
    })
  }
  public getCachelessUrl(url: string): string {
    return url + '?v=' + this.datetime.getTime();
  }

  public generatePDF(): void {
    this.exportLoader = true;
    html2canvas(this.exportPdf.nativeElement, { scale: 3, useCORS: true }).then((canvas) => {
      const imageGeneratedFromTemplate = canvas.toDataURL('image/png');
      let PDF = new jsPDF('p', 'mm', 'a4');
      const fileWidth = 200;
      let pageHeight = 290;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      let fileHeightLeft = fileHeight;
      let position = 10;

      PDF.addImage(imageGeneratedFromTemplate, 'PNG', 0, position, fileWidth, fileHeight);
      fileHeightLeft -= pageHeight;

      while (fileHeightLeft >= 0) {
        position += fileHeightLeft - fileHeight;
        PDF.addPage();
        PDF.addImage(imageGeneratedFromTemplate, 'PNG', 0, position, fileWidth, fileHeight);
        fileHeightLeft -= pageHeight;
      }

      PDF.html(this.exportPdf.nativeElement.innerHTML);
      PDF.save(this.payout?.user?.firstName+'-'+this.payout?.user?.lastName+'-payout-disclosure.pdf');
      this.exportLoader = false;
    });
  }

}
