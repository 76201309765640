import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { License, LicenseItem } from '../../models/license';
import { HttpHelperService } from '../http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {
  licenseList$ : License[]=null;
  AllLicenseList$ : License[]=null;
  private endpoint: string = 'license';

  constructor(private httpHelper: HttpHelperService) { }
  resetState(){
    this.licenseList$ = this.AllLicenseList$ = null;
  }
  
  getAll(filter?:any): Observable<LicenseItem[]> {
    return this.httpHelper.request<LicenseItem[]>(this.endpoint,{method : 'GET',search : filter});
  }

  get(id: number): Observable<License> {
    return this.httpHelper.request<License>(`${this.endpoint}/${id}`);
  }

  create(item: License): Observable<License> {
    return this.httpHelper.request<License>(`${this.endpoint}`, {body: item, method: 'POST'});
  }

  edit(item: License): Observable<License> {
    return this.httpHelper.request<License>(`${this.endpoint}/${item.id}`, {body: item, method: 'PUT'});
  }
  delete(item : License) : Observable<License>{
    return this.httpHelper.request<License>(`${this.endpoint}/${item.id}`, { method: 'DELETE'});
  }

  getEntityLicenses(schoolId): Observable<License[]> {
    const search = {schoolId};
    return this.httpHelper.request<License[]>(`${this.endpoint}/entity-licenses/`, { search });
  }
}
