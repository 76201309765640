import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Model } from '../../models/blank';
import { HttpHelperService } from '../http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class ModelService {
  public modelView$: string = null;
  private endpoint: string = 'models';

  constructor(private httpHelper: HttpHelperService) { }

  getAll(): Observable<Model[]> {
    return this.httpHelper.request<Model[]>(this.endpoint);
  }

  get(id: number | string): Observable<Model> {
    return this.httpHelper.request<Model>(`${this.endpoint}/${id}`);
  }

  filter(item: Partial<Model>): Observable<Model[]> {
    return this.httpHelper.request<Model[]>(`${this.endpoint}`, { params: item, method: 'GET' });
  }

  create(item: Model): Observable<Model> {
    return this.httpHelper.request<Model>(`${this.endpoint}`, { body: item, method: 'POST' });
  }

  edit(item: Model): Observable<Model> {
    return this.httpHelper.request<Model>(`${this.endpoint}/${item.model}`, { body: item, method: 'PUT' });
  }

  delete(model: string): Observable<null> {
    return this.httpHelper.request<null>(`${this.endpoint}/${model}`, { method: 'DELETE' });
  }

  search(search: string): Observable<Model[]> {
    return this.httpHelper.request<Model[]>(`${this.endpoint}/search`, { search: { search }, method: 'GET' })
  }

}
