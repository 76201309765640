import { Component, ElementRef, inject, Input } from '@angular/core';
import { School } from 'src/app/core/models/school';
import { SchoolTemplateDesign, TemplateSide } from 'src/app/core/models/templates';
import { CommonService } from 'src/app/core/services/api/common.service';
import { LicenseCategoryModel } from 'src/app/core/services/api/school-template-designs.service';


export const SIZE_MAP = {
  "Left Chest": "4 inches wide",
  "Left Side": "4 inches wide",
  "Right Side": "4 inches wide",
  "Sleeve": "4 inches wide",
  "Back": "11.5 inches wide",
  "Front": "11.5 inches wide",
  "R Shoulder": "4 inches wide",
  "L Shoulder": "4 inches wide",
  "L Patch": "4 inches wide",
  "R Patch": "4 inches wide",
  "Backneck": "4 inches wide",
}


@Component({
  selector: 'app-license-product-category',
  templateUrl: './license-product-category.component.html',
  styleUrls: ['./license-product-category.component.scss']
})
export class LicenseProductCategoryComponent {
  @Input({required: true}) school: School;
  @Input({required: true}) design: SchoolTemplateDesign;
  @Input({required: true}) category: LicenseCategoryModel;
  @Input({required: true}) sides: TemplateSide[];

  commonService = inject(CommonService);
  elementRef = inject(ElementRef);

  private datetime = new Date();

  SIZE_MAP = SIZE_MAP;

  public getCachelessUrl(url: string): string {
    return url + '?v=' + this.datetime.getTime();
  }

  getPrintFileUrl(colorway: string, versionCode: string | undefined): string {
    versionCode = versionCode ? versionCode : '';
    return `https://ipods.s3.amazonaws.com/media/print_files/${this.design.schoolTemplate}_${this.design.id}${colorway}${versionCode}.png?${new Date().getTime()}`;
  };

}
