<vex-page-layout>
  <vex-page-layout-content class="container">
    <div>
      <div class="flex flex-row gap-3 items-center my-4 justify-between">
        <div class="flex flex-row items-center">
          <mat-icon [routerLink]="['/user/payouts']" class="hover:text-gray-500 cursor-pointer">arrow_back</mat-icon>
          <span class="ml-3 text-lg font-semibold">
            Payouts
          </span>
        </div>
      </div>
    </div>
    <app-transactions></app-transactions>
  </vex-page-layout-content>
</vex-page-layout>