import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PandaTemplateService } from '../api/panda-template.service';
import { PandaTemplate } from '../../models/document';

@Injectable({
  providedIn: 'root'
})
export class PandaTemplateStateService {
  pandaTemplateService = inject(PandaTemplateService);

  private readonly _templateSource = new BehaviorSubject<PandaTemplate[]>(null);
  public readonly templates$ = this._templateSource.asObservable();

  public init(): void {
    if (!this._templateSource.getValue()) {
      this.pandaTemplateService.getAll().subscribe(users => {
        this._templateSource.next(users);
      })
    }
  }

  getTemplates(): PandaTemplate[] {
    return this._templateSource.getValue();
  }

}


