import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import {
  catchError,
  map,
  mergeMap,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { NotifierService } from './core/services/notifier.services';
import { AuthService } from './core/services/api/auth.service';

interface CustomError extends ErrorEvent {
  details: { [key: string]: string[] }
}

declare var CustomError: {
  prototype: CustomError;
  new(type: string, eventInitDict?: ErrorEventInit): CustomError;
};

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  authService = this.injector.get(AuthService);
  constructor(private notifier: NotifierService, private injector: Injector) { }

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      req.url.endsWith('/login/') ||
      req.url.endsWith('/user-verification/') ||
      req.url.endsWith('/get-user-from-jwt/')
    ) {
      return of(req).pipe(
        mergeMap((r) =>
          next
            .handle(r)
            .pipe(
              catchError((error: HttpErrorResponse) => {
                if (error.status !== 403) {
                  const errorMessage = this.setError(error);
                  this.notifier.alert({ message: errorMessage, type: 'error' });
                  return throwError(() => error);
                }
              })
            )
            .pipe(
              map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
                return evt;
              })
            )
        )
      );
    } else {
      return of(
        req.clone({
          setHeaders: {
            Authorization: `Token ${this.authService.getAuthToken()}`,
          },
        })
      ).pipe(
        mergeMap((r) =>
          next
            .handle(r)
            .pipe(
              catchError((error: HttpErrorResponse) => {
                const errorMessage = this.setError(error);
                this.notifier.alert({ message: errorMessage, type: 'error' });
                return throwError(() => error);
              })
            )
            .pipe(
              map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
                return evt;
              })
            )
        )
      );
    }
  }
  setError(error: HttpErrorResponse): string {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      if (error.status === 0) {
        errorMessage = `Unknown Error ocurred`;
      } else {
        errorMessage = `${error.error.error.message || error.message}`;
      }
    }
    return errorMessage;
  }
}
