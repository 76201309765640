<div class="flex flex-col h-full">
  <div class="flex-0 flex items-center" mat-dialog-title>
    <h2 class="text-base md:text-2xl m-0 flex-auto">Product Selection</h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-divider class="text-border"></mat-divider>
  <mat-dialog-content class="flex-1 card flex items-start shrink overflow-y-auto">
    <common-product-model-menu class="product-model-menu w-1/4 hidden md:block h-full mr-2"
      (filterChange)="closeMenu()">
    </common-product-model-menu>
    <div class="w-full md:w-3/4 h-auto flex-auto custom-border border-l-[#ddd4d4] border-l">
      <div class="flex flex-row items-center px-2 md:px-4 py-4 ">
        <div class="card rounded-full px-4 flex-auto flex items-center border border-gray-300 searchProducts" [blockLoader]="loadingModels">
          <mat-icon class="icon-sm text-secondary">search</mat-icon>
          <input
            #inpRef
            [formControl]="control"
            [matAutocomplete]="auto"
            class="px-4 py-2 border-0 outline-none w-full bg-transparent" placeholder="Search Products" type="search">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="updateModel($event)" [displayWith]="displayedModelText">
            <mat-option *ngFor="let model of filteredModels$ | async" [value]="model">
              {{model.model}} - {{model?.manufacturer?.name}} - {{model.description}}
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>
      <common-products-view (productSelected)="this.dialogRef.close($event)" (resetSearchProductInputField)="resetInput(inpRef)" class="w-full mar"></common-products-view>
    </div>
  </mat-dialog-content>
  <!-- <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Cancel</button>
    <button color="primary" mat-flat-button>Submit</button>
  </mat-dialog-actions> -->
</div>
