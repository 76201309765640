<div>
  <h2>Select a Logo</h2>
  <ul class="grid grid-cols-3">
    <li *ngFor="let logo of logos">
      <img [src]="logo.whiteColorway" alt="Logo" 
           class="hover:outline-primary focus:outline-primary"
           (click)="logoIdChange.emit(logo.id)">
    </li>
  </ul>
</div>
