import { Component, Input, OnInit } from '@angular/core';
import { StatItem } from '../../models/utils';

@Component({
  selector: 'app-stats-banner',
  templateUrl: './stats-banner.component.html',
  styleUrls: ['./stats-banner.component.scss']
})
export class StatsBannerComponent implements OnInit {

  @Input() statItems: StatItem[];
  @Input() header: string;
  @Input() noDataHeader: string = 'No Data';
  @Input() noDataSubHeader: string = 'There is no data for your selected time range';

  constructor() { }

  ngOnInit(): void {
  }

}
